import React from 'react';
import CommonHeader from '../components/HeaderComponent';
import APIUtils from '../utils/APIUtils';
import Container from '../components/Container';
import ProgressChart from '../components/statistics/ProgressChart';
import DistributionChart from '../components/statistics/DistributionChart';
import "../css/statistics.css";

type States = {
  serviceList: any;
};

export default class Statistics extends React.Component<any, States> {
  apiUtils = new APIUtils();

  state = {
    serviceList: [],
  };

  componentDidMount() {
    this.fetchServiceList();
  }

  // !!! 연계서비스 목록 호출
  fetchServiceList = () => {
    this.apiUtils.fetchApi('/v1/me/organization/service', 'get').then((r) => {
      r.map((i: any, idx: number) => {
        this.apiUtils.fetchApi(`/v1/me/organization/event/statistics/${i.organizationService.id}`, 'get').then((r2) => {
          let tempServiceList: any = [...this.state.serviceList];
          tempServiceList.push({ ...i, ...r2 });
          this.setState({
            serviceList: tempServiceList,
          });
        });
      });


    });
  };

  // !!! 서비스 별 이벤트 수
  renderOverviewCard = () => {
    if (this.state.serviceList.length < 1) return;
    return this.state.serviceList.map((i: any, idx: number) => {
      return (
        <div
          key={idx}
          style={{
            backgroundColor: '#313644',
            width: 388,
            marginRight: idx === this.state.serviceList.length - 1 ? 0 : 28,
            borderRadius: 8,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            color: '#FFFFFF',
            padding: '24px 20px',
          }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 46, height: 46, marginRight: 17 }}>
              <img style={{ maxWidth: 46, maxHeight: 46 }} src={i.iconUrl} />
            </div>
            <div>
              <div style={{ fontSize: 18 }}>{i.organizationService.name}</div>
              <div style={{ fontSize: 12, color: '#999999', marginTop: 8 }}>{i.uuid}</div>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: 24 }}>
            <div style={{ textAlign: 'center' }}>
              <div style={{ fontSize: 14 }}>오늘</div>
              <div style={{ fontWeight: 'bold', marginTop: 8 }}>{i.daily}건</div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <div style={{ fontSize: 14 }}>이번주</div>
              <div style={{ fontWeight: 'bold', marginTop: 8 }}>{i.weekly}건</div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <div style={{ fontSize: 14 }}>이번달</div>
              <div style={{ fontWeight: 'bold', marginTop: 8 }}>{i.monthly}건</div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <div style={{ fontSize: 14 }}>누적</div>
              <div style={{ fontWeight: 'bold', marginTop: 8 }}>{i.total}건</div>
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    return (
      <Container>
        <CommonHeader pageTitle={'Statistics'} />

        <div className={"statistics-container"} style={{ width: '100%', height: '100vh', overflow: 'auto', padding: 65, paddingBottom: 150 }}>
          <div style={{ fontSize: 20, fontWeight: 700, color: '#FFFFFF', marginBottom: 30 }}>서비스 별 이벤트 수</div>
          <div
            className={'scroll-c'}
            style={{ width: '100%', height: 174, paddingBottom: 10, display: 'flex', flexDirection: 'row', overflowX: 'auto', whiteSpace: 'nowrap' }}>
            {this.renderOverviewCard()}
          </div>

          <div style={{ fontSize: 20, fontWeight: 700, color: '#FFFFFF', marginTop: 76, marginBottom: 10 }}>서비스 별 추이</div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ width: '47%' }}>
              <ProgressChart type={"monthly"}/>
            </div>

            <div style={{ width: '47%' }}>
              <ProgressChart type={"daily"}/>
            </div>
          </div>

          <div style={{ fontSize: 20, fontWeight: 700, color: '#FFFFFF', marginTop: 76, marginBottom: 10 }}>서비스 분포도</div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ width: 550 }} className={"distribution-chart"}>
              <DistributionChart />
            </div>
          </div>
        </div>
      </Container>
    );
  }
}
