import { useEffect, useState } from 'react';
import '../css/myPage/departmentManage.css';
import DepartmentDetailModal from '../components/departmentManage/modals/DepartmentDetailModal';
import RegiDepartmentModal from '../components/departmentManage/modals/RegiDepartmentModal';
import HeaderComponent from '../components/groupManagement/HeaderComponent';
import { ReactComponent as CROWN } from '../icons/group/master_icon.svg';
import { ReactComponent as GROUP } from '../icons/group/group_icon.svg';
import ServiceIconList from '../components/ServiceIconList';
import CommonHeader from '../components/HeaderComponent';
import Container from '../components/Container';
import APIUtils from '../utils/APIUtils';
import useInput from '../hooks/useInput';
import Table from '../components/Table';
import Input from '../components/Input';
import RightBtn from '../components/Table/RightBtn/RightBtn';

const apiUtils = new APIUtils();

const DepartmentManage = () => {
  const [departmentDetailOpen, setDepartmentDetailOpen] = useState(false);
  const [departmentRegiOpen, setDepartmentRegiOpen] = useState(false);
  const [closeOpen, setCloseOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [curPage, setCurPage] = useState(1);

  const [selectedRowData, setSelectedRowData] = useState('');
  const [isPermissionFetched, setIsPermissionFetched] = useState(false);

  const searchInput = useInput('');
  const PAGE_LIMIT = 6;

  const RegiOpen = () => {
    setDepartmentRegiOpen(true);
  };

  const DetailOpen = (params) => {
    setDepartmentDetailOpen(true);
    setSelectedRowData(params.row);
  };

  const groupManageColumns = [
    {
      field: 'id',
      headerName: 'No.',
      width: 80,
    },
    {
      field: 'name',
      headerName: '그룹명',
      flex: 1,
    },
    {
      field: 'users',
      headerName: '회원 수',
      flex: 1,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <GROUP />
          <div style={{ marginLeft: 5 }}>{params?.row.users.length}</div>
        </div>
      ),
    },
    {
      field: 'none',
      headerName: '마스터ID',
      flex: 1,
      renderCell: (params) => {
        let masterUsers = [];
        params.row.users.map((e) => {
          if (e?.ThroughGroupUser.master === 1) return masterUsers.push(e.username);
        });
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {masterUsers.length > 0 && <CROWN />}
            <div style={{ marginLeft: 5 }}>{masterUsers.join(', ')}</div>
          </div>
        );
      },
    },
    {
      field: 'permission',
      headerName: '이벤트 권한',
      flex: 2,
      renderCell: (cell) => <ServiceIconList uuid={cell.row.uuid} category={'group'} isPermissionFetched={isPermissionFetched} />,
    },
  ];

  const fetchTable = (e = null, p = 1) => {
    setCurPage(p);
    apiUtils
      .fetchApi('/v1/me/organization/group', 'get', {
        limit: PAGE_LIMIT,
        offset: p ? (p - 1) * PAGE_LIMIT : 0,
        ob: 'id',
        od: 'DESC',
        q: searchInput.value,
      })
      .then((r) => {
        setRows(r.rows);
        setTotalCount(r.count);
      });
  };

  useEffect(() => {
    fetchTable();
  }, [searchInput.value]);

  return (
    <Container>
      <CommonHeader pageTitle={'Department'} />
      <div className='department-container'>
        <div className='content'>
          <div className='right'>
            <div>
              <HeaderComponent />
              <div className='search-container'>
                <div className={'left-side'}>
                  <Input width={400} variant={'primary'} value={searchInput.value} onChange={searchInput.onChange} placeholder={'그룹명, ID검색'} />
                </div>
                <RightBtn onClick={RegiOpen}>+ 신규그룹 등록</RightBtn>
              </div>
            </div>

            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
              <Table
                columns={groupManageColumns}
                rows={rows}
                onRowDoubleClick={DetailOpen}
                pageSize={PAGE_LIMIT}
                rowCount={totalCount}
                curPage={curPage}
                onCustomPaginationChange={fetchTable}
                componentsProps={{
                  row: {
                    style: {
                      background: 'linear-gradient(90.12deg, #313644 0.02%, #1E2530 79.25%)',
                      fontWeight: 'bold',
                      borderRadius: 10,
                      marginBottom: 10,
                    },
                  },
                }}
              />
              {apiUtils.renderLoading()}
            </div>
            <div className={'bottom'}>
              <div className={'info'}>· 그룹을 지정해야 회원별 이벤트 권한을 설정할 수 있습니다.</div>
              <div className={'info'}>· 삭제된 그룹은 미지정그룹으로 자동 변경됩니다.</div>
              <div className={'info'}>· 미지정된 회원은 이벤트접근이 제한되오니사용자 목록에서 재설정 바랍니다.</div>
            </div>
          </div>
        </div>
        {/* 등록 - C */}
        <RegiDepartmentModal open={departmentRegiOpen} setOpen={setDepartmentRegiOpen} rowInfo={selectedRowData} groupUuid={selectedRowData?.uuid} fetchTable={fetchTable} />
        {/* 조회 - R */}
        <DepartmentDetailModal open={departmentDetailOpen} setOpen={setDepartmentDetailOpen} rowInfo={rows?.find((row) => row.uuid === selectedRowData.uuid)} groupUuid={selectedRowData?.uuid} fetchTable={fetchTable} setIsPermissionFetched={setIsPermissionFetched} />
      </div>
    </Container>
  );
};

export default DepartmentManage;
