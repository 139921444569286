import React, { useState } from "react";
import Container from "../components/Container";
import "../css/myPage/myPage.css";

import Chunsic from "../icons/chunsic2.png";

const MyPage = () => {
  const [isEditable, setIsEditable] = useState(false);

  const [id, setID] = useState(null);
  const [pwd, setPwd] = useState(null);
  const [pwdConfirm, setPwdConfirm] = useState(null);
  const [phoneNum, setPhoneNum] = useState(null);
  const [email, setEmail] = useState(null);
  const [organ, setOrgan] = useState(null);
  const [department, setDepartment] = useState(null);

  const onClick = () => {
    if (
      (id && pwd && pwdConfirm && phoneNum && email && organ && department) !==
        "" &&
      (id && pwd && pwdConfirm && phoneNum && email && organ && department) !==
        null
    ) {
      setIsEditable(!isEditable);
    } else {
      alert("빈칸을 모두 채워주세요");
    }
  };

  return (
    <Container>
      <div className="my-page-container">
        <div className="sub-container">
          <div className="top">
            <div className="icon">
              <img className="profile-img" src={Chunsic} alt="profile" />
            </div>
          </div>
          <div className="bottom">
            <div className="left">
              <div className="content">
                <div className="title">이름</div>
                <div className={`detail ${isEditable ? "edit" : "fix"}`}>
                  {isEditable ? (
                    <input
                      type="text"
                      className="input-edit"
                      placeholder="이름을 입력해주세요"
                      onChange={(e) => setID(e.target.value)}
                    ></input>
                  ) : (
                    "춘식이"
                  )}
                </div>
              </div>
              <div className="content">
                <div className="title">아이디</div>
                <div className="detail">haeundae1</div>
              </div>
              <div className="content">
                <div className="title">비밀번호</div>
                <div className={`detail ${isEditable ? "edit" : "fix"}`}>
                  {isEditable ? (
                    <input
                      type="password"
                      className="input-edit"
                      placeholder="비밀번호를 입력해주세요"
                      onChange={(e) => setPwd(e.target.value)}
                    ></input>
                  ) : (
                    "비밀"
                  )}
                </div>
              </div>
              <div className="content">
                <div className="title">비밀번호 확인</div>
                <div className={`detail ${isEditable ? "edit" : "fix"}`}>
                  {isEditable ? (
                    <input
                      type="password"
                      className="input-edit"
                      placeholder="비밀번호 확인"
                      onChange={(e) => setPwdConfirm(e.target.value)}
                    ></input>
                  ) : (
                    "비밀"
                  )}
                </div>
              </div>
              <div className="content">
                <div className="title">휴대폰 번호</div>
                <div className={`detail ${isEditable ? "edit" : "fix"}`}>
                  {isEditable ? (
                    <input
                      type="phone"
                      className="input-edit"
                      placeholder="휴대폰 번호를 입력해주세요"
                      onChange={(e) => setPhoneNum(e.target.value)}
                    ></input>
                  ) : (
                    "010-1234-1234"
                  )}
                </div>
              </div>
            </div>
            <div className="right">
              <div className="content">
                <div className="title">이메일</div>
                <div className={`detail ${isEditable ? "edit" : "fix"}`}>
                  {isEditable ? (
                    <input
                      type="email"
                      className="input-edit"
                      placeholder="이메일을 입력해주세요"
                      onChange={(e) => setEmail(e.target.value)}
                    ></input>
                  ) : (
                    "chunsic2@kakao.co.kr"
                  )}
                </div>
              </div>
              <div className="content">
                <div className="title">소속기관</div>
                <div className={`detail ${isEditable ? "edit" : "fix"}`}>
                  {isEditable ? (
                    <input
                      type="text"
                      className="input-edit"
                      placeholder="소속기관을 입력해주세요"
                      onChange={(e) => setOrgan(e.target.value)}
                    ></input>
                  ) : (
                    "카카오"
                  )}
                </div>
              </div>
              <div className="content">
                <div className="title">조직/부서</div>
                <div className={`detail ${isEditable ? "edit" : "fix"}`}>
                  {isEditable ? (
                    <input
                      type="text"
                      className="input-edit"
                      placeholder="조직/부서를 입력해주세요"
                      onChange={(e) => setDepartment(e.target.value)}
                    ></input>
                  ) : (
                    "고양이과"
                  )}
                </div>
              </div>
              <div className="content">
                <div className="title">계정 권한</div>
                <div className="detail">최고관리자</div>
              </div>
              <div className="content">
                <div className="title">이벤트 접근 권한</div>
                <div className="detail">119긴급출동지원, 112긴급출동지원</div>
              </div>
            </div>
          </div>
          <div className="btn-container">
            {isEditable ? (
              <button
                className="btn-drop-out"
                onClick={() => setIsEditable(!isEditable)}
              >
                취소
              </button>
            ) : (
              <button className="btn-drop-out">탈퇴하기</button>
            )}
            {isEditable ? (
              <button className="btn-edit" onClick={onClick}>
                수정 완료
              </button>
            ) : (
              <button
                className="btn-edit"
                onClick={() => setIsEditable(!isEditable)}
              >
                정보 수정
              </button>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MyPage;
