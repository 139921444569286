import React, {createRef} from 'react';
import { api_tta } from '../api/api_tta';
import AlertMessage from '../components/utilities/AlertMessage';
import LoadingComponent from "../components/utilities/LoadingComponent";
import {StorageUtils} from "./StorageUtils";
import {WSC} from "../service/WSC";

export default class APIUtils extends React.Component {
  refLoadingComponent = createRef();

  constructor() {
    super();
  }

  logout = () => {
    // 브라우저에 저장된 JWT 토큰 삭제
    StorageUtils.removeAuthorization();
    // API 인스턴스에 저장된 Authorization 삭제
    api_tta.deleteHeader('Authorization');
    // Web Socket Client 연결 끊기
    WSC.getInstance().stop(true);
    try {
      typeof this.sessionStore?.reset === 'function' && this.sessionStore?.reset();
    } catch (e) {}
    window.location.href="/sign-in";
  };

  fetchApi = (url, reqType, params): any => {
    this.refLoadingComponent?.current?.changeStatus(true);
    return new Promise((resolve, reject) => {
      api_tta[reqType](url, params && params?.formData ? params.formData : { ...params })
        .then((r) => {
          if (!r.ok) {
            if(r.status === 401) {
              this.logout();
            }
            console.log('❌[server response error]\n', r);
            AlertMessage.alertMessage('error', (r.status == "403" ? "권한이 없습니다" : r?.data?.message || r?.data?.code || r?.problem));
            return reject('server error', r);
          }
          resolve(r.data);
        })
        .catch((err) => {
          console.log('❌[catch err]\n', err);
          AlertMessage.alertMessage('error', '[CATCH ERR]\n' + err);
          return reject('catch err', err);
        })
        .finally(() => {
          this.refLoadingComponent?.current?.changeStatus(false);
        });
    });
  };

  renderLoading = (withBg) => {
    return (
      <LoadingComponent ref={this.refLoadingComponent} withBg={withBg || false}/>
    );
  };
}

export const fetchApi = (url, reqType, params) => {
  return new Promise((resolve, reject) => {
    api_tta[reqType](url, params && params?.formData ? params.formData : { ...params })
      .then((r) => {
        if (!r.ok) {
          console.log('❌[server response error]\n', r);
          AlertMessage.alertMessage('error', (r.status == "403" ? "권한이 없습니다" : r?.data?.message || r?.data?.code || r?.problem));
          return reject('server error', r);
        }
        resolve(r.data);
      })
      .catch((err) => {
        console.log('❌[catch err]\n', err);
        AlertMessage.alertMessage('error', '[CATCH ERR]\n' + err);
        return reject('catch err', err);
      });
  });
};
