import React from 'react';
import NoDataFound from '../NoDataFound';
import {PANEL_MODE} from './ServiceListComponent';
import AddServiceModal from './AddServiceModal';
import {SETTING_PANEL} from './SettingModal';
import {IFacilityType, WSSEvent} from 'anuro-platform-core';
import {Switch, Tooltip} from '@mui/material';
import APIUtils from '../../utils/APIUtils';
import AlertMessage from '../utilities/AlertMessage';
import {BsPlusSquare} from "react-icons/bs";
import EventEmitter from "../../utils/EventEmitter";
import {WSC} from "../../service/WSC";
import {EXECUTE_TYPE} from "../utilities/HandlingSocketData";

export default class FacilityListComponent extends React.Component {
  emitter = EventEmitter.getInstance();
  apiUtils = new APIUtils();
  state = {
    mode: PANEL_MODE.normal,
    addModalOpen: false,
    facilityList: [],
  };

  componentDidMount() {
    WSC.getInstance().socket.on(WSSEvent[WSSEvent.FACILITY_DEL], (event) => this.onFacilityWSSEvent(event, EXECUTE_TYPE.DEL));

    this.fetchFacilityList();
  }

  componentWillUnmount() {
    WSC.getInstance().socket.off(WSSEvent[WSSEvent.FACILITY_DEL]);
  }

  onFacilityWSSEvent = (event, type) => {

  }

  handleModal = (addModalOpen) => this.setState({ addModalOpen, mode: PANEL_MODE.normal });
  onManage = () => this.setState({ mode: this.state.mode === PANEL_MODE.normal ? PANEL_MODE.manage : PANEL_MODE.normal });

  // !!! 시설물 목록 호출
  fetchFacilityList = () => {
    this.apiUtils.fetchApi('/v1/me/organization/facility-type', 'get').then((r) => {
      this.setState({ facilityList: r });
    });
  };
  // !!! 시설물 아이콘 변경할 때
  onUpdate = (i: IFacilityType, type, value) => {
    if (this.state.mode === PANEL_MODE.manage) return null;

    let formData = new FormData();
    formData.append(type, value);
    this.apiUtils.fetchApi(`/v1/me/organization/facility-type/${i.organizationFacilityType.id}`, 'patch', { formData }).then((r) => {
      this.fetchFacilityList();
      this.emitter.emit('layer_fetchFacilityList');
      this.emitter.emit('fetchCCTVList');
    });
  };

  // !!! 시설물 삭제 시
  onDeleteFacility = async (i: IFacilityType) => {
    const result = await AlertMessage.confirmMessage(`'${i.name}' 을(를)\n 삭제하시겠습니까?`);
    if (!result) return null;
    this.apiUtils.fetchApi(`/v1/me/organization/facility-type/${i.organizationFacilityType.id}`, 'delete').then((r) => {
      this.fetchFacilityList();
      this.emitter.emit('removeMarker', { markerType: 'cctv' });
      this.emitter.emit("fetchCCTVList");
    });
  };

  // !!! 시설물 목록 렌더링
  renderFacilityItem = () =>
    this.state.facilityList.length > 0 &&
    this.state.facilityList.map((i: IFacilityType, idx) => {
      return (
        <div key={idx} style={{ display: 'flex', alignItems: 'center' }}>
          <div className={`item`} style={{ width: '100%', flexDirection: 'column' }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <div style={{ width: 150, paddingTop: 10 }}>
                {i.name} <span style={{ color: i.organizationFacilityType.active ? '#78D700' : '#EC1C00' }}>●</span>
              </div>
              <Switch checked={i.organizationFacilityType.active === 1} onClick={(e) => this.onUpdate(i, 'active', e.target?.checked === true ? 1 : 0)} />
            </div>

            <div className={'icon-container'}>
              <input type={'file'} id={'file_normal'} style={{ display: 'none' }} onChange={(e) => this.onUpdate(i, 'iconNormal', e.target.files[0])} />
              <img src={require('../../icons/text-decoration.png')} style={{ marginRight: 10 }} />
              <div style={{ marginRight: 10 }}>정상일때</div>
              <Tooltip title={'아이콘을 눌러 변경하세요'} placement={'top'} arrow disableHoverListener={this.state.mode !== PANEL_MODE.normal}>
                <label htmlFor='file_normal' style={{ width: 44, height: 44, cursor: 'pointer' }}>
                  {i.organizationFacilityType.iconNormalUrl ? <img className={'icon cursor'} src={i.organizationFacilityType.iconNormalUrl} /> : <BsPlusSquare style={{ marginLeft: 10 }} />}
                </label>
              </Tooltip>
            </div>

            <div className={'icon-container'}>
              <input type={'file'} id={'file_trouble'} style={{ display: 'none' }} onChange={(e) => this.onUpdate(i, 'iconFault', e.target.files[0])} />
              <img src={require('../../icons/text-decoration.png')} style={{ marginRight: 10 }} />
              <div style={{ marginRight: 10 }}>고장일때</div>
              <Tooltip title={'아이콘을 눌러 변경하세요'} placement={'top'} arrow disableHoverListener={this.state.mode !== PANEL_MODE.normal}>
                <label htmlFor='file_trouble' style={{ width: 44, height: 44, cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                  {i.organizationFacilityType.iconTroubleS3Url ? <img className={'icon cursor'} src={i.organizationFacilityType.iconTroubleS3Url} /> : <BsPlusSquare style={{ marginLeft: 10 }} />}
                </label>
              </Tooltip>
            </div>
          </div>

          {this.state.mode === PANEL_MODE.manage && (
            <div className={'red-txt-btn'} style={{ width: 22, marginLeft: 15 }} onClick={() => this.onDeleteFacility(i)}>
              &times;
            </div>
          )}
        </div>
      );
    });

  render() {
    return (
      <div className={'right list'}>
        <div style={{ height: 'calc(100% - 100px)' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>시설물 관리</div>
            <div className={`cursor txt-btn ${this.state.mode === PANEL_MODE.manage && 'active'}`} onClick={this.onManage}>
              관리
            </div>
          </div>
          <div className={'facility-list'} style={{ position: 'relative' }}>
            {this.state.facilityList.length === 0 && <NoDataFound emptyList />}
            {this.renderFacilityItem()}
            {this.apiUtils.renderLoading()}
          </div>
        </div>
        <button className={'btn'} onClick={() => this.handleModal(true)}>
          + 시설물 추가
        </button>
        {this.state.addModalOpen && <AddServiceModal title='시설물 추가' type={SETTING_PANEL.FACILITY_MANAGEMENT} fetchData={this.fetchFacilityList} onClose={this.handleModal} />}
      </div>
    );
  }
}
