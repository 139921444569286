import { useState } from 'react';
import '../../../css/facilities/facilityDetailModal.css';
import { ReactComponent as CCTV } from '../../../icons/cctv_logo.svg';
import BaseModal from '../../common/Modal/BaseModal';
import EditFacilityModal from './EditFacilityModal';
import Divider from '../../Divider';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

const FacilityDetail = ({ title, detail }) => {
  const margin = 15;
  const color = '#cccccc';
  return (
    <>
      <Divider marginTop={margin} marginBottom={margin} color={color} />
      <div className='each-detail'>
        <div className='title'>{title}</div>
        <div>{detail}</div>
      </div>
    </>
  );
};

const FacilityDetailModal = inject('cctvStore')(
  observer(({ cctvStore, open, setOpen, rowInfo, setFacilityDeleteOpen, fetchTable }) => {
    const [facilityEditOpen, setFacilityEditOpen] = useState(false);
    const margin = 15;
    const color = '#cccccc';

    const handleClose = () => {
      setOpen(false);
    };
    const handleDelBtn = () => {
      setOpen(false);
      setFacilityDeleteOpen(true);
    };
    const handleEditBtn = () => {
      setFacilityEditOpen(true);
    };

    return (
      <BaseModal open={open} handleCloseBtn={handleClose} leftBtnTitle={'삭제'} rightBtnTitle={'정보 수정'} handleLeftBtn={handleDelBtn} handleRightBtn={handleEditBtn}>
        <div className='facility-detail-modal-container'>
          <div className='header-container'>
            <CCTV className='icon' />
            <div className='name'>{rowInfo?.name}</div>
            <div className='sub-header-container'>
              <div className='facility-type'>{rowInfo?.organizationFacilityType?.facilityType?.name}</div>
              <div className='facility-id'>(ID: {rowInfo?.id})</div>
            </div>
          </div>
          <div className='detail-container'>
            <FacilityDetail title={'UUID'} detail={rowInfo?.uuid} />
            <FacilityDetail title={'코드'} detail={rowInfo?.code ? rowInfo.code : '미지정'} />
            <FacilityDetail title={'위치'} detail={rowInfo?.addressStreet + ' / ' + rowInfo?.addressStreetDetail} />
            <FacilityDetail title={'위도/경도'} detail={rowInfo?.latitude + ' / ' + rowInfo?.longitude} />
            <FacilityDetail title={'활성 상태'} detail={rowInfo?.active === 1 ? '활성' : '비활성'} />
            <FacilityDetail title={'동작 상태'} detail={rowInfo?.active === 1 && cctvStore.workingCCTVList.includes(rowInfo?.uuid) ? '정상' : '이상'} />
            <FacilityDetail title={'등록일'} detail={moment(rowInfo?.createdAt).format('YYYY.MM.DD')} />
            <FacilityDetail title={'비고'} detail={rowInfo?.detail} />
            <Divider marginTop={margin} marginBottom={margin} color={color} />
          </div>
        </div>
        {/* 수정 - U */}
        {facilityEditOpen && <EditFacilityModal open={facilityEditOpen} setOpen={setFacilityEditOpen} rowInfo={rowInfo} fetchTable={fetchTable} />}
      </BaseModal>
    );
  }),
);

export default FacilityDetailModal;
