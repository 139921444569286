import React from 'react';
import BaseModal from '../../BaseModal';

const DeleteFailedModal = ({ open, setOpen, setIsDeleteOpen }) => {
  const handleClose = () => {
    setIsDeleteOpen(false);
    setOpen(false);
  };

  return (
    <BaseModal title={'삭제할 수 없습니다'} open={open} leftBtnLabel={'취소'} handleLeftBtn={handleClose} handleRightBtn={() => {}}>
      <div>처리중인 이벤트가 있을 경우 삭제가 불가합니다. 이벤트 담당자 변경 후 다시 시도해주세요.</div>
    </BaseModal>
  );
};

export default DeleteFailedModal;
