import React, { HTMLAttributes } from 'react';
import { Modal } from '@mui/material';
import styles from './BaseModal.module.css';
import Divider from '../../Divider';
import { ReactComponent as CLOSE } from '../../../icons/close.svg';
import ModalButton from '../Buttons/ModalButton/ModalButton';

interface Props {
  open: boolean;
  children: React.ReactNode;
  title?: string;
  divider?: boolean;
  handleCloseBtn: any;
  leftBtnTitle?: string;
  rightBtnTitle?: string;
  handleLeftBtn?: HTMLAttributes<HTMLButtonElement>;
  handleRightBtn?: HTMLAttributes<HTMLButtonElement>;
}

const BaseModal = (props: Props) => {
  const handleEscapeKey = (e) => {
    if (e.key === 'Escape') {
      props.handleCloseBtn();
    }
  };

  return (
    <Modal open={props.open} onClose={handleEscapeKey}>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.title}>{props.title && props.title}</div>
          <div>{props.handleCloseBtn && <CLOSE className={styles.closeBtn} onClick={props.handleCloseBtn} />}</div>
        </div>
        {props.divider && <Divider />}
        <div>{props.children}</div>
        <div className={styles.btnContainer}>
          {props.leftBtnTitle && (
            <ModalButton className={`${styles.leftBtn} ${styles.button}`} variant={'outlined'} onClick={props.handleLeftBtn}>
              {props.leftBtnTitle}
            </ModalButton>
          )}
          {props.rightBtnTitle && (
            <ModalButton className={`${styles.rightBtn} ${styles.button}`} variant={'contained'} onClick={props.handleRightBtn}>
              {props.rightBtnTitle}
            </ModalButton>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default BaseModal;
