import React, {useEffect, useState} from 'react';
import '../../css/event/eventDetailModal.css';
import {EVENT_DEFAULT_ICON, EVENT_PROGRESS_CODE, EVENT_STATUS} from '../../constants/Event';
import EventDetailListComponent from './EventDetailListComponent';
import {ReactComponent as CLOSE} from '../../icons/close.svg';
import {ReactComponent as MORE} from '../../icons/more.svg';
import CompleteAndCloseModal from '../CompleteAndCloseModal';
import {SERVICE, SocketParser} from 'anuro-platform-core';
import AlertMessage from '../utilities/AlertMessage';
import EventEmitter from '../../utils/EventEmitter';
import {useLocation} from 'react-router-dom';
import {useHistory} from 'react-router-dom/';
import MenuItem from '@mui/material/MenuItem';
import APIUtils from '../../utils/APIUtils';
import {Box, Modal} from '@mui/material';
import {useForm} from 'react-hook-form';
import ModalStyle from '../ModalStyle';
import Menu from '@mui/material/Menu';
import Button from '../Button';
import Input from '../Input';
import RawModal from './RawModal';
import {ReactComponent as FULLTEXT} from '../../icons/full_text.svg';
import DetailList from '../eventManage/modals/eventDetailModal/DetailList';

const emitter = EventEmitter.getInstance();
const apiUtils = new APIUtils();

// const UserListOptions = () => {
//   const form = useForm();
//   const userListArr = [];
//   fetchApi(`/v1/me/organization/user`, `get`).then((res) => {
//     res.rows.map((e) => {
//       return userListArr.push({
//         value: e?.uuid,
//         label: `${e?.profile?.name}(${e?.username})`,
//       });
//     });
//   });
//   return (
//     <Controller
//       name='chargeUserUuid'
//       control={form.control}
//       render={({ field }) => {
//         return <SelectBox defaultValue={chargedPersonDefault} containerWidth={'100%'} label={'담당자'} name={'chargeUserUuid'} width={'100%'} options={userListArr} placeholder={'담당자를 선택해주세요'} {...field} />;
//       }}
//     />
//   );
// };

const EventDetailModal = ({ detailOpen, isDetailOpen, isDeleteOpen, setEventQuiteOpen, setEditOpen, rowInfo, setDeleteData, fetchData }) => {
  const location = useLocation();

  const [closeOpen, setCloseOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rawModalOpen, setRawModalOpen] = useState(false);

  const { register, handleSubmit, reset } = useForm();
  const history = useHistory();

  if (!rowInfo) return null;

  const parsingData = rowInfo.raw ? SocketParser.parse(SERVICE[rowInfo?.organizationEventCategory?.eventCategory?.service?.code], rowInfo.raw) : '';
  const progressCode = rowInfo.raw ? parsingData.header.PROGRESS_CODE : '';

  const isEventOccur = rowInfo.status === EVENT_STATUS.STANDBY.code;
  const terminated = rowInfo.raw && (progressCode == EVENT_PROGRESS_CODE.RELEASE.code || progressCode == EVENT_PROGRESS_CODE.TERMINATED.code) ? true : rowInfo.status === EVENT_STATUS.TERMINATED.code ? true : false;

  // const terminated = rowInfo?.status === 'TERMINATED';

  const onSubmit = (data) => {
    apiUtils
      .fetchApi(`/v1/me/organization/event/${rowInfo.uuid}/comment/${rowInfo?.organizationEventCategory.organizationService.id}`, 'post', {
        message: data.record,
      })
      .then(() => {
        AlertMessage.alertMessage('success', '처리내역이 전송되었습니다');
        reset();
      });
    // console.log('[Line:23 data]', data);
  };

  const open = Boolean(anchorEl);
  const BOTTOM_MARGIN = 16;

  const handleClose = () => {
    isDetailOpen(false);
    reset();
  };

  const handleMoreFunctionBtn = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteBtn = () => {
    setDeleteData && setDeleteData([rowInfo]);
    setAnchorEl(null);
    isDetailOpen(false);
    isDeleteOpen(true);
  };

  const handleEditBtn = () => {
    emitter.emit('removeMarker', { eventType: 'search' });
    setAnchorEl(null);
    setEditOpen(true);
  };

  // !!! 이벤트 상태 변경
  const patchEventStatus = async (status) => {
    if (location.pathname === '/event' && status === EVENT_STATUS.IN_PROGRESS.code) {
      return history.push('/gis');
    }

    const result = await AlertMessage.confirmMessage(`이벤트 상태를 ${status === 'IN_PROGRESS' ? "'처리중'으로" : "'상황종료'로"} 변경하시겠습니까?`);
    if (!result) return;

    apiUtils.fetchApi(`/v1/me/organization/event/${rowInfo?.organizationEventCategory.organizationService.id}/${rowInfo.uuid}`, 'patch', { status }).then((r) => {
      emitter.emit('fetchEventList');
      emitter.emit('layer_fetchServiceList');
      fetchData();
      setTimeout(() => {
        emitter.emit('moveTo', { lat: rowInfo?.latitude, lng: rowInfo?.longitude });
      }, 1000);
      AlertMessage.alertMessage('success', `이벤트 상태를 ${status === 'IN_PROGRESS' ? "'처리중'으로" : "'상황종료'로"} 변경했습니다`);
    });
  };

  const MainEventInfo = () => {
    const eventOccurStyle = {
      backgroundColor: '#FFE6E6',
      border: '1px solid #FF0000',
    };
    const eventEndStyle = {
      backgroundColor: '#1B2649',
    };

    return (
      <div style={terminated ? eventEndStyle : eventOccurStyle} className={`top`}>
        <div className={'left'}>
          <img className={'icon'} src={rowInfo.organizationEventCategory?.organizationService?.iconUrl || EVENT_DEFAULT_ICON[rowInfo?.organizationEventCategory?.eventCategory?.service?.code] || ''} alt={'긴급출동119'} style={{ width: 60, height: 60 }} />
          <div className={'title'}>
            <div
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                color: terminated ? 'white' : '#FF0000',
                marginBottom: 4,
              }}>
              {rowInfo.raw ? (progressCode == EVENT_PROGRESS_CODE.RELEASE.code ? '상황해제' : progressCode == EVENT_PROGRESS_CODE.TERMINATED.code ? '상황종료' : EVENT_STATUS[rowInfo.status].name) : terminated ? '상황종료' : EVENT_STATUS[rowInfo.status].name}
            </div>
            <div
              style={{
                fontSize: 26,
                fontWeight: 'bold',
                color: terminated ? 'white' : '#FF0000',
                marginBottom: 8,
              }}>
              {rowInfo?.organizationEventCategory?.organizationService?.name || ''} ({rowInfo?.organizationEventCategory?.eventCategory.name})
            </div>
            <div style={{ fontSize: 14, color: terminated ? 'white' : 'black' }}>이벤트 ID : {rowInfo?.code}</div>
          </div>
        </div>
        {!terminated && (
          <div className={'right'}>
            <button className={'btn btn-handle'} onClick={() => patchEventStatus('IN_PROGRESS')}>
              처리하기
            </button>
            <button className={'btn btn-quite'} onClick={() => patchEventStatus('TERMINATED')}>
              종료하기
            </button>
          </div>
        )}
      </div>
    );
  };

  const ProcessingLists = () => {
    const [commentListArr, setCommentListArr] = useState([]);

    useEffect(() => {
      const fetchCommentList = () => {
        apiUtils
          .fetchApi(`/v1/me/organization/event/${rowInfo?.uuid}/comment?offset=0&limit=100&ob=id&od=DESC`, 'get')
          .then((res) => {
            setCommentListArr(res.rows);
          })
          .then(() => {
            apiUtils
              .fetchApi(`/v1/me/organization/event/${rowInfo?.uuid}/history?offset=0&limit=100&ob=id&od=DESC`, 'get')
              .then((res) => {
                if (res.rows.length === 0) return null;
                setCommentListArr((prevList) => [
                  ...prevList.map((comment) => ({
                    ...comment,
                    ts: comment.createdAt,
                  })),
                  ...res?.rows.map((history) => ({ ...history, ts: history.createdAt })),
                ]);
              })
              .then(() => {
                // console.log('[Line:195 commentListArr]', commentListArr);
              });
          })
          .catch((err) => console.log('[Line:186 err]', err));
      };
      fetchCommentList();
    }, []);

    // TODO commentListArr 시간순으로 정렬
    const test = commentListArr?.sort((a, b) => {
      return new Date(b.ts) - new Date(a.ts);
    });

    return (
      <div className={'right'}>
        <div style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 16 }}>처리내역</div>
        <div className={'content-container'}>
          <div className={'line'}></div>
          <div style={{ overflowY: 'auto' }}>
            {test?.map((e) => {
              return <EventDetailListComponent key={e.id} commentList={e} />;
            })}
          </div>
        </div>
      </div>
    );
  };

  const RecordWritingArea = () => {
    return terminated ? null : (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'input-container'}>
          <Input width={'100%'} placeholder={'이벤트 기록 작성'} {...register('record')} />
          <Button style={{ marginLeft: 14 }} height={20} variant={'primary'} type={'submit'}>
            작성하기
          </Button>
        </div>
      </form>
    );
  };

  return (
    <Modal open={detailOpen} onClose={handleClose}>
      <Box sx={{ ...ModalStyle(960, terminated ? 570 : 660, 5), height: 610, outline: 'none' }}>
        <div className='event-detail-title-container'>
          <div className={'left'}>
            <h2>이벤트 상세정보</h2>
            {(rowInfo?.raw || rowInfo?.ES_112_MTMDA_RECEPT) && (
              <div
                className={'full-text'}
                onClick={() => {
                  setRawModalOpen(true);
                }}>
                <FULLTEXT />
                <p>전문보기</p>
              </div>
            )}
          </div>
          <div className='right'>
            <MORE onClick={handleMoreFunctionBtn} style={{ marginRight: 14, cursor: 'pointer', textAlign: 'center', padding: 5 }} />
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              <MenuItem onClick={handleEditBtn}>수정하기</MenuItem>
              <MenuItem onClick={handleDeleteBtn}>삭제하기</MenuItem>
            </Menu>
            <CLOSE onClick={handleClose} style={{ cursor: 'pointer' }} />
          </div>
        </div>
        <div className={'event-detail-content-container'}>
          <MainEventInfo />
          <div className={'bottom'}>
            <DetailList rowInfo={rowInfo} />
            <ProcessingLists />
          </div>
          <RecordWritingArea />
        </div>
        <CompleteAndCloseModal title={'수정완료'} detail={'이벤트 등록이 정상적으로 처리되었습니다'} open={closeOpen} setOpen={setCloseOpen} />
        {rawModalOpen && <RawModal open={rawModalOpen} onClose={() => setRawModalOpen(false)} rowInfo={rowInfo} />}
        {apiUtils.renderLoading()}
      </Box>
    </Modal>
  );
};

export default EventDetailModal;
