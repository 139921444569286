import React from "react";
import SideNavigationComponent from "./SideNavigationComponent";
import "../css/nav.css";
import AlertMessage from "./utilities/AlertMessage";
import APIUtils from "../utils/APIUtils";
import {IUser} from "anuro-platform-core";
import {inject} from "mobx-react";

const styles = {
	notification: {
		wrapper: {
			backgroundColor: "#03A8A8",
			borderRadius: 10,
			padding: "15px 23px",
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
		},
		imgAlertType: {
			marginRight: 15,
		},
		txtEventName: {
			fontSize: 16,
			fontWeight: 700,
			color: "#FFFFFF"
		},
		txtEventLocation: {
			fontSize: 12,
			fontWeight: 400,
			color: "#FFFFFF",
			marginTop: 5,
		},
	}
}

@inject("sessionStore")
export default class Container extends React.Component {
	apiUtils = new APIUtils();
  componentDidMount() {
		this.fetchUserProfile();
	}

	fetchUserProfile = () => {
		this.apiUtils.fetchApi("/v1/me", "get").then((r: IUser) => {
			this.apiUtils.fetchApi(`/v1/me/organization/user/${r.uuid}/permissions`, "get").then((r2) => {
				this.props.sessionStore.setUser(r);
				let tempPermission = {
					C: [], U: [], R: [], D: []
				}
				r2?.map((i) => {
					i.allowedActions && i.allowedActions?.map(i2 => {
						tempPermission[i2.type].push(i.code);
					})
				});
				this.props.sessionStore.setUserPermission(tempPermission);
			});
		})
	}

  render() {
    return (
      <div className='nav-container'>
        <SideNavigationComponent />
        <div className={'content'}>{this.props.children}</div>
        {AlertMessage.renderNotification()}
      </div>
    );
  }
}
