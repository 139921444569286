import React, { useLayoutEffect, useState } from 'react';
import { defaultCheckboxList } from '../constants/SelectOptions';
import { useFormContext } from 'react-hook-form';
import { fetchApi } from '../utils/APIUtils';
import CheckboxGroup from './CheckboxGroup';
import Checkbox from './Checkbox';

const PermissionCheckboxes = ({ permissions, serviceId }) => {
  const form = useFormContext();

  return defaultCheckboxList.map((checkbox) => {
    const permission = permissions.find((p) => p.organizationServiceId === serviceId);
    let checked;
    if (permission?.allowedActions) {
      checked = !!permission.allowedActions?.find((p) => p.type === checkbox.name);
    } else {
      checked = false;
    }
    const checkboxKey = serviceId + '_permission_' + checkbox.name;
    form.setValue(checkboxKey, checked);

    return <Checkbox key={serviceId + checkbox.name} label={checkbox.label} {...form.register(checkboxKey)} />;
  });
};

const EventAccessCheckBoxes = ({ groupUuid, category }) => {
  const [serviceList, setServiceList] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const form = useFormContext();

  const checkBoxToggle = (e) => {
    const data = form.getValues();
    setCheckedAll(e.target.checked);
    setTimeout(() => {
      for (const key in data) {
        if (!key.includes('_permission_')) continue;
        console.log(key, e.target.checked);
        form.setValue(key, e.target.checked);
      }
    }, 100);
  };

  useLayoutEffect(() => {
    fetchApi(`/v1/me/organization/service`, 'get')
      .then((e) => {
        setServiceList(e);
      })
      .catch((err) => console.log('[Line:27 err]', err));
    fetchApi(`/v1/me/organization/${category}/${groupUuid}/permissions`, 'get')
      .then((el) => {
        setPermissions(el);
      })
      .catch((err) => console.log('[Line:36 err]', err));
  }, []);

  return (
    <div style={{ minHeight: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {serviceList.map((service) => {
        return (
          <CheckboxGroup labelwidth={130} key={service?.uuid} label={service?.name}>
            <PermissionCheckboxes permissions={permissions} serviceId={service?.organizationService?.id} />
          </CheckboxGroup>
        );
      })}
      <Checkbox labelstyle={{ width: 'auto' }} containerstyle={{ borderRadius: 4, padding: 8, border: '1px solid #808080', justifyContent: 'center' }} label={checkedAll ? '전체 해제' : '전체 선택'} onChange={checkBoxToggle} />
    </div>
  );
};

export default EventAccessCheckBoxes;
