import React, { useEffect, useState } from 'react';
import '../../../css/event/smsModal.css';
import DeleteSms from '../../eventManage/modals/DeleteSms';
import APIUtils, { fetchApi } from '../../../utils/APIUtils';
import AlertMessage from '../../utilities/AlertMessage';
import SmsEditModal from './SmsEditModal';
import moment from 'moment/moment';
import Table from '../../Table';

const apiUtils = new APIUtils();
const PAGE_LIMIT = 6;

const SmsTempSaveComponent = ({ setTabValue }) => {
  const [curPage, setCurPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [checkedRows, setCheckedRows] = useState([]);

  const [selectedRowData, setSelectedRowData] = useState();
  const [smsDeleteOpen, setSmsDeleteOpen] = useState(false);

  let intervalTimer = null;

  const [smsEditOpen, setSmsEditOpen] = useState(false);

  const smsTempSavedListColumns = [
    {
      field: 'id',
      headerName: 'id',
      width: 50,
    },
    {
      field: 'message',
      headerName: '작성내용',
      flex: 1,
    },
    {
      field: 'to',
      headerName: '수신자',
      width: 150,
      valueGetter: (params) => {
        let tos = [];
        try {
          tos = JSON.parse(params.value);
        } catch (e) {
          console.error('To value is not valid json object');
        }

        if (tos.length > 1) {
          return `${tos.length}명의 수신자`;
        } else if (tos.length === 1) {
          return tos[0];
        } else {
          return '';
        }
      },
    },
    {
      field: 'createdAt',
      headerName: '작성일자',
      width: 180,
      valueGetter: (params) => moment(params.value).format('YY-MM-DD HH:mm:ss'),
    },
  ];

  const fetchTempSmsSendList = (q = '', p = 1) => {
    setCurPage(p);
    apiUtils
      .fetchApi('/v1/message-service', 'get', {
        limit: PAGE_LIMIT,
        offset: p ? (p - 1) * PAGE_LIMIT : 0,
        ob: 'id',
        od: 'DESC',
        temp: 1,
        q,
      })
      .then((res) => {
        setRows(res.rows);
        setTotalCount(res.count);
      });
  };

  useEffect(() => {
    fetchTempSmsSendList();
  }, []);

  const handleDeleteBtn = () => {
    if (checkedRows.length < 1) return AlertMessage.alertMessage('error', '삭제할 메세지를 선택해 주세요');
    const deleteEventList = rows?.map((i) => {
      if (checkedRows.includes(i.id)) return i;
    });
    setSelectedRowData(deleteEventList);
    setSmsDeleteOpen(true);
  };

  const leftBtn = () => {
    return (
      <button className='btn-del' onClick={handleDeleteBtn}>
        선택 삭제
      </button>
    );
  };

  const rightBtn = () => {
    const handleSendBtn = () => {
      if (checkedRows.length < 1) return AlertMessage.alertMessage('error', '전송할 메세지를 선택해 주세요');
      if (checkedRows.length > 1) return AlertMessage.alertMessage('error', '메세지를 한개씩 전송해주세요');
      fetchApi(`/v1/message-service`, `post`, {
        temp: 0,
        message: checkedRows[0]?.message,
        to: checkedRows[0]?.to,
        eventUuid: checkedRows[0]?.eventUuid,
      })
        .then(() => {
          apiUtils.fetchApi(`/v1/message-service/${checkedRows[0]?.uuid}`, 'delete');
        })
        .then(() => {
          AlertMessage.alertMessage('success', '생성이 성공적으로 완료되었습니다');
          setTabValue('2');
        })
        .catch((err) => console.log('[Line:27 err]', err));
    };
    return (
      <button className='btn-send' onClick={handleSendBtn}>
        보내기
      </button>
    );
  };

  return (
    <div className='sms-list-container'>
      <div className='sms-list-header'>
        <div className='title'>보관 {rows?.length}건</div>
        <div className='search-container'>
          <input
            type='text'
            className='search'
            placeholder='검색어를 입력해주세요'
            width={'100%'}
            onChange={(e) => {
              clearTimeout(intervalTimer);
              fetchTempSmsSendList(e.target.value);
            }}></input>
        </div>
      </div>
      <div className='table-container'>
        <div style={{ width: '100%', height: '500px', position: 'relative' }}>
          <Table
            columns={smsTempSavedListColumns}
            rows={rows}
            variant={'bright'}
            checkbox
            leftBtn={leftBtn()}
            rightBtn={rightBtn()}
            pageSize={PAGE_LIMIT}
            rowCount={totalCount}
            curPage={curPage}
            sx={{
              '& .MuiSvgIcon-root': {
                color: 'grey',
              },
              '& .Mui-selected .MuiSvgIcon-root': {
                color: '#496DED',
              },
            }}
            onCustomPaginationChange={fetchTempSmsSendList}
            onRowDoubleClick={(params) => {
              setSmsEditOpen(true);
              setSelectedRowData(params.row);
            }}
            componentsProps={{
              row: {
                style: {
                  borderRadius: 10,
                  marginBottom: 10,
                  backgroundColor: '#fff',
                  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
                },
              },
            }}
            onSelectionModelChange={(ids) => {
              let checkedData = [];
              rows.map((i) => {
                if (ids.includes(i.id)) return checkedData.push(i);
              });
              setCheckedRows(checkedData);
            }}
          />
          {apiUtils.renderLoading()}
        </div>
      </div>
      {smsEditOpen && <SmsEditModal open={smsEditOpen} setOpen={setSmsEditOpen} rowInfo={selectedRowData} setTabValue={setTabValue} />}
      {smsDeleteOpen && <DeleteSms open={smsDeleteOpen} setOpen={setSmsDeleteOpen} deleteData={checkedRows} fetchData={fetchTempSmsSendList} />}
    </div>
  );
};

export default SmsTempSaveComponent;
