import React from 'react';
import {BrowserRouter, Redirect, Switch} from 'react-router-dom';
import PermissionRoute from './PermissionRoute';
import ScrollToTop from '../components/ScrollToTop';
import SignIn from '../screens/auth/SignIn';
import GIS from '../screens/GIS';
import Event from '../screens/Event';
import MyPage from '../screens/MyPage';
import UserManage from '../screens/UserManage';
import DepartmentManage from '../screens/DepartmentManage';
import FacilityManage from '../screens/FacilityManage';
import Disaster from '../screens/Disaster';
import Statistics from '../screens/Statistics';
import {WSC} from '../service/WSC';
import {WSSEvent} from 'anuro-platform-core';
import AlertMessage, {NotificationMessage} from '../components/utilities/AlertMessage';

const REDIRECT_SIGN_IN = '/sign-in';

const unauthorizedOnly = {
  unauthorized: true,
  redirect: '/gis',
};

class Router extends React.Component {
  componentDidMount() {
    WSC.getInstance().socket.on(WSSEvent[WSSEvent.SHUTDOWN], () => {
      AlertMessage.serverMessage(NotificationMessage.Server.shutdown);
    });
  }

  componentWillUnmount() {
    WSC.getInstance().socket.off(WSSEvent[WSSEvent.SHUTDOWN]);
  }

  render() {
    return (
      <BrowserRouter>
        <ScrollToTop />
        <Switch atEnter={{ opacity: 0 }} atLeave={{ opacity: 0 }} atActive={{ opacity: 1 }} className='switch-wrapper'>
          <PermissionRoute title={'로그인'} path={`/sign-in`} component={SignIn} {...unauthorizedOnly} />

          <PermissionRoute title={'시설물 관리'} path={`/facility`} exact component={FacilityManage} authorized redirect={REDIRECT_SIGN_IN} />
          <PermissionRoute title={'GIS'} path={`/gis`} exact component={GIS} authorized redirect={REDIRECT_SIGN_IN} />
          <PermissionRoute title={'이벤트'} path={`/event`} exact component={Event} authorized redirect={REDIRECT_SIGN_IN} />
          <PermissionRoute title={'사용자 관리'} path={`/user-management`} exact component={UserManage} authorized redirect={REDIRECT_SIGN_IN} />
          <PermissionRoute title={'그룹관리'} path={`/department-management`} exact component={DepartmentManage} authorized redirect={REDIRECT_SIGN_IN} />
          <PermissionRoute title={'재난정보'} path={`/disaster`} exact component={Disaster} authorized redirect={REDIRECT_SIGN_IN} />
          <PermissionRoute title={'통계'} path={`/statistics`} exact component={Statistics} authorized redirect={REDIRECT_SIGN_IN} />

          {/* my-page */}
          <PermissionRoute title={'My Page'} path={`/my-page`} exact component={MyPage} authorized redirect={REDIRECT_SIGN_IN} />
          {/* <PermissionRoute title={"Facility Management"} path={`/my-page/facility-management`} exact component={FacilityManage} authorized unauthorized redirect={REDIRECT_MAIN} /> */}

          <Redirect path={'/'} to={'/gis'} />
          {/*<Route path={"/404"} component={Index} />*/}
          {/*<Route component={NotFound} />*/}
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Router;
