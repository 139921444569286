import React from 'react';
import SettingBaseModal from './SettingBaseModal';
import APIUtils from '../../utils/APIUtils';
import {ReactComponent as LOCK} from '../../icons/lock.svg';
import {ReactComponent as UNLOCK} from '../../icons/unlock.svg';
import {HiEye, HiEyeOff} from 'react-icons/hi';
import {USER} from "anuro-platform-core";
import AlertMessage from "../utilities/AlertMessage";
import PasswordPolicyComponent, {PASSWORD_RESULT} from "./PasswordPolicyComponent";


export default class ChangePasswordModal extends React.Component {
  apiUtils = new APIUtils();

  state = {
    focusKey: 'BeforePassword',
    isBeforePasswordHide: true,
    isNewPasswordHide: true,
    beforePassword: "",
    newPassword: "",
  };

  isPasswordAvailable = PASSWORD_RESULT.PASSWORD_TOO_SHORT;

  // !!! 비밀번호 입력 폼 렌더링
  passwordInputComponent = (key: 'BeforePassword' | 'NewPassword') => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          borderRadius: 4,
          padding: "15px 20px",
          margin: '20px 0',
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.35)',
          backgroundColor: this.state.focusKey === key ? '#3F4452' : '#FFFFFF',
        }}
        onFocus={() => {
          this.setState({ focusKey: key });
        }}>
        {this.state.focusKey === key ? <UNLOCK /> : <LOCK />}
        <input
          placeholder={key === 'BeforePassword' ? '현재 비밀번호 입력' : '변경할 비밀번호 입력'}
          onChange={(e) => {this.setState({[key === "BeforePassword" ? "beforePassword" : "newPassword"]: e.target.value})}}
          style={{
            width: '100%',
            margin: '0 10px',
            color: this.state.focusKey === key ? '#FFFFFF' : '#333333',
            backgroundColor: this.state.focusKey === key ? '#3F4452' : '#FFFFFF',
          }}
          type={this.state['is' + key + 'Hide'] ? 'password' : 'text'}
        />
        <div
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => {
            this.setState({ ['is' + key + 'Hide']: !this.state['is' + key + 'Hide'] });
          }}>
          {this.state['is' + key + 'Hide'] ? <HiEye size={20} color={this.state.focusKey === key ? "#FFFFFF" : "#333333"} /> : <HiEyeOff size={20} color={this.state.focusKey === key ? "#FFFFFF" : "#333333"}/>}
        </div>
      </div>
    );
  };

  // !!! 비밀번호 변경 api
  patchChangePassword = () => {
    const {beforePassword, newPassword} = this.state;
    if(beforePassword.length === 0) return AlertMessage.alertMessage("error", "현재 비밀번호를 입력해주세요");
    if(!this.isPasswordAvailable.status) return AlertMessage.alertMessage("error", this.isPasswordAvailable.message);
    if(!(newPassword.length >= USER.PASSWORD_MIN_LENGTH && newPassword.length <= USER.PASSWORD_MAX_LENGTH)) return AlertMessage.alertMessage("error", "변경할 비밀번호의 글자수는\n6~100자로 입력해야 합니다")

    this.apiUtils.fetchApi("/v1/me/password", "patch", {
      before: beforePassword,
      after: newPassword
    }).then(r => {
      AlertMessage.alertMessage("success", "비말번호가 변경되었습니다.");
      this.props.onClose();
    });
  }

  render() {
    const { onClose } = this.props;
    return (
      <SettingBaseModal open={true} onClose={() => onClose(false)} title={this.props.title} width={460} height={560} withBackground>
        <div className={'change-password-modal'}>
          <div>
            {this.passwordInputComponent('BeforePassword')}
            {this.passwordInputComponent('NewPassword')}
            <div style={{marginTop: 40, position: "relative", borderRadius: 4, border: "1px solid #ccc", padding: "25px 20px 15px 20px"}}>
              <PasswordPolicyComponent password={this.state.newPassword} getPasswordPoliyResult={(r) => this.isPasswordAvailable = r}/>
            </div>
          </div>
          <div className={'btn-wrapper'}>
            <div className={'btn2 cursor'} onClick={() => onClose(false)}>
              취소
            </div>
            <div className={'btn2 close cursor'} onClick={this.patchChangePassword}>
              변경
            </div>
          </div>
        </div>
        {this.apiUtils.renderLoading()}
      </SettingBaseModal>
    );
  }
}
