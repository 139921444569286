import React from 'react';
import {SETTING_PANEL, SETTING_TAB} from './SettingModal';
import AddServiceModal from './AddServiceModal';
import APIUtils from '../../utils/APIUtils';
import {IService, WSSEvent} from 'anuro-platform-core';
import AlertMessage from "../utilities/AlertMessage";
import {WSC} from "../../service/WSC";
import HandlingSocketData, {EXECUTE_TYPE} from "../utilities/HandlingSocketData";
import EventEmitter from "../../utils/EventEmitter";

export const PANEL_MODE = {
	normal: 'normal',
	manage: 'manage',
};
export default class ServiceListComponent extends React.Component {
  emitter = EventEmitter.getInstance();
  apiUtils = new APIUtils();

  state = {
    mode: PANEL_MODE.normal,
    addModalOpen: false,
    serviceList: [],
  };

  componentDidMount() {
    WSC.getInstance().socket.on(WSSEvent[WSSEvent.ORGANIZATION_SERVICE_ADD], (event) => this.onOrganizationWSSEvent(event, EXECUTE_TYPE.ADD));
    WSC.getInstance().socket.on(WSSEvent[WSSEvent.ORGANIZATION_SERVICE_MOD], (event) => this.onOrganizationWSSEvent(event, EXECUTE_TYPE.MOD));
    WSC.getInstance().socket.on(WSSEvent[WSSEvent.ORGANIZATION_SERVICE_DEL], (event) => this.onOrganizationWSSEvent(event, EXECUTE_TYPE.DEL));

    this.fetchServiceList();
  }

  componentWillUnmount() {
		WSC.getInstance().socket.off(WSSEvent[WSSEvent.ORGANIZATION_SERVICE_ADD]);
		WSC.getInstance().socket.off(WSSEvent[WSSEvent.ORGANIZATION_SERVICE_MOD]);
		WSC.getInstance().socket.off(WSSEvent[WSSEvent.ORGANIZATION_SERVICE_DEL]);
	}

  onOrganizationWSSEvent = (resultData, type) => {
    const serviceList = HandlingSocketData.update(type, this.state.serviceList, {organizationService: resultData}, "organizationService.id");
    this.setState({ serviceList }, () => {
      if(type === EXECUTE_TYPE.DEL) {
        this.emitter.emit('removeMarker', { markerType: 'event' });
        this.emitter.emit('removeMarker', { markerType: 'eventCircle' });
        this.emitter.emit("fetchEventList");
      }
    });
  }

  handleModal = (addModalOpen) => this.setState({ addModalOpen, mode: PANEL_MODE.normal });

  // !!! 연계서비스 목록 호출
  fetchServiceList = () => {
    this.apiUtils.fetchApi('/v1/me/organization/service', 'get').then((r) => {
      this.setState({ serviceList: r });
    });
  };

  // !!! 연계서비스 목록 렌더링
  renderServiceItem = () =>
    this.state.serviceList.length > 0 &&
    this.state.serviceList.map((i: IService, idx) => {
      return (
        <div
          key={idx}
          className={`item hover-opacity hover-transform-3 ${this.state.mode === PANEL_MODE.normal && 'cursor'}`}
          onClick={() => {
            if (this.state.mode === PANEL_MODE.normal) this.props.handleData(SETTING_PANEL.SERVICE_MANAGEMENT_SETTING, SETTING_TAB.SERVICE, i);
          }}>
          <img src={i.organizationService?.iconUrl} />
          <div className={'text-container'}>
            <div>{i.organizationService?.name}</div>

            <div className={'small-txt'}>
              <span className={`status-txt ${i?.organizationService?.active ? 'green' : 'red'}`}>●</span>
              {i?.organizationService?.active ? '활성' : '비활성'}
            </div>
          </div>
          {this.state.mode === PANEL_MODE.normal ? (
            <div>></div>
          ) : (
            <div
              className={'red-txt-btn'}
              onClick={() => {
                this.onDeleteService(i);
              }}>
              &times;
            </div>
          )}
        </div>
      );
    });

  // !!! 서비스 항목 삭제
  onDeleteService = async (i) => {
    const result = await AlertMessage.confirmMessage(`'${i.name}' 을(를)\n 삭제하시겠습니까?`);
    if (!result) return null;
    this.apiUtils.fetchApi(`/v1/me/organization/service/${i.organizationService.id}`, 'delete').then((r) => {
      this.fetchServiceList();
    });
  };

  // !!! 관리 버튼을 눌렀을 때
  onManage = () => this.setState({ mode: this.state.mode === PANEL_MODE.normal ? PANEL_MODE.manage : PANEL_MODE.normal });

  render() {
    return (
      <div className={'right list'}>
        <div style={{ height: 'calc(100% - 100px)' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>연계서비스 관리</div>
            <div className={`cursor txt-btn ${this.state.mode === PANEL_MODE.manage && 'active'}`} onClick={this.onManage}>
              관리
            </div>
          </div>
          <div className={'service-list'} style={{ position: 'relative' }}>
            {/*{this.state.serviceList.length === 0 && <NoDataFound emptyList />}*/}
            {this.renderServiceItem()}
            {this.apiUtils.renderLoading()}
          </div>
        </div>
        <button className={'btn hover-opacity hover-transform-3'} onClick={() => this.handleModal(true)}>
          + 연계서비스 추가
        </button>
        {this.state.addModalOpen && <AddServiceModal title='서비스 추가' type={SETTING_PANEL.SERVICE_MANAGEMENT_LIST} fetchData={this.fetchServiceList} onClose={this.handleModal} />}
      </div>
    );
  }
}
