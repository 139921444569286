import React from 'react';
import '../../css/setting.css';
import {ReactComponent as PROFILE} from '../../icons/header/profile_icon.svg';
import ServiceListComponent from './ServiceListComponent';
import ServiceSettingPanelComponent from './ServiceSettingPanelComponent';
import SettingBaseModal from './SettingBaseModal';
import SettingProfilePanelComponent from './SettingProfilePanelComponent';
import FacilityListComponent from './FacilityListComponent';
import APIUtils from '../../utils/APIUtils';
import {IUser} from 'anuro-platform-core';
import LoginHistoryPanelComponent from "./LoginHistoryPanelComponent";
import ModifyInformationPanelComponent from "./ModifyInformationPanelComponent";

export const SETTING_PANEL = {
  PROFILE: 'profile',
  PROFILE_LOGIN_HISTORY: 'login_history',
  PROFILE_MODIFY_INFORMATION: 'modify_information',
  SERVICE_MANAGEMENT_LIST: 'list',
  SERVICE_MANAGEMENT_SETTING: 'setting',
  FACILITY_MANAGEMENT: 'facility',
};

export const SETTING_TAB = {
  PROFILE: "profile",
  SERVICE: "service",
  FACILITY: "facility"
}

export default class SettingModal extends React.Component {
  apiUtils = new APIUtils();
  state = {
    mode: SETTING_PANEL.PROFILE,
    selectedItem: {},
    user: {},
    currentTab: SETTING_TAB.PROFILE,
  };

  componentDidMount() {
    this.fetchUserInformation();
  }

  handleData = (mode, currentTab, selectedItem) => {
    this.setState({
      mode,
      currentTab,
      selectedItem: selectedItem || {}
    });
  }

  // !!! 버튼 클릭 시에 대한 오른쪽 패널 렌더링
  renderPanel = () => {
    const mode = this.state.mode;
    if (mode === SETTING_PANEL.PROFILE) {
      /* 설정 메뉴 */
      return <SettingProfilePanelComponent fetchUserInformation={this.fetchUserInformation} userProfile={this.state.user} handleData={this.handleData}/>;
    } else if (mode === SETTING_PANEL.PROFILE_LOGIN_HISTORY) {
      /* 로그인 기록 */
      return <LoginHistoryPanelComponent handleData={this.handleData}/>
    } else if (mode === SETTING_PANEL.PROFILE_MODIFY_INFORMATION) {
      /* 정보 수정 */
      return <ModifyInformationPanelComponent handleData={this.handleData} user={this.state.user} fetchUserInformation={this.fetchUserInformation} updateUserInfo={this.updateUserInfo}/>
    } else if (mode === SETTING_PANEL.SERVICE_MANAGEMENT_LIST) {
      /* 연계서비스 관리 - 서비스 목록 */
      return <ServiceListComponent handleData={this.handleData} />;
    } else if (mode === SETTING_PANEL.SERVICE_MANAGEMENT_SETTING) {
      /* 연계서비스 관리 - 카테고리 설정 */
      return <ServiceSettingPanelComponent handleData={this.handleData} serviceData={this.state.selectedItem} />;
    } else if (mode === SETTING_PANEL.FACILITY_MANAGEMENT) {
      /* 시설물 관리 - 시설물 목록 */
      return <FacilityListComponent handleData={this.handleData} />;
    }
  };

  // !!! 사용자 계정 정보 가져오기
  fetchUserInformation = () => {
    this.apiUtils.fetchApi('/v1/me', 'get').then((user: IUser) => {
      this.setState({ user });
    });
  };

  updateUserInfo = (user: IUser) => {
    this.setState({user: JSON.parse(JSON.stringify(user))});
  }

  //!!! 왼쪽 상단에 사용자 프로필 렌더링
  renderUserProfile = () => {
    const { user } = this.state;
    return (
      <div className={`profile cursor ${this.state.currentTab === SETTING_TAB.PROFILE ? '' : 'hover-opacity'}`} style={{backgroundColor: this.state.currentTab === SETTING_TAB.PROFILE ? "#1B264918" : "transparent"}} onClick={() => this.handleData(SETTING_PANEL.PROFILE, SETTING_TAB.PROFILE)}>
        {user?.profile?.imageUrl ? <img width={46} height={46} src={user?.profile?.imageUrl} style={{ borderRadius: 100, marginRight: 10 }} /> : <PROFILE className={'profile-img'} />}
        <div>
          <div>{user?.profile?.name || ""} ({user?.username || ""})</div>
          <div className={'department'}>{user?.organization?.name || ""} | {user.groups?.length > 0 ? user?.groups[0]?.name : "그룹 미지정"}</div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <SettingBaseModal open={true} onClose={this.props.modalHandler} title={'설정'} width={760} height={620} withBackground>
        {/* 왼쪽 패널  */}
        <div className={'content2'} style={{ position: 'relative' }}>
          <div className={'left'}>
            {this.renderUserProfile()}

            {/* !!! 왼쪽 설정 목록 */}
            <div className={'setting-list'}>
              <div className={`item cursor ${this.state.currentTab === SETTING_TAB.SERVICE ? '' : 'hover-opacity'}`} style={{backgroundColor: this.state.currentTab === SETTING_TAB.SERVICE ? "#1B264918" : "transparent"}} onClick={() => this.handleData(SETTING_PANEL.SERVICE_MANAGEMENT_LIST, SETTING_TAB.SERVICE)}>
                <img src={require('../../icons/setting/setting-service.png')} />
                <div>연계서비스 관리</div>
              </div>
              <div className={`item cursor ${this.state.currentTab === SETTING_TAB.FACILITY ? '' : 'hover-opacity'}`} style={{backgroundColor: this.state.currentTab === SETTING_TAB.FACILITY ? "#1B264918" : "transparent"}} onClick={() => this.handleData(SETTING_PANEL.FACILITY_MANAGEMENT, SETTING_TAB.FACILITY)}>
                <img src={require('../../icons/setting/setting-facility.png')} />
                <div>시설물 관리</div>
              </div>
            </div>
          </div>
          {this.renderPanel()}
          {this.apiUtils.renderLoading(true)}
        </div>
      </SettingBaseModal>
    );
  }
}
