import React, {createRef} from 'react';
import {FaMapMarkerAlt} from 'react-icons/fa';
import ReactModal from 'react-modal-resizable-draggable';
import {inject, observer} from 'mobx-react';
import EventEmitter from '../../utils/EventEmitter';
import {MODE_WRITE} from './EventListPanelComponent';
import moment from 'moment';
import {CCTV_STATUS_AVAILABLE} from '../../stores/CCTVStore';
import {loadPlayer} from 'rtsp-relay/browser';
import CCTVControlComponent from './CCTVControlComponent';
import {api_tta} from '../../api/api_tta';
import APIUtils from '../../utils/APIUtils';
import ImageUtils from '../../utils/ImageUtils';
import AlertMessage from '../utilities/AlertMessage';
import {ReactComponent as CAPTURE} from '../../icons/camera_with_round.svg';
import {ReactComponent as FULLSCREEN} from '../../icons/fullscreen.svg';
import {ReactComponent as VIDEO_OFF} from '../../icons/video-off.svg';
import FacilityDetailModal from '../facilityManage/modals/FacilityDetailModal';
import EditFacilityModal from '../facilityManage/modals/EditFacilityModal';
import DeleteFacilityModal from '../facilityManage/modals/DeleteFacilityModal';

export const MODE = {
  normal: 'norma',
  delete: 'delete',
};

@inject('cctvStore')
@observer
export default class CCTVViewModal extends React.Component {
  emitter = EventEmitter.getInstance();
  apiUtils = new APIUtils();
  cctvCanvasRef = createRef();
  modalRef = createRef();

  player = null;

  state = {
    mode: MODE.normal,
    capturedImageList: [],
    selectAll: false,
    extendStorageImage: true,
    isVideoFullScreen: false,
    numberOfSelectedImg: 0,
    deleteProcess: 0,

    facilityDetailOpen: false,
    facilityEditOpen: false,
    facilityDeleteOpen: false,
  };

  componentDidMount() {
    this.emitter.on('fetchCapturedImage', this.fetchCapturedImage);
    document.addEventListener('keydown', this.videoKeyDownHandler);
    this.fetchCapturedImage();
    this.bringToFront();
    this.renderCCTV();

    this.modalRef?.focus();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.videoKeyDownHandler);
    try {
      this.player?.destroy?.();
    } catch (err) {
      console.log('[CCTVViewModal.js] player destroy catch => ', err);
    }

    this.emitter.off('fetchCapturedImage');
  }

  renderCCTV = () => {
    const facilityCctv = this.props.selectedCCTV.facilityCctv;
    loadPlayer({
      url: `${api_tta.getBaseURL().replace('http', 'ws')}/stream/${facilityCctv.id}?stream=${facilityCctv.rtspPath}`,
      canvas: this.cctvCanvasRef.current,
      preserveDrawingBuffer: true,
    }).then((r) => {
      this.player = r;
    });
  };

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if (prevProps.isWorking !== this.props.isWorking && this.props.isWorking) {
      this.renderCCTV();
    }
  }

  // !!! 캡처된 이미지 목록 호출 API
  fetchCapturedImage = () => {
    const cctvId = this.props.selectedCCTV.facilityCctv.id;
    this.apiUtils
      .fetchApi(`/v1/me/organization/facility/cctv/${cctvId}/snapshot`, 'get', {
        ob: 'id',
        od: 'DESC',
      })
      .then((r) => {
        this.setState(
          {
            capturedImageList: r.rows.map((i) => {
              return { ...i, checked: false };
            }),
          },
          () => {
            this.countSelectedImg();
            this.props.cctvStore.fetchCapturedImage();
          },
        );
      });
  };

  videoKeyDownHandler = (e) => {
    if (e.key === 'Escape' && this.state.isVideoFullScreen) {
      this.setState({
        isVideoFullScreen: false,
      });
    }
  };

  // !!! 이미지 캡처 버튼 누를 시
  onCapture = () => {
    if (!this.cctvCanvasRef.current) return null;
    const img = this.cctvCanvasRef.current.toDataURL('image/jpeg', 1);
    const selectedCCTV = this.props.selectedCCTV;

    const formData = new FormData();
    formData.append('ts', moment().unix());
    formData.append('image', ImageUtils.dataURLtoFile(img));

    this.apiUtils.fetchApi(`/v1/me/organization/facility/cctv/${selectedCCTV.facilityCctv.id}/snapshot`, 'post', { formData }).then((r) => {
      this.fetchCapturedImage();
    });
  };

  createEvent = (capturedImageList) => {
    const checkedImage = capturedImageList.length > 0 ? capturedImageList?.filter((i) => i.checked) : [];
    if (checkedImage.length > 0) {
      this.emitter.emit('changeEventListMode', { mode: MODE_WRITE, data: checkedImage });
    } else {
      this.emitter.emit('changeEventListMode', { mode: MODE_WRITE });
    }
  };

  countSelectedImg = () => {
    let cnt = this.state.capturedImageList.filter((i) => i.checked).length;
    this.setState({
      numberOfSelectedImg: cnt,
      deleteProcess: 0,
      selectAll: cnt === this.state.capturedImageList.length ? true : false,
    });
  };

  selectImg = (index, value) => {
    let tempArr = [...this.state.capturedImageList];
    tempArr[index].checked = value.target.checked;
    this.setState({ capturedImageList: tempArr }, () => this.countSelectedImg());
  };

  selectAll = (value) => {
    let tmpArr = this.state.capturedImageList.map((i) => {
      return { ...i, checked: value };
    });
    this.setState(
      {
        capturedImageList: [...tmpArr],
      },
      () => this.countSelectedImg(),
    );
  };

  // !!! 캡처 이미지 삭제 시
  deleteCapturedImage = async () => {
    const result = await AlertMessage.confirmMessage(`${this.state.numberOfSelectedImg}개의 갭쳐된 이미지를\n 삭제 하시겠습니까?`);
    if (!result) return null;
    this.setState(
      {
        mode: MODE.delete,
        deleteProcess: { idx: 1, total: this.state.numberOfSelectedImg },
      },
      () => {
        const numberOfSelectedImg = this.state.numberOfSelectedImg;
        let currentDeleteIdx = 1;

        this.state.capturedImageList.map((i, idx) => {
          if (i.checked) {
            this.apiUtils.fetchApi(`/v1/me/organization/facility/cctv/${i.cctvId}/snapshot/${i.uuid}`, 'delete').then((r) => {
              if (this.state.deleteProcess) {
                this.setState({
                  deleteProcess: {
                    total: numberOfSelectedImg,
                    idx: currentDeleteIdx++,
                  },
                });
              }
            });
          }

          if (this.state.capturedImageList.length - 1 === idx) {
            setTimeout(() => {
              this.fetchCapturedImage();
              this.setState({
                mode: MODE.normal,
              });
            }, 1000);
          }
        });
      },
    );
  };

  bringToFront = () => {
    let modalElement = document.getElementsByClassName('gis-cctv-modal');
    for (let i = 0; i < modalElement.length; i++) {
      if (Object.values(modalElement.item(i).classList).includes(`gis-cctv-modal-${this.props.index}`)) {
        modalElement.item(i).classList.add('bring-to-front');
      } else {
        modalElement.item(i).classList.remove('bring-to-front');
      }
    }
  };

  // !!! 삭제 시 진행바 렌더링
  renderProgressBar = () => {
    let progress = (this.state.deleteProcess.idx / this.state.deleteProcess.total) * 100;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 8 }}>
            <img src={require('../../icons/refresh.png')} />
          </div>
          <div style={{ fontWeight: 'bold' }}>삭제중...</div>
        </div>
        <div
          style={{
            background: `linear-gradient(to right, #558B2F ${progress.toFixed(0) || 1}%, #757575 ${progress.toFixed(0) || 99}%)`,
            width: 'calc(100% - 100px)',
            height: 30,
            borderRadius: 5,
            lineHeight: '30px',
            textAlign: 'center',
            fontWeight: 'bold',
          }}>
          {`${this.state.deleteProcess.idx}/${this.state.deleteProcess.total}(${((this.state.deleteProcess.idx / this.state.deleteProcess.total) * 100).toFixed(1)})`}
        </div>
      </div>
    );
  };

  modalHandler = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const { selectedCCTV } = this.props;
    return (
      <ReactModal
        key={selectedCCTV.uuid}
        className={`gis-cctv-modal ${this.state.isVideoFullScreen ? 'unset' : ''} gis-cctv-modal-${this.props.index}`}
        initWidth={530}
        minWidth={530}
        minHeight={650}
        maxHeight={800}
        initHeight={'fit-content'}
        onFocus={() => this.bringToFront()}
        top={window.innerHeight / 5}
        left={window.innerWidth / 4}
        onRequestClose={() => {}}
        isOpen={true}>
        <div
          ref={(e) => (this.modalRef = e)}
          className={'body'}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Escape') this.props.modalHandler(selectedCCTV, false);
          }}>
          <div className={'header'}>
            <div className={'place'}>{selectedCCTV?.name || ''}</div>
            <div>
              <div className={'modify'} onClick={(e) => this.modalHandler('facilityDetailOpen', e)}>
                시설 정보
              </div>
              <div
                className={'close'}
                onClick={() => {
                  this.props.modalHandler(selectedCCTV, false);
                  this.selectAll(false);
                }}>
                &times;
              </div>
            </div>
          </div>
          <div className={'address'}>
            <FaMapMarkerAlt style={{ marginRight: 5 }} />
            <span>{selectedCCTV?.addressStreet || ''}</span>
          </div>
          <div className={'view-panel'}>
            <div className={`view ${this.state.isVideoFullScreen ? 'full-screen' : ''}`} id={'view'}>
              {selectedCCTV.active === CCTV_STATUS_AVAILABLE ? (
                !this.props.isWorking ? (
                  <div className={'no-video'}>
                    <VIDEO_OFF />
                    <p style={{ marginTop: 7, fontSize: 12, textAlign: 'center' }}>
                      스트림 없음
                      <br />
                      No stream available
                    </p>
                  </div>
                ) : (
                  <>
                    <div className={`live ${this.state.isVideoFullScreen ? 'full-screen' : ''}`}>
                      LIVE <span style={{ marginLeft: 4, fontSize: 12 }}>●</span>
                    </div>
                    <div className={`video-btn ${this.state.isVideoFullScreen ? 'full-screen' : ''}`}>
                      <div style={{ marginRight: 7 }} onClick={() => this.setState({ isVideoFullScreen: !this.state.isVideoFullScreen })}>
                        <FULLSCREEN />
                      </div>
                      <div key={selectedCCTV.cctvId} onClick={this.onCapture}>
                        <CAPTURE />
                      </div>
                    </div>
                    <canvas ref={this.cctvCanvasRef} key={`cctv-screen-${selectedCCTV.uuid}`} id={`cctv-screen-${selectedCCTV.uuid}`} style={{ objectFit: 'fill', width: '100%', height: '100%' }} />
                  </>
                )
              ) : (
                <div className={'no-video'}>
                  <VIDEO_OFF />
                  <p style={{ marginTop: 7, fontSize: 12, textAlign: 'center', lineHeight: 1.2 }}>
                    시설물이 비활성 상태입니다
                    <br />
                    Facility is disabled
                  </p>
                </div>
              )}
            </div>

            {/* !!! CCTV 컨트롤 패널 */}
            <CCTVControlComponent cctv={selectedCCTV.facilityCctv} />
          </div>

          <div className={'place-name'}>
            <div>
              <img src={require('../../icons/cctv2.png')} style={{ margin: '0 8px' }} />
              <span style={{ fontWeight: 'bold', marginRight: 3, color: '#999999' }}>CCTV</span>
              <span
                style={{
                  fontSize: 12,
                  color: '#999999',
                  fontWeight: 'bold',
                }}>
                (/{selectedCCTV.facilityCctv.rtspPath})
              </span>
            </div>
            <div>
              <span style={{ fontSize: 11, color: '#999999' }}>캡처를 통해 이벤트 상황을 등록하세요</span>
            </div>
          </div>
          <div className={'divide'} />
          <div className={'bottom'}>
            <button
              onClick={() => {
                this.setState({ extendStorageImage: !this.state.extendStorageImage });
              }}>
              캡처 보관함 <span style={{ fontWeight: 700, margin: '0 5px 0 3px' }}>{this.state.capturedImageList.length}</span>
              <img src={require('../../icons/arrow3.png')} />
            </button>
            <div>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input type={'checkbox'} id={`select-all-${selectedCCTV.cctvId}`} checked={this.state.selectAll} onChange={(e) => this.selectAll(e.target.checked)} />
                  <label style={{ color: '#999999', fontSize: 14 }} htmlFor={`select-all-${selectedCCTV.cctvId}`}>
                    전체선택
                  </label>
                </div>
              </div>
              {/*) : (*/}
              {/*  <button className={'create'} onClick={this.createEvent}>*/}
              {/*    이벤트 생성*/}
              {/*  </button>*/}
              {/*)}*/}
            </div>
          </div>

          {/*{this.state.extendStorageImage && this.state.capturedImageList.length > 0 && (*/}
          <div style={{ height: 'calc(50% - 50px)' }}>
            <div className={'capture-img scroll-b'}>
              {this.state.capturedImageList.map((item, index) => {
                return (
                  <label htmlFor={`cctv-${item.cctvId}-${item.id}`} key={index}>
                    <input
                      id={`cctv-${item.cctvId}-${item.id}`}
                      type={'checkbox'}
                      checked={item.checked}
                      onChange={(value) => {
                        this.selectImg(index, value);
                      }}
                    />
                    <img key={`cctv-${item.cctvId}-${item.id}`} src={item.url} />
                    {item.checked && <div className={'cover'} />}
                  </label>
                );
              })}
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              {this.state.mode === MODE.normal ? (
              <>
                <span style={{ cursor: 'pointer' }} onClick={this.deleteCapturedImage}>
                  <img src={require('../../icons/trash.png')} />
                </span>
                <button
                  className={'create'}
                  style={{ margin: 0 }}
                  onClick={() => {
                    this.setState({});
                    this.createEvent(this.state.capturedImageList);
                  }}>
                  <img src={require('../../icons/attachment2.png')} style={{ marginRight: 9, objectFit: 'fill' }} />
                  {this.state.numberOfSelectedImg}개 첨부하기
                </button>
              </>
              ) : (
                this.renderProgressBar()
              )}
            </div>
          </div>
          {/*)}*/}
        </div>
        <FacilityDetailModal open={this.state.facilityDetailOpen} setOpen={(e) => this.modalHandler('facilityDetailOpen', e)} setFacilityDeleteOpen={(e) => this.modalHandler('facilityDeleteOpen', e)} setEditOpen={(e) => this.modalHandler('facilityEditOpen', e)} rowInfo={selectedCCTV} />
        <EditFacilityModal open={this.state.facilityEditOpen} setOpen={(e) => this.modalHandler('facilityEditOpen', e)} rowInfo={selectedCCTV} fetchTable={() => this.props.fetchCCTVList()} />
        <DeleteFacilityModal open={this.state.facilityDeleteOpen} setOpen={(e) => this.modalHandler('facilityDeleteOpen', e)} rowInfo={selectedCCTV} setFacilityDetailOpen={(e) => this.modalHandler('facilityDetailOpen', e)} fetchTable={() => this.props.fetchCCTVList()} />

        {this.apiUtils.renderLoading()}
      </ReactModal>
    );
  }
}
