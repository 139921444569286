import React from 'react';
import SettingBaseModal from './SettingBaseModal';
import {SETTING_PANEL} from './SettingModal';
import APIUtils from '../../utils/APIUtils';
import {IService} from 'anuro-platform-core';
import NoDataFound from '../NoDataFound';
import AlertMessage from '../utilities/AlertMessage';

export default class AddServiceModal extends React.Component {

	apiUtils = new APIUtils();

	state = {
		selectedItem: {},
		list: [],
	};

	componentDidMount() {
		this.fetchList();
	}

	onChange = (key, value) => this.setState({ [key]: value });

	//!!! API - 조직에 연계서비스 or 카테고리 목록 호출
	fetchList = () => {
		if (this.props.type === SETTING_PANEL.SERVICE_MANAGEMENT_LIST) {
			this.apiUtils.fetchApi('/v1/service', 'get').then((r) => {
				this.setState({ list: r });
			});
		} else if (this.props.type === SETTING_PANEL.SERVICE_MANAGEMENT_SETTING) {
			this.apiUtils.fetchApi(`/v1/service/${this.props.serviceData.uuid}/event-category`, 'get').then((r) => {
				this.setState({ list: r });
			});
		} else if (this.props.type === SETTING_PANEL.FACILITY_MANAGEMENT) {
			this.apiUtils.fetchApi(`/v1/facility-type`, 'get').then((r) => {
				this.setState({ list: r });
			});
		}
	};

	isEmptyObject = (obj) => {
		return Object.keys(obj).length === 0;
	};

	//!!! 조직에 연계서비스 or 카테고리 등록 or 시설물 등록
	addItem = () => {
		if (this.state.list.length === 0) return AlertMessage.alertMessage('error', '추가할 항목이 없습니다.');
		if (this.isEmptyObject(this.state.selectedItem)) return AlertMessage.alertMessage('error', '선택된 항목이 없습니다');

		const { type } = this.props;
		let url = "";

		if(type === SETTING_PANEL.SERVICE_MANAGEMENT_LIST) {
			url = '/v1/me/organization/service';
		} else if (type === SETTING_PANEL.SERVICE_MANAGEMENT_SETTING) {
			url = `/v1/me/organization/service/${this.props.serviceData.organizationService.id}/event-category`;
		} else if (type === SETTING_PANEL.FACILITY_MANAGEMENT) {
			url = `/v1/me/organization/facility-type`;
		}

		this.apiUtils.fetchApi(url, 'post', {
			...(type === SETTING_PANEL.SERVICE_MANAGEMENT_LIST && { uuid: [this.state.selectedItem.uuid] }),
			...(type === SETTING_PANEL.SERVICE_MANAGEMENT_SETTING && { eventCategoryId: this.state.selectedItem.id }),
			...(type === SETTING_PANEL.FACILITY_MANAGEMENT && { uuid: [this.state.selectedItem.uuid] }),
		}).then((r) => {
			this.props.fetchData();
			this.props.onClose(false);
		});
	};

	//!!! 연계서비스 관리 목록 렌더
	renderList = () =>
		this.state.list.length > 0 ? (
			this.state.list.map((i: IService, idx) => {
				return (
					<div key={idx} className={`item cursor ${this.state.selectedItem.uuid === i.uuid ? 'selectedItem' : ''}`} onClick={() => this.onChange('selectedItem', i)}>
						<div className={'img-wrapper'}>
							<img src={i.iconUrl} />
						</div>
						<div className={'category-list'}>
							<div className={'service-name'}>
								<div>{i.name}</div>
								<div>></div>
							</div>
							<div>
								{i.eventCategories.map((j, idx) => {
									return (
										<div key={`ec-${j.id}`} className={'category-item'}>
											<img src={require(`../../icons/text-decoration${this.state.selectedItem.uuid === i.uuid ? '-w' : ''}.png`)} /> {j.name}
										</div>
									);
								})}
							</div>
						</div>
					</div>
				);
			})
		) : (
			<NoDataFound emptyList />
		);

	//!!! 연계서비스 목록에서 하나 선택 후 카테고리 목록 렌더링
	renderServiceCategoryList = () =>
		this.state.list.length > 0 ? (
			this.state.list.map((i, idx) => {
				return (
					<div key={idx} onClick={() => this.onChange('selectedItem', i)} className={`item cursor category-item ${this.state.selectedItem.id === i.id ? 'selectedItem' : ''}`}>
						{i.name}
					</div>
				);
			})
		) : (
			<NoDataFound emptyList />
		);

	render() {
		const { type, onClose } = this.props;
		return (
			<SettingBaseModal
				open={true}
				onClose={() => onClose(false)}
				title={this.props.title}
				width={460}
				height={460}
				withBackground>
				<div className={'add-modal'}>
					<div className={'list-wrapper'}>{type === SETTING_PANEL.SERVICE_MANAGEMENT_LIST ? this.renderList() : this.renderServiceCategoryList()}</div>
					<div className={'btn-wrapper'}>
						<div className={'btn2 cursor'} onClick={() => onClose(false)}>
							닫기
						</div>
						<div className={'btn2 close cursor'} onClick={this.addItem}>
							추가
						</div>
					</div>
				</div>
				{this.apiUtils.renderLoading()}
			</SettingBaseModal>
		);
	}
}
