// import { useEffect, useState } from 'react';
// import '../css/myPage/userManage.css';
// import DeleteFailedModal from '../components/userManage/modals/DeleteFailedModal';
// import { ReactComponent as GRAY_CROWN } from '../icons/user/crown_gray_icon.svg';
// import UserDetailModal from '../components/userManage/modals/UserDetailModal';
// import DeleteUserModal from '../components/userManage/modals/DeleteUserModal';
// import { ReactComponent as CROWN } from '../icons/user/crown_green_icon.svg';
// import HeaderComponent from '../components/groupManagement/HeaderComponent';
// import RegiUserModal from '../components/userManage/modals/RegiUserModal';
// import AlertMessage from '../components/utilities/AlertMessage';
// import { accAccessOptions } from '../constants/SelectOptions';
// import PermissionFilter from '../components/PermissionFilter';
// import ServiceIconList from '../components/ServiceIconList';
// import CommonHeader from '../components/HeaderComponent';
// import APIUtils, { fetchApi } from '../utils/APIUtils';
// import Container from '../components/Container';
// import SelectBox from '../components/SelectBox';
// import useSelect from '../hooks/useSelect';
// import useInput from '../hooks/useInput';
// import Table from '../components/Table';
// import Input from '../components/Input';
// import PermissionList from '../components/Permission/PermissionList';
// import { IUser } from 'anuro-platform-core';
// import useAsyncList from '../hooks/useAsyncList';
//
// interface IUserRes {
//   count: number;
//   rows: IUser[];
// }
//
// const userManageColumns = [
//   {
//     field: 'id',
//     headerName: 'No',
//     width: 80,
//   },
//   {
//     field: 'groups',
//     headerName: '그룹',
//     flex: 0.5,
//     renderCell: (params) => {
//       const MASTER = params?.row?.groups[0]?.ThroughGroupUser.master === 1;
//       if (!params?.row?.groups[0]) return '미지정';
//       if (MASTER)
//         return (
//           <div style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
//             <CROWN />
//             <div style={{ marginLeft: 5, color: '#03A8A8' }}>{params.row?.groups[0]?.name}</div>
//           </div>
//         );
//       return (
//         <div style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
//           <GRAY_CROWN />
//           <div style={{ marginLeft: 5, color: 'white' }}>{params.row?.groups[0]?.name}</div>
//         </div>
//       );
//     },
//   },
//   {
//     field: `name`,
//     headerName: '이름',
//     flex: 1,
//     valueGetter: (params) => `${params?.row?.profile?.name}`,
//   },
//   {
//     field: `username`,
//     headerName: '아이디',
//     flex: 1,
//   },
//   {
//     field: 'type',
//     headerName: '유형',
//     width: 150,
//     valueGetter: (params) => {
//       const MASTER = params.value === 'ACCOUNT_HOLDER';
//       const SUPER_USER = params.value === 'SUPER_USER';
//       if (SUPER_USER) return '관리자';
//       if (MASTER) return '계정소유자';
//       return '일반';
//     },
//   },
//   {
//     field: 'phone',
//     headerName: '연락처',
//     flex: 1,
//     valueGetter: (params) => params.row?.profile?.phoneNumber || '',
//   },
//   {
//     field: 'event_access',
//     headerName: '이벤트 권한',
//     flex: 2,
//     renderCell: (params) => {
//       return <ServiceIconList user={params.row} />;
//     },
//   },
// ];
//
// const apiUtils = new APIUtils();
// const PAGE_LIMIT = 6;
//
// const UserManage = () => {
//   const [isDeleteOpen, setIsDeleteOpen] = useState(false);
//   const [cannotDeleteConfirmOpen, setCannotDeleteConfirmOpen] = useState(false);
//   const [regiOpen, setRegiOpen] = useState(false);
//
//   const [userDetailOpen, setUserDetailOpen] = useState(false);
//
//   const [selectedRowData, setSelectedRowData] = useState({});
//
//   const [rows, setRows] = useState([]);
//
//   const [totalCount, setTotalCount] = useState(0);
//   const [curPage, setCurPage] = useState(1);
//
//   const searchInput = useInput('');
//   const userTypeSelection = useSelect([]);
//   const departmentSelection = useSelect([]);
//
//   const [checkedRows, setCheckedRows] = useState([]);
//
//   const [permissions, setPermissions] = useState([]);
//   const [isPermissionFetched, setIsPermissionFetched] = useState(false);
//
//   const handleRowDoubleClick = (params) => {
//     console.log('[Line:125 onclicked]');
//     setSelectedRowData(params.row);
//     setUserDetailOpen(true);
//   };
//
//   const handleDeleteBtn = () => {
//     if (checkedRows.length < 1) return AlertMessage.alertMessage('error', '삭제할 사용자를 선택해 주세요');
//     if (checkedRows.length > 1) return AlertMessage.alertMessage('error', '두명 이상의 사용자는 선택하실 수 없습니다');
//     setSelectedRowData(checkedRows[0]);
//     setIsDeleteOpen(true);
//   };
//
//   const handleRegiBtn = () => {
//     setRegiOpen(true);
//   };
//
//   const leftBtn = () => {
//     return (
//       <button className='btn-del btn-bottom' onClick={handleDeleteBtn}>
//         삭제
//       </button>
//     );
//   };
//
//   const rightBtns = () => {
//     return (
//       <button className='btn-regi' onClick={handleRegiBtn}>
//         + 사용자 추가
//       </button>
//     );
//   };
//
//   const departmentOptions = () => {
//     const departmentsArr = [];
//     fetchApi(`/v1/me/organization/group?ob=id&od=DESC`, `get`).then((res) => {
//       res.rows.map((e) => {
//         return departmentsArr.push({
//           value: e?.uuid,
//           label: e?.name,
//         });
//       });
//     });
//     return departmentsArr;
//   };
//
//   const fetchTable = (e = null, p = 1) => {
//     setCurPage(p);
//     apiUtils
//       .fetchApi('/v1/me/organization/user', 'get', {
//         limit: PAGE_LIMIT,
//         offset: p ? (p - 1) * PAGE_LIMIT : 0,
//         ob: 'id',
//         od: 'DESC',
//         groupUuid: departmentSelection.value,
//         type: userTypeSelection.value,
//         q: searchInput.value,
//         permissions: permissions.map((p) => p.organizationServiceId + '_' + p.type).join(','),
//       })
//       .then((r) => {
//         setRows(r.rows);
//         setTotalCount(r.count);
//       });
//   };
//
//   useEffect(() => {
//     fetchTable();
//   }, [searchInput.value, userTypeSelection.value, departmentSelection.value, permissions]);
//
//   return (
//     <Container>
//       <CommonHeader pageTitle={'Department'} />
//       <div className='user-container'>
//         <div className='content'>
//           <div className='right'>
//             <HeaderComponent page={'user'} />
//             <div className='search-container'>
//               <div style={{ display: 'flex' }}>
//                 <SelectBox isClearable onChange={departmentSelection.onChange} options={departmentOptions()} variant={'primary'} placeholder={'그룹'}></SelectBox>
//                 <SelectBox isClearable onChange={userTypeSelection.onChange} options={accAccessOptions} variant={'primary'} placeholder={'회원등급'}></SelectBox>
//                 <Input value={searchInput.value} onChange={searchInput.onChange} variant={'primary'} width={300} placeholder={'ID 검색'} />
//               </div>
//               <div style={{ display: 'flex' }}>
//                 <PermissionFilter uuid={'uuid'} category={'user'} setPermissionList={setPermissions} />
//               </div>
//             </div>
//             <div style={{ width: '100%', height: '75%', position: 'relative' }}>
//               <Table
//                 columns={userManageColumns}
//                 rows={rows}
//                 checkbox
//                 leftBtn={leftBtn()}
//                 rightBtn={rightBtns()}
//                 onRowDoubleClick={handleRowDoubleClick}
//                 pageSize={PAGE_LIMIT}
//                 rowCount={totalCount}
//                 curPage={curPage}
//                 onCustomPaginationChange={fetchTable}
//                 disableSelectionOnClick
//                 componentsProps={{
//                   row: {
//                     style: {
//                       background: 'linear-gradient(90.12deg, #313644 0.02%, #1E2530 79.25%)',
//                       fontWeight: 'bold',
//                       borderRadius: 10,
//                       marginBottom: 10,
//                     },
//                   },
//                 }}
//                 onSelectionModelChange={(ids) => {
//                   const selectedIDs = new Set(ids);
//                   const selectedRows = rows.filter((row) => selectedIDs.has(row.id));
//                   setCheckedRows(selectedRows);
//                 }}
//               />
//               {apiUtils.renderLoading()}
//             </div>
//           </div>
//         </div>
//         {/* 등록 - C */}
//         <RegiUserModal open={regiOpen} setOpen={setRegiOpen} rowInfo={selectedRowData} fetchTable={fetchTable} />
//         {/* 조회 - R */}
//         <UserDetailModal open={userDetailOpen} setOpen={setUserDetailOpen} isDeleteOpen={setIsDeleteOpen} rowInfo={rows?.find((row) => row.uuid === selectedRowData?.uuid)} fetchTable={fetchTable} setIsPermissionFetched={setIsPermissionFetched} />
//         {/* 삭제 - D */}
//         <DeleteUserModal open={isDeleteOpen} setOpen={setIsDeleteOpen} rowInfo={selectedRowData} fetchTable={fetchTable} />
//         {/* 삭제 실패 모달 */}
//         <DeleteFailedModal open={cannotDeleteConfirmOpen} setOpen={setCannotDeleteConfirmOpen} setIsDeleteOpen={setIsDeleteOpen} />
//       </div>
//     </Container>
//   );
// };
//
// export default UserManage;

import {useEffect, useState} from 'react';
import '../css/myPage/userManage.css';
import DeleteFailedModal from '../components/userManage/modals/DeleteFailedModal';
import {ReactComponent as GRAY_CROWN} from '../icons/user/crown_gray_icon.svg';
import UserDetailModal from '../components/userManage/modals/UserDetailModal';
import DeleteUserModal from '../components/userManage/modals/DeleteUserModal';
import {ReactComponent as CROWN} from '../icons/user/crown_green_icon.svg';
import HeaderComponent from '../components/groupManagement/HeaderComponent';
import RegiUserModal from '../components/userManage/modals/RegiUserModal';
import AlertMessage from '../components/utilities/AlertMessage';
import {accAccessOptions} from '../constants/SelectOptions';
import PermissionFilter from '../components/PermissionFilter';
import ServiceIconList from '../components/ServiceIconList';
import CommonHeader from '../components/HeaderComponent';
import APIUtils, {fetchApi} from '../utils/APIUtils';
import Container from '../components/Container';
import SelectBox from '../components/SelectBox';
import useSelect from '../hooks/useSelect';
import useInput from '../hooks/useInput';
import Table from '../components/Table';
import Input from '../components/Input';
import DeleteBtn from '../components/Table/DeleteBtn/DeleteBtn';
import RightBtn from '../components/Table/RightBtn/RightBtn';

const apiUtils = new APIUtils();
const PAGE_LIMIT = 6;

const UserManage = () => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [cannotDeleteConfirmOpen, setCannotDeleteConfirmOpen] = useState(false);
  const [regiOpen, setRegiOpen] = useState(false);

  const [userDetailOpen, setUserDetailOpen] = useState(false);

  const [selectedRowData, setSelectedRowData] = useState({});

  const [rows, setRows] = useState([]);

  const [totalCount, setTotalCount] = useState(0);
  const [curPage, setCurPage] = useState(1);

  const searchInput = useInput('');
  const userTypeSelection = useSelect([]);
  const departmentSelection = useSelect([]);

  const [checkedRows, setCheckedRows] = useState([]);

  const [permissions, setPermissions] = useState([]);
  const [isPermissionFetched, setIsPermissionFetched] = useState(false);

  const userManageColumns = [
    {
      field: 'id',
      headerName: 'No',
      width: 80,
    },
    {
      field: 'groups',
      headerName: '그룹',
      flex: 0.5,
      renderCell: (params) => {
        const MASTER = params?.row?.groups[0]?.ThroughGroupUser.master === 1;
        if (!params?.row?.groups[0]) return '미지정';
        if (MASTER)
          return (
            <div style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
              <CROWN />
              <div style={{ marginLeft: 5, color: '#03A8A8' }}>{params.row?.groups[0]?.name}</div>
            </div>
          );
        return (
          <div style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
            <GRAY_CROWN />
            <div style={{ marginLeft: 5, color: 'white' }}>{params.row?.groups[0]?.name}</div>
          </div>
        );
      },
    },
    {
      field: `name`,
      headerName: '이름',
      flex: 1,
      valueGetter: (params) => `${params?.row?.profile?.name}`,
    },
    {
      field: `username`,
      headerName: '아이디',
      flex: 1,
    },
    {
      field: 'type',
      headerName: '유형',
      width: 150,
      valueGetter: (params) => {
        const MASTER = params.value === 'ACCOUNT_HOLDER';
        const SUPER_USER = params.value === 'SUPER_USER';
        if (SUPER_USER) return '관리자';
        if (MASTER) return '계정소유자';
        return '일반';
      },
    },
    {
      field: 'phone',
      headerName: '연락처',
      flex: 1,
      valueGetter: (params) => params.row?.profile?.phoneNumber || '',
    },
    {
      field: 'event_access',
      headerName: '이벤트 권한',
      flex: 2,
      renderCell: (params) => {
        return <ServiceIconList uuid={params.row.uuid} groupUuid={params.row?.groups[0]?.uuid} category={'user'} setIsPermissionFetched={setIsPermissionFetched} isPermissionFetched={isPermissionFetched} />;
      },
    },
  ];

  const handleRowDoubleClick = (params) => {
    setSelectedRowData(params.row);
    setUserDetailOpen(true);
  };

  const handleDeleteBtn = () => {
    if (checkedRows.length < 1) return AlertMessage.alertMessage('error', '삭제할 사용자를 선택해 주세요');
    if (checkedRows.length > 1) return AlertMessage.alertMessage('error', '두명 이상의 사용자는 선택하실 수 없습니다');
    setSelectedRowData(checkedRows[0]);
    setIsDeleteOpen(true);
  };

  const handleRegiBtn = () => {
    setRegiOpen(true);
  };

  const rightBtn = () => {
    return <RightBtn onClick={handleRegiBtn}>+ 사용자 추가</RightBtn>;
  };

  const departmentOptions = () => {
    const departmentsArr = [];
    fetchApi(`/v1/me/organization/group?ob=id&od=DESC`, `get`).then((res) => {
      res.rows.map((e) => {
        return departmentsArr.push({
          value: e?.uuid,
          label: e?.name,
        });
      });
    });
    return departmentsArr;
  };

  const fetchTable = (e = null, p = 1) => {
    setCurPage(p);
    apiUtils
      .fetchApi('/v1/me/organization/user', 'get', {
        limit: PAGE_LIMIT,
        offset: p ? (p - 1) * PAGE_LIMIT : 0,
        ob: 'id',
        od: 'DESC',
        groupUuid: departmentSelection.value,
        type: userTypeSelection.value,
        q: searchInput.value,
        permissions: permissions.map((p) => p.organizationServiceId + '_' + p.type).join(','),
      })
      .then((r) => {
        setRows(r.rows);
        setTotalCount(r.count);
      });
  };

  useEffect(() => {
    fetchTable();
  }, [searchInput.value, userTypeSelection.value, departmentSelection.value, permissions]);

  return (
    <Container>
      <CommonHeader pageTitle={'Department'} />
      <div className='user-container'>
        <div className='content'>
          <div className='right'>
            <HeaderComponent page={'user'} />
            <div className='search-container'>
              <div style={{ display: 'flex' }}>
                <SelectBox isClearable onChange={departmentSelection.onChange} options={departmentOptions()} variant={'primary'} placeholder={'그룹'}></SelectBox>
                <SelectBox isClearable onChange={userTypeSelection.onChange} options={accAccessOptions} variant={'primary'} placeholder={'회원등급'}></SelectBox>
                <Input value={searchInput.value} onChange={searchInput.onChange} variant={'primary'} width={300} placeholder={'ID 검색'} />
              </div>
              <div style={{ display: 'flex' }}>
                <PermissionFilter uuid={'uuid'} category={'user'} setPermissionList={setPermissions} />
              </div>
            </div>
            <div style={{ width: '100%', height: '75%', position: 'relative' }}>
              <Table
                columns={userManageColumns}
                rows={rows}
                checkbox
                leftBtn={DeleteBtn(handleDeleteBtn)}
                rightBtn={rightBtn()}
                onRowDoubleClick={handleRowDoubleClick}
                pageSize={PAGE_LIMIT}
                rowCount={totalCount}
                curPage={curPage}
                onCustomPaginationChange={fetchTable}
                disableSelectionOnClick
                componentsProps={{
                  row: {
                    style: {
                      background: 'linear-gradient(90.12deg, #313644 0.02%, #1E2530 79.25%)',
                      fontWeight: 'bold',
                      borderRadius: 10,
                      marginBottom: 10,
                    },
                  },
                }}
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRows = rows.filter((row) => selectedIDs.has(row.id));
                  setCheckedRows(selectedRows);
                }}
              />
              {apiUtils.renderLoading()}
            </div>
          </div>
        </div>
        {/* 등록 - C */}
        <RegiUserModal open={regiOpen} setOpen={setRegiOpen} rowInfo={selectedRowData} fetchTable={fetchTable} />
        {/* 조회 - R */}
        <UserDetailModal open={userDetailOpen} setOpen={setUserDetailOpen} isDeleteOpen={setIsDeleteOpen} rowInfo={rows?.find((row) => row.uuid === selectedRowData?.uuid)} fetchTable={fetchTable} setIsPermissionFetched={setIsPermissionFetched} />
        {/* 삭제 - D */}
        <DeleteUserModal open={isDeleteOpen} setOpen={setIsDeleteOpen} rowInfo={selectedRowData} fetchTable={fetchTable} />
        {/* 삭제 실패 모달 */}
        <DeleteFailedModal open={cannotDeleteConfirmOpen} setOpen={setCannotDeleteConfirmOpen} setIsDeleteOpen={setIsDeleteOpen} />
      </div>
    </Container>
  );
};

export default UserManage;
