import React from "react";
import '../../css/gis.css';
import EventItemComponent from "./EventItemComponent";
import EventWriteComponent from "./EventWriteComponent";
import EventEmitter from "../../utils/EventEmitter";
import MessageBox from "../MessageBox";
import APIUtils from "../../utils/APIUtils";
import {WSC} from "../../service/WSC";
import {IEvent, SERVICE, SocketParser, WSSEvent} from "anuro-platform-core";
import AlertMessage from "../utilities/AlertMessage";

import {ReactComponent as REFRESH} from "../../icons/refresh.svg";
import {ReactComponent as SIREN_RED} from "../../icons/siren_red.svg";
import {ReactComponent as SIREN_WHITE} from "../../icons/siren_white.svg";
import {Switch} from "@mui/material";
import {inject} from "mobx-react";
import {EVENT_PROGRESS_CODE, EVENT_STATUS} from "../../constants/Event";
import HandlingSocketData, {EXECUTE_TYPE} from "../utilities/HandlingSocketData";

export const MODE_LIST = '1';
export const MODE_WRITE = '2';

@inject("sessionStore")
export default class EventListPanelComponent extends React.Component {
	idx = 0;
	emitter = EventEmitter.getInstance();
	apiUtils = new APIUtils();

	eventWriteComponentRef = React.createRef();

	state = {
		listMode: MODE_LIST,
		message: "",
		eventList: [],
		isShowTerminatedEvent: false,
	}

	componentDidMount() {
		this.emitter.on("changeEventListMode", ({mode, data}) => this.changeEventListMode(mode, data));
		this.emitter.on("fetchEventList", this.fetchEventList);
		WSC.getInstance().socket.on(WSSEvent[WSSEvent.EVENT_ADD], (event) => this.onWSSEvent(event, EXECUTE_TYPE.ADD));
		WSC.getInstance().socket.on(WSSEvent[WSSEvent.EVENT_MOD], (event) => this.onWSSEvent(event, EXECUTE_TYPE.MOD));
		WSC.getInstance().socket.on(WSSEvent[WSSEvent.EVENT_DEL], (event) => this.onWSSEvent(event, EXECUTE_TYPE.DEL));

		this.fetchEventList();
	}

	componentWillUnmount() {
		this.emitter.off("changeEventListMode");
		this.emitter.off("fetchEventList");
		WSC.getInstance().socket.off(WSSEvent[WSSEvent.EVENT_ADD]);
		WSC.getInstance().socket.off(WSSEvent[WSSEvent.EVENT_MOD]);
		WSC.getInstance().socket.off(WSSEvent[WSSEvent.EVENT_DEL]);
	}

	onWSSEvent = (event, type) => {
		if(!this.props.sessionStore.userPermission["R"].includes(event.organizationEventCategory.eventCategory.service.code)) return ;

		AlertMessage.eventResponseMessage(event, type);
		const result = HandlingSocketData.update(type, this.state.eventList, event, "id");
		this.setState({
			eventList: result
		}, () => {
			this.emitter.emit('removeMarker', { markerType: 'eventCircle' });
			if(type === EXECUTE_TYPE.DEL) {
				this.emitter.emit("onChangeMarkerData", {key: "eventList", value: this.state.eventList});
			} else {
				this.setChangeDataToMap(event);
			}
		});
	}

	setChangeDataToMap = (event: IEvent) => {
		this.emitter.emit("onChangeMarkerData", {key: "eventList", value: this.state.eventList});
		setTimeout(() => {
			this.emitter.emit("moveTo", { lat: event.latitude, lng: event.longitude });
		}, 500);
	}


	// !!! 이벤트 목록 호출
	fetchEventList = () => {
		this.apiUtils.fetchApi("/v1/me/organization/event", "get", {ob: "id", od: "DESC"}).then(r => {
			this.setState({eventList: r.rows});

			this.emitter.emit("onChangeMarkerData", {key: "eventList", value: this.state.eventList});
			if(this.state.eventList.length < 1) return ;
			let idx = -1;
			this.state.eventList.map((i, index: number) => {
				const parsingData = i.raw ? SocketParser.parse(SERVICE[i?.organizationEventCategory?.eventCategory?.service?.code], i.raw) : "";
				const progressCode = i.raw ? parsingData.header.PROGRESS_CODE : "";
				if(!(progressCode != EVENT_PROGRESS_CODE.RELEASE.code & (progressCode == EVENT_PROGRESS_CODE.TERMINATED.code || i.status == EVENT_STATUS.TERMINATED.code))) {
					if(idx < 0 ) idx = index;
				}
			})

			if(idx > -1) {
				this.emitter.emit('moveTo', { lat: this.state.eventList[idx]?.latitude, lng: this.state.eventList[idx]?.longitude });
			}
		})
	}

	changeEventListMode = (mode, data) => {
		this.setState({
			listMode: mode
		}, () => {
			if(data) {
				this.eventWriteComponentRef.current?.addCapturedImage(data);
			}
			if(this.state.listMode === MODE_WRITE) {
				this.emitter.emit("headerButtonHandler", {key: "cctvBtn", value: true});
			}
		});
	}

	renderItem = () =>
			this.state.eventList.map((item) =>
					<EventItemComponent
						key={item.uuid}
						data={item}
						fetchData={this.fetchEventList}
						isShowTerminatedEvent={this.state.isShowTerminatedEvent}/>
			)

	renderEmptyItem = () => {
		return (
				<div style={{fontWeight: 700, color: "#FFFFFF", opacity: 0.5, fontSize: 14, height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
					<img src={require("../../icons/siren_transparent.png")} style={{marginBottom: 10}}/>
					<div style={{marginBottom: 5}}>이벤트 발생 내역이</div>
					<div>없습니다</div>
				</div>
		)
	}

	setMessageBox = (message) => this.setState({message});

	refresh = () => {
		this.fetchEventList();
		this.emitter.emit("layer_fetchServiceList");
	}

	addEventBtnHandler = () => {
		this.changeEventListMode(this.state.listMode === MODE_LIST ? MODE_WRITE : MODE_LIST);
		this.state.listMode === MODE_LIST && this.emitter.emit('headerButtonHandler', { key: 'savedImgBtn', value: true});
		this.emitter.emit("removeMarker", {markerType: "search"});
	}

	render() {
		return (
				<div className={"event-panel-container"} style={{position: "relative"}}>
					<div className={"header"}>
						<div>
							<span className={"title"}>Event</span>
							<REFRESH className={"cursor"} onClick={this.refresh}/>
						</div>
						<div style={{display: "flex", flexDirection: "row", alignItems: "center", color: "#AAAAAA", fontSize: 14}}>
								상황종료 :
								<Switch value={this.state.isShowTerminatedEvent} color="default" sx={{marginRight: 1}} onClick={(e) => {this.setState({
									isShowTerminatedEvent: e.target?.checked
								})}}/>
							{/*<Switch value={false} onClick={(e) => this.onUpdate(i, "active", e.target?.checked === true ? 1 : 0)}/>*/}
							{this.state.listMode === MODE_LIST ?
								<SIREN_WHITE className={"cursor"} onClick={this.addEventBtnHandler}/> : <SIREN_RED  className={"cursor"} onClick={this.addEventBtnHandler}/>
							}
						</div>
					</div>
					{
						this.state.listMode === MODE_LIST ?
								<div className={"event-list-container"}>
									<MessageBox message={this.state.message} setMessageBox={this.setMessageBox} />

									{/* 이벤트 목록 */}
									<div className={"item-list"}>
										{this.state.eventList.length === 0 && this.renderEmptyItem()}
										{this.renderItem()}
									</div>

									<div style={{width: "100%", height: 1, backgroundColor: "#323232", margin: "35px 0"}}></div>
								</div> :
								/* 이벤트 작성 */
								<EventWriteComponent ref={this.eventWriteComponentRef} setMessageBox={this.setMessageBox} changeEventListMode={this.changeEventListMode} fetchEventList={this.fetchEventList} />
					}
					{this.apiUtils.renderLoading()}
				</div>
		);
	}
}
