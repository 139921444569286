import React, {ChangeEvent} from 'react';
import SettingBaseModal from './SettingBaseModal';
import APIUtils from '../../utils/APIUtils';
import {ReactComponent as USER_S} from '../../icons/user.svg';
import {ReactComponent as USER_W} from '../../icons/user-w.svg';
import {ReactComponent as USER_B} from '../../icons/user-b.svg';
import {IUser, USER} from 'anuro-platform-core';
import {FaCrown} from "react-icons/fa";
import AlertMessage from "../utilities/AlertMessage";
import jwt_decode from "jwt-decode";
import {StorageUtils} from "../../utils/StorageUtils";
import {runInAction} from "mobx";
import {api_tta} from "../../api/api_tta";
import {inject} from "mobx-react";
import SessionStore from "../../stores/SessionStore";

type Props = {
  accountProfile: IUser;
  title: string;
  onClose: Function;
  sessionStore: SessionStore;
  fetchUserInformation: Function;
};

type States = {
  q: string;
  isTyping: boolean;
  userList: IUser[];
  selectedUser: number;
};

@inject("sessionStore")
export default class TransferAccountModal extends React.Component<Props, States> {
  apiUtils = new APIUtils();

  state = {
    q: "",
    isTyping: false,
    userList: [],
    selectedUser: -1,
  };
  endTyping: NodeJS.Timer;

  componentDidMount() {
    this.fetchUserList();
  }

  // !!! 사용자 목록 호출
  fetchUserList = () => {
    this.apiUtils.fetchApi('/v1/me/organization/user', 'get', { ob: 'username', od: 'ASC', limit: 100, q: this.state.q}).then((r) => {
      this.setState({ userList: r.rows, isTyping: false, selectedUser: -1 });
    });
  };

  // !!! 사용자 검색 폼 렌더링
  renderSearchComponent = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          borderRadius: 4,
          padding: '7px 11px',
          margin: '10px 0',
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.35)',
          backgroundColor: '#3F4452',
        }}>
        <USER_S />
        <input
          placeholder='검색(사용자명, 이름, 부서)'
          onChange={(e: ChangeEvent<HTMLInputElement>)  => {
            clearTimeout(this.endTyping);
            this.setState({
              q: e.target.value,
              isTyping: true,
            }, () => {
              if (this.state.isTyping) {
                this.endTyping = setTimeout(() => this.fetchUserList(), 300);
              }
            });
          }}
          style={{
            width: '100%',
            margin: '0 10px',
            color: '#FFFFFF',
            backgroundColor: '#3F4452',
          }}
        />
      </div>
    );
  };

  // !!! 사용자 목록 호출
  renderUserList = () => {
    return this.state.userList.map((i: IUser, idx: number) => {
      const isOwner = i.uuid === this.props.accountProfile.uuid;
      return (
        <div
          key={i.uuid}
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: 'calc(100% - 6px)',
            padding: '11px 15px',
            borderRadius: 10,
            margin: 2,
            cursor: isOwner ? "default" : "pointer",
            boxShadow: '0px 1px 4px rgb(0 0 0 / 35%)',
            marginBottom: this.state.userList.length - 1 === idx ? 0 : 10,
            color: isOwner ? "#666666" : this.state.selectedUser === idx ? "#FFFFFF" : "#333333",
            backgroundColor: isOwner ? "#DDDDDD" : this.state.selectedUser === idx ? "#1B2649" : "#FFFFFF"
          }}
          onClick={() => {
            if(isOwner) return ;
            this.setState({selectedUser: idx})
          }}
        >
          <div style={{ width: 36, height: 36, display: "flex", alignItems: "center", justifyContent: "center", marginRight: 15 }}>
            {i.profile?.imageUrl ? <img src={i.profile?.imageUrl || ''} style={{ maxWidth: 36, maxHeight: 36 }}/> : this.state.selectedUser === idx ? <USER_W /> : <USER_B />}
          </div>
          <div>
            <div style={{ fontWeight: 'bold', display: "flex", alignItems: "center" }}>{i.username} {i.type === USER.TYPE.ACCOUNT_HOLDER && <FaCrown style={{marginLeft: 5}} color={"#03A8A8"}/>}</div>
            <div style={{ fontSize: 14, marginTop: 3 }}>{(i.groups && i.groups?.length > 0) ? i.groups[0].name : "그룹 미지정"}</div>
          </div>
        </div>
      );
    });
  };

  // !!! 사용자 계정 이전
  patchTransferAccount = () => {
    if(this.state.selectedUser === -1) return AlertMessage.alertMessage("error", "이전할 사용자를 선택해주세요");
    const selectedUser: IUser = this.state.userList[this.state.selectedUser];
    this.apiUtils.fetchApi("/v1/me/mgmt-transfer", "patch", {uuid: selectedUser.uuid}).then(r => {
      AlertMessage.alertMessage("success", "계정 이전이 되었습니다.");

      StorageUtils.removeAuthorization();
      api_tta.deleteHeader('Authorization');

      const payload: IUser = jwt_decode(r.token);

      // Save JWT to LocalStorage
      StorageUtils.setAuthorization(r.token, true);

      // 저장된 JWT 체크
      if (r.token === StorageUtils.getAuthorization()) {
        runInAction(() => {
          this.props.sessionStore.setUser({...payload})
        });
        api_tta.setHeader('Authorization', r.token);
      }
      this.props.fetchUserInformation();
      this.props.onClose();
    });
  }

  render() {
    const { onClose } = this.props;
    return (
      <SettingBaseModal open={true} onClose={() => onClose(false)} title={this.props.title} width={460} height={500} withBackground>
        <div className={'transfer-account-modal'}>
          <div>{this.renderSearchComponent()}</div>
          <div
            style={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              margin: '10px 0 5px 0',
              overflowY: "auto",
              padding: "2px 0",
            }}>
            {this.renderUserList()}
          </div>
          <div style={{ fontSize: 12, color: '#AAAAAA', textAlign: 'right', margin: "10px 0" }}>최대 100명의 사용자를 보여줍니다</div>
          <div className={'btn-wrapper'}>
            <div className={'btn2 cursor'} onClick={() => onClose(false)}>
              취소
            </div>
            <div className={'btn2 close cursor'} onClick={() => this.patchTransferAccount()}>
              계정 이전
            </div>
          </div>
        </div>
        {this.apiUtils.renderLoading()}
      </SettingBaseModal>
    );
  }
}
