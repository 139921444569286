import React from 'react';
import AddServiceModal from './AddServiceModal';
import {SETTING_PANEL, SETTING_TAB} from './SettingModal';
import APIUtils from '../../utils/APIUtils';
import {Switch, Tooltip} from '@mui/material';
import {PANEL_MODE} from './ServiceListComponent';
import AlertMessage from '../utilities/AlertMessage';
import EventEmitter from "../../utils/EventEmitter";
import {ReactComponent as EDIT} from "../../icons/edit.svg";
import {WSC} from "../../service/WSC";
import {WSSEvent} from "anuro-platform-core";
import {EXECUTE_TYPE} from "../utilities/HandlingSocketData";

export default class ServiceSettingPanelComponent extends React.Component {
  emitter = EventEmitter.getInstance();
  apiUtils = new APIUtils();

  state = {
    mode: PANEL_MODE.normal,
    addModalOpen: false,
    list: [],
    serviceIcon: this.props.serviceData?.organizationService?.iconUrl || '',
    isActive: this.props.serviceData?.organizationService?.active,
    name: this.props.serviceData?.organizationService?.name,
    code: this.props.serviceData?.organizationService?.code,
    isNameEditable: false,
    isCodeEditable: false,
  };

  componentDidMount() {
    WSC.getInstance().socket.on(WSSEvent[WSSEvent.ORGANIZATION_SERVICE_ADD], (event) => this.onWSSEvent(event, EXECUTE_TYPE.ADD));
    WSC.getInstance().socket.on(WSSEvent[WSSEvent.ORGANIZATION_SERVICE_MOD], (event) => this.onWSSEvent(event, EXECUTE_TYPE.MOD));
    WSC.getInstance().socket.on(WSSEvent[WSSEvent.ORGANIZATION_SERVICE_DEL], (event) => this.onWSSEvent(event, EXECUTE_TYPE.DEL));

    this.fetchCategoryList();
  }

  componentWillUnmount() {
    WSC.getInstance().socket.off(WSSEvent[WSSEvent.ORGANIZATION_SERVICE_ADD]);
    WSC.getInstance().socket.off(WSSEvent[WSSEvent.ORGANIZATION_SERVICE_MOD]);
    WSC.getInstance().socket.off(WSSEvent[WSSEvent.ORGANIZATION_SERVICE_DEL]);
  }

  onWSSEvent = () => {

  }


  // !!! 카테고리 목록 출력
  fetchCategoryList = () => {
    const serviceId = this.props.serviceData.organizationService.id;
    this.apiUtils.fetchApi(`/v1/me/organization/service/${serviceId}/event-category`, 'get').then((r) => {
      this.setState({ list: r });
    });
  };

  // !!! 카테고리 활성/비활성 업데이트
  updateEventCategoryActivation = (item) => {
    if (!this.state.isActive) return null;
    this.apiUtils.fetchApi(`/v1/me/organization/service/${item?.organizationEventCategory?.organizationServiceId}/event-category/${item?.organizationEventCategory?.id}`,
      'patch',
      {"active": item?.organizationEventCategory?.active === 0 ? 1 : 0}).then((r) => {
      this.fetchCategoryList();
    });
  };

  // !!! 서비스 활성/비활성 업데이트
  updateServiceActivation = (item) => {
    const active = !this.state.isActive;
    let formData = new FormData();
    formData.append("active", active === true ? 1 : 0);
    this.apiUtils.fetchApi(`/v1/me/organization/service/${item.organizationService.id}`, 'patch', { formData }).then((r) => {
      this.setState({ isActive: active, mode: PANEL_MODE.normal });
    });
  };

  // !!! 아이콘 업데이트
  updateEventCategoryIcon = (item, value) => {
    let formData = new FormData();
    formData.append("icon", value);
    this.apiUtils.fetchApi(`/v1/me/organization/service/${item.organizationService.id}`, 'patch', { formData }).then((r) => {
      this.setState({
        serviceIcon: URL.createObjectURL(value),
        mode: PANEL_MODE.normal
      }, () => {
        this.emitter.emit("fetchEventList");
        this.emitter.emit("layer_fetchServiceList");
      });
    });
  };

  // !!! 카테고리 삭제
  onDeleteCategory = async (item) => {
    const result = await AlertMessage.confirmMessage(`'${item.name}' 항목을(를) 삭제하시겠습니까?`);
    if (!result) return null;
    this.apiUtils.fetchApi(`/v1/me/organization/service/${item?.organizationEventCategory?.organizationServiceId}/event-category/${item?.organizationEventCategory?.id}`, 'delete').then((r) => {
      this.fetchCategoryList();
      this.setState({ mode: PANEL_MODE.normal });
    });
  };

  goBack = () => !this.state.isNameEditable && this.props.handleData(SETTING_PANEL.SERVICE_MANAGEMENT_LIST, SETTING_TAB.SERVICE);

  // !!! 관리 버튼을 눌렀을 때
  onManage = () => {
    if (!this.state.isActive) return null;
    this.setState({ mode: this.state.mode === PANEL_MODE.normal ? PANEL_MODE.manage : PANEL_MODE.normal });
  };

  handleModal = (addModalOpen) => this.setState({ addModalOpen, mode: PANEL_MODE.normal });

  onChange = (key, value) => this.setState({[key]: value});

  getByteLengthOfString = (s,b,i,c) => {
    for(b=i=0;c=s.charCodeAt(i++);b+=c>>11?2:c>>7?2:1);
    return b < 10 ? 10 : b;
  };

  onKeyDown = (e, type) => {
    if (e.key === "Enter") {
      if(type === "name") {
        this.apiUtils.fetchApi(`/v1/me/organization/service/${this.props.serviceData.organizationService.id}`, "patch", {
          name: this.state.name
        }).then(r => {
          AlertMessage.alertMessage("success", `서비스명이 ${this.state.name}로 바뀌었습니다`);
          this.setState({
            isNameEditable: false,
          })
          this.emitter.emit("fetchEventList");
          this.emitter.emit("layer_fetchServiceList");
        })
      }
    }
  };

  render() {
    const { serviceData } = this.props;
    return (
        <div className={'right setting'}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{display: "flex", alignItems: "center", flexDirection: "row"}}>
              <div className={'cursor'} onClick={this.goBack} >
                <span style={{ marginRight: 10 }}>&#60;</span>
                  {
                    this.state.isNameEditable ?
                      <input value={this.state.name} onKeyDown={e => this.onKeyDown(e, "name")} onChange={e => {this.onChange("name", e.target.value)}} style={{width: 300, fontSize: 16, backgroundColor: "#FFF", border: "1px solid #CCC", borderRadius: 4, padding: 10}}/>
                      :
                      <div style={{display: "inline-flex", alignItems: "center"}}>
                        <span style={{marginRight: 10, maxWidth: 290, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{this.state.name}</span>
                        <EDIT className={"cursor"} onClick={(e) => {
                          e.stopPropagation();
                          if(this.state.isNameEditable) {
                            this.onKeyDown({key: "Enter"}, "name");
                          } else {
                            this.onChange("isNameEditable", !this.state.isNameEditable);
                          }
                        }}/>
                      </div>
                  }
              </div>
            </div>
            <div>
              <Switch checked={this.state.isActive} onClick={() => this.updateServiceActivation(this.props.serviceData)} />
            </div>
          </div>
          <input type={'file'} id={'file'} style={{ display: 'none' }} onChange={(e) => this.updateEventCategoryIcon(this.props.serviceData, e.target.files[0])} />
          {/*{this.state.list.length > 0 && (*/}
          <div className={'service-icon-container'}>
            <label htmlFor='file'>
              <Tooltip title={'아이콘을 눌러 변경하세요'} placement={'top'} arrow disableHoverListener={this.state.mode !== PANEL_MODE.normal}>
                {this.state.serviceIcon ? (
                  <img className={'cursor hover-opacity hover-shadow'} src={this.state.serviceIcon} />
                ) : (
                  <div className={'no-img cursor hover-opacity hover-transform'}>
                    <span>+</span>
                  </div>
                )}
              </Tooltip>
            </label>
          </div>
        {/*)}*/}
        {/*{this.state.list.length > 0 && (*/}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>이벤트</div>
            <div onClick={this.onManage} className={`${this.state.isActive ? 'txt-btn' : 'inactive'}`}>
              관리
            </div>
          </div>
        {/*)}*/}
        <div className={'item-container'} style={{ height: '50%', position: "relative" }}>
          {this.state.list.map((i, idx) => {
            return (
              <div className={'item'} key={idx}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {i.name} <span className={'small-txt'}>{i?.organizationEventCategory?.active ? 'ON' : 'OFF'}</span>
                </div>
                {this.state.mode === PANEL_MODE.normal ? (
                  <Switch disabled={!this.state.isActive} checked={i?.organizationEventCategory?.active === 1} onClick={() => this.updateEventCategoryActivation(i)} />
                ) : (
                  <div
                    className={'red-txt-btn'}
                    style={{ margin: 9, width: 20 }}
                    onClick={() => {
                      this.onDeleteCategory(i);
                    }}>
                    &times;
                  </div>
                )}
              </div>
            );
          })}
          {this.apiUtils.renderLoading()}
        </div>

        <button className={`btn ${!this.state.isActive && 'inactive'}`} disabled={!this.state.isActive} onClick={() => this.handleModal(true)}>
          + 카테고리 추가
        </button>
        {this.state.addModalOpen && <AddServiceModal title='카테고리 추가' type={SETTING_PANEL.SERVICE_MANAGEMENT_SETTING} fetchData={this.fetchCategoryList} serviceData={this.props.serviceData} onClose={this.handleModal} />}
      </div>
    );
  }
}
