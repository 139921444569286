import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormInput } from '../../utils/InputUtils';
import '../../css/authentication/signin.css';

import { IconButton, OutlinedInput, TextField, InputLabel, FormControl, Checkbox, FormControlLabel, Button, Container } from '@material-ui/core';
import Grid from '@mui/material/Grid';

import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import {
  // FacebookLoginButton,
  GoogleLoginButton,
  KakaoLoginButton,
  NaverLoginButton,
  // AppleLoginButton,
} from '../../components/utilities/SocialLoginButtons';
import InputAdornment from '@material-ui/core/InputAdornment';
import { inject, observer } from 'mobx-react';
import { WSC } from '../../service/WSC';

const SignIn = (props) => {
  const [loading, setLoading] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [isLockedSubmit, setIsLockedSubmit] = useState(false);

  const username = useFormInput('', {});
  const password = useFormInput('', { password: true });
  const history = useHistory();

  const pwdShowHandler = () => {
    setShowPwd(!showPwd);
  };

  const onSignIn = () => {
    const release = (state, callback) => {
      setLoading(() => false);
      setIsLockedSubmit(false);
      typeof callback === 'function' && callback();
    };

    if (!username) return release({ error: '계정을 입력해주세요.' });
    if (!password) return release('비밀번호를 입력해주세요');

    let bol = props.sessionStore
      .signIn(username.value, password.value, true)
      .then(() => {
        WSC.getInstance().start();
        history.push('/gis');
      })
      .catch((e) => {
        switch (e) {
          case 'TOKEN_NOT_EXISTS': {
            release({ error: '로그인 실패(T404)' });
            break;
          }
          default: {
            release({ error: '로그인 실패' });
            break;
          }
        }
        alert('아이디와 비밀번호를 확인해주세요');
        history.push('/admin');
      });
  };

  return (
    <Container maxWidth='md'>
      <Grid container style={{ height: '100vh', padding: '0 1.5rem' }} alignItems='center' justifyContent='center' columnSpacing={{ xs: 0, md: 6 }}>
        <Grid item md={6}>
          <div className='native-signin'>
            <p className='header'>일반 로그인</p>
            <TextField type={'text'} label={'Enter Username'} {...username} variant={'outlined'} className='signin-input' onKeyDown={(e) => {if(e.key === "Enter") onSignIn()}}
            />
            <FormControl variant='outlined' className='signin-input'>
              <InputLabel htmlFor='input-password'>Password</InputLabel>
              <OutlinedInput
                id='input-password'
                type={showPwd ? 'text' : 'password'}
                label={'Enter password'}
                {...password}
                onKeyDown={(e) => {if(e.key === "Enter") onSignIn()}}
                endAdornment={
                  <InputAdornment position={'end'}>
                    <IconButton onClick={pwdShowHandler} edge={'end'}>
                      {showPwd ? <MdVisibilityOff /> : <MdVisibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <div className='save-credentials'>
              <FormControlLabel value='end' control={<Checkbox color='primary' />} label='아이디 저장' labelPlacement='end' />
              <FormControlLabel value='end' control={<Checkbox color='primary' />} label='자동 로그인' labelPlacement='end' />
            </div>
            <hr />
            <div className='credentials-restore'>
              <Button size='small'>
                <Link to='/find-id'>아이디 찾기</Link>
              </Button>
              <Button size='small'>
                <Link to='/find-password'>비밀번호 찾기</Link>
              </Button>
              <Button size='small'>
                <Link to='/sign-up'>회원가입</Link>
              </Button>
            </div>
            <hr />
            <Button disabled={isLockedSubmit} onClick={onSignIn} className='signin-btn' variant='outlined'>
              로그인
            </Button>
          </div>
        </Grid>
        <Grid item md={6}>
          <div className='foreign-signin'>
            <p className='header'>간편 로그인</p>
            <div className='social-btn-group'>
              <KakaoLoginButton />
              <NaverLoginButton />
              <GoogleLoginButton />
            </div>
            {/* <FacebookLoginButton />
          <AppleLoginButton /> */}
          </div>
        </Grid>
      </Grid>

      {/* <section className="signin-wrapper"></section> */}
    </Container>
  );
};

export default inject('sessionStore')(observer(SignIn));
