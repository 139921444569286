import React from 'react';

import marker_w from '../../icons/marker/marker-w.png';
import marker_g from '../../icons/marker/marker-g.png';
import EventEmitter from '../../utils/EventEmitter';
import {EVENT_DEFAULT_ICON, EVENT_PROGRESS_CODE, EVENT_STATUS} from '../../constants/Event';
import moment from 'moment';
import EventDetailModal from '../event/EventDetailModal';
import EventEditModal from '../event/EventEditModal';
import ConfirmDialogModal from '../ConfirmDialogModal';
import RegiCompleteModal from '../event/RegiCompleteModal';
import SMSModal from '../event/SMSModal';
import SMSEditModal from '../event/SMSEditModal';
import SMSDetailModal from '../event/SMSDetailModal';
import EventDeleteConfirmModal from '../event/EventDeleteConfirmModal';
import DeleteSms from '../eventManage/modals/DeleteSms';
import {ReactComponent as SIREN2_WHITE} from '../../icons/siren2_white.svg';
import {ReactComponent as SIREN2_RED} from '../../icons/siren2_red.svg';

import {ImCheckboxChecked, ImCheckboxUnchecked} from 'react-icons/im';
import {SERVICE, SocketParser} from 'anuro-platform-core';

export default class EventItemComponent extends React.Component {
  emitter = EventEmitter.getInstance();

  state = {
    result: [],

    // modal 관련 변수
    detailOpen: false,
    deleteOpen: false,
    eventEditOpen: false,
    eventEditCompleteOpen: false,
    eventQuiteCompleteOpen: false,
    deleteConfirmOpen: false,
    eventQuiteOpen: false,
    completeModalOpen: false,
  };

  componentDidMount() {
    this.emitter.on('handleEventDetailModal', ({ uuid, value }) => {
      if (uuid === this.props.data.uuid) {
        this.handleModal('detailOpen', value);
      }
    });
  }

  componentWillUnmount() {
    this.emitter.off('handleEventDetailModal');
  }

  handleModal = (key, value, data, isEventOccur) => {
    !isEventOccur && this.setState({ [key]: value });
    data &&
      data.status !== EVENT_STATUS.TERMINATED.code &&
      this.emitter.emit('moveTo', {
        lat: data?.latitude,
        lng: data?.longitude,
        withMarker: false,
      });
  };

  handleEvent = () => {
    this.emitter.emit('headerButtonHandler', { key: 'cctvBtn', value: true });
  };

  render() {
    const { data, fetchData } = this.props;

    const parsingData = data.raw ? SocketParser.parse(SERVICE[data?.organizationEventCategory?.eventCategory?.service?.code], data.raw) : '';
    const progressCode = data.raw ? parsingData.header.PROGRESS_CODE : '';

    const isEventOccur = data.status === EVENT_STATUS.STANDBY.code;
    const isEventDone = data.raw && (progressCode == EVENT_PROGRESS_CODE.RELEASE.code || progressCode == EVENT_PROGRESS_CODE.TERMINATED.code) ? true : data.status === EVENT_STATUS.TERMINATED.code ? true : false;

    // raw 있을 때
    if (!this.props.isShowTerminatedEvent) {
      if (data.raw && progressCode == EVENT_PROGRESS_CODE.TERMINATED.code) {
        return null;
      } else if (data.raw && progressCode == EVENT_PROGRESS_CODE.RELEASE.code) {
      } else {
        if (isEventDone) {
          return null;
        }
      }
    }
    return (
      <>
        <div className={`${!isEventDone && isEventOccur ? 'item red' : 'item'} hover-transform-3`} onClick={() => this.handleModal('detailOpen', true, data, isEventOccur)}>
          <div className={'basic'}>
            <img className={'img'} src={data.organizationEventCategory?.organizationService?.iconUrl || EVENT_DEFAULT_ICON[data?.organizationEventCategory?.eventCategory?.service?.code]} />
            {isEventDone && <span className={'img'} style={{ position: 'absolute', backgroundColor: '#99999990' }}></span>}
            <div style={{ width: 'calc(100% - 60px)' }}>
              <div className={'place'}>
                <div>
                  <div className={'place-name'} style={{ color: isEventDone ? '#999999' : '#FFFFFF' }}>
                    {data.organizationEventCategory?.organizationService?.name}
                  </div>
                  <div className={'status'}>
                    {data.reported === null ? !isEventDone && isEventOccur ? <SIREN2_RED /> : <SIREN2_WHITE /> : data.reported ? <ImCheckboxChecked color={'green'} /> : <ImCheckboxUnchecked color={'gray'} />}
                    <span
                      style={{
                        color: !isEventDone && isEventOccur ? '#FF0000' : isEventDone ? '#999999' : '#FFFFFF',
                        marginLeft: 5,
                      }}>
                      {data.raw ? (progressCode == EVENT_PROGRESS_CODE.RELEASE.code ? '상황해제' : progressCode == EVENT_PROGRESS_CODE.TERMINATED.code ? '상황종료' : EVENT_STATUS[data.status].name) : isEventDone ? '상황종료' : EVENT_STATUS[data.status].name}
                    </span>
                  </div>
                </div>
                <div className={'place-location'}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: 'calc(100% - 110px)',
                    }}>
                    <img src={!isEventDone && isEventOccur ? marker_w : marker_g} style={{ marginRight: 5 }} />
                    <div
                      className={'address'}
                      style={{ color: isEventDone ? '#999999' : '#FFFFFF' }}
                      onClick={(e) => {
                        const parsingData = data.raw ? SocketParser.parse(SERVICE[data?.organizationEventCategory?.eventCategory?.service?.code], data.raw) : '';
                        const progressCode = data.raw ? parsingData.header.PROGRESS_CODE : '';

                        if (progressCode != EVENT_PROGRESS_CODE.RELEASE.code && (progressCode == EVENT_PROGRESS_CODE.TERMINATED.code || data.status === EVENT_STATUS.TERMINATED.code)) return;
                        e.stopPropagation();
                        this.emitter.emit('moveTo', { lat: data.latitude, lng: data.longitude, withMarker: false });
                      }}>
                      {data.addressStreet}
                    </div>
                  </div>
                  <div>{moment(data.issuedAt).format('MM월DD일 HH:mm:ss')}</div>
                </div>
              </div>
            </div>
          </div>

          {!isEventDone && isEventOccur && (
            <div style={{ textAlign: 'right' }}>
              <button style={{ marginRight: 0, backgroundColor: '#FF0000', color: '#FFFFFF', marginTop: 16 }} onClick={() => {
                this.handleModal('detailOpen', true, data);
                this.handleEvent(data);
              }}>
                상황 처리
              </button>
            </div>
          )}
        </div>

        {/* !!! 이벤트 관련 모달들 */}
        {this.state.detailOpen && (
          <EventDetailModal
            detailOpen={true}
            isDetailOpen={(e) => this.handleModal('detailOpen', e)}
            isDeleteOpen={(e) => this.handleModal('deleteOpen', e)}
            rowInfo={data}
            setEventQuiteOpen={(e) => this.handleModal('eventQuiteOpen', e)}
            setEditOpen={(e) => this.handleModal('eventEditOpen', e)}
            setDeleteData={(e) => this.handleModal('deleteOpen', e)}
          />
        )}
        {this.state.eventEditOpen && <EventEditModal open={true} setOpen={(e) => this.handleModal('eventEditOpen', e)} setEventEditCompleteOpen={(e) => this.handleModal('eventEditCompleteOpen', e)} rowInfo={data} fetchData={fetchData} />}
        {this.state.eventQuiteOpen && (
          <ConfirmDialogModal
            confirmDialogOpen={true}
            isConfirmDialogOpen={(e) => this.handleModal('eventQuiteOpen', e)}
            setPrevModal={(e) => this.handleModal('detailOpen', e)}
            setNextModal={(e) => this.handleModal('eventQuiteCompleteOpen', e)}
            title={'이벤트 상황종료'}
            detail={'현재 처리중인 이벤트를 종료하시겠습니까?'}
          />
        )}
        {/*<ConfirmDialogModal confirmDialogOpen={smsDeleteOpen} isConfirmDialogOpen={setSmsDeleteOpen} setPrevModal={setSmsOpen} setNextModal={setDeleteConfirmOpen} title={'SMS 삭제'} detail={'삭제한 후에는 되돌릴 수 없습니다. \n 선택한 SMS를 삭제하시겠습니까?'} />*/}
        {this.state.completeModalOpen && <RegiCompleteModal completeOpen={true} setCompleteOpen={(e) => this.handleModal('completeModalOpen', e)} setSmsOpen={(e) => this.handleModal('smsOpen', e)} />}
        {this.state.smsOpen && (
          <SMSModal
            smsOpen={true}
            setSmsOpen={(e) => this.handleModal('smsOpen', e)}
            smsDeleteOpen={(e) => this.handleModal('smsDeleteOpen', e)}
            setSmsCompleteOpen={(e) => this.handleModal('smsCompleteOpen', e)}
            setSmsEditOpen={(e) => this.handleModal('smsEditOpen', e)}
            setSmsDeleteOpen={(e) => this.handleModal('smsDeleteOpen', e)}
            setSmsDetailOpen={(e) => this.handleModal('smsDeleteOpen', e)}
            rowInfo={data}
          />
        )}
        {/*<CompleteAndCloseModal title={'SMS 전송 성공'} detail={`전송된 메세지는 전송목록에서 \n 확인할 수 있습니다.`} open={smsCompleteOpen} setOpen={setSmsCompleteOpen} />*/}
        {/*<CompleteAndCloseModal title={'수정 완료'} detail={`이벤트 수정이 성공적으로 완료되었습니다`} open={eventEditCompleteOpen} setOpen={setEventEditCompleteOpen} />*/}
        {/*{<CompleteAndCloseModal title={'종료 완료'} detail={`정상적으로 처리되었습니다`} open={eventQuiteCompleteOpen} setOpen={setEventQuiteCompleteOpen} />}*/}
        {/*{<CompleteAndCloseModal title={'삭제 완료'} detail={`정상적으로 처리되었습니다`} open={deleteConfirmOpen} setOpen={setDeleteConfirmOpen} />}*/}
        {this.state.smsEditOpen && <SMSEditModal smsEditOpen={true} setSmsEditOpen={(e) => this.handleModal('smsEditOpen', e)} setSmsCompleteOpen={(e) => this.handleModal('smsCompleteOpen', e)} />}
        {/*<SMSDeletedModal smsDeleteOpen={smsDeleteOpen} setSmsDeleteOpen={setSmsDeleteOpen} /> */}
        {this.state.smsDetailOpen && <SMSDetailModal smsDetailOpen={true} setSmsDetailOpen={(e) => this.handleModal('smsDetailOpen', e)} />}
        {this.state.deleteOpen && (
          <ConfirmDialogModal
            confirmDialogOpen={true}
            isConfirmDialogOpen={(e) => this.handleModal('deleteOpen', e)}
            setNextModal={(e) => this.handleModal('deleteConfirmOpen', e)}
            title={'이벤트 삭제'}
            detail={`한 번 삭제된 이벤트는 되돌릴 수 없습니다. 선택된 이벤트를 삭제하시겠습니까?`}
            deleteData={[data]}
            fetchData={fetchData}
          />
        )}
        {this.state.deleteConfirmOpen && <EventDeleteConfirmModal deleteConfirmOpen={true} setDeleteConfirmOpen={(e) => this.handleModal('deleteConfirmOpen', e)} isDeleteOpen={(e) => this.handleModal('deleteOpen', e)} />}
        {this.state.smsDeleteOpen && <DeleteSms open={true} setOpen={(e) => this.handleModal('smsDeleteOpen', e)} setPrevModal={(e) => this.handleModal('smsOpen', e)} />}
      </>
    );
  }
}
