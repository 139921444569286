import React from 'react';
import ReactModal from 'react-modal-resizable-draggable';
import APIUtils from '../../utils/APIUtils';
import {SERVICE, SocketParser} from 'anuro-platform-core';
import {EVENT_PROPERTY} from '../../constants/Event';

export default class RawModal extends React.Component<any, any> {
  apiUtils = new APIUtils();

  renderRawData = (is112) => {
    const { rowInfo } = this.props;
    let parsingData = {};
    if (is112) {
      parsingData = {
        body: { ...rowInfo.ES_112_MTMDA_RECEPT },
      };
    } else {
      parsingData = rowInfo.raw ? SocketParser.parse(SERVICE[rowInfo?.organizationEventCategory?.eventCategory?.service?.code], rowInfo.raw) : '';
    }
    return (
      <>
        {/* !!! HEADER */}
        {parsingData?.header && (
          <tr>
            <td colSpan={4} className={'separate-field'}>
              HEADER
            </td>
          </tr>
        )}
        {parsingData?.header &&
          Object.keys(parsingData.header).map((i, idx) => {
            return (
              <tr key={idx}>
                <td className={'ta-center'}>{idx + 1}</td>
                <td>{EVENT_PROPERTY.HEADER[i]?.name}</td>
                <td className={'ta-center'}>{EVENT_PROPERTY.HEADER[i]?.length}</td>
                <td>{parsingData.header[i]}</td>
              </tr>
            );
          })}

        {/* !!! BODY */}
        {parsingData?.body && (
          <tr>
            <td colSpan={4} className={'separate-field'}>
              PAYLOAD
            </td>
          </tr>
        )}
        {parsingData?.body &&
          Object.keys(parsingData.body).map((i, idx) => {
            if (!EVENT_PROPERTY.BODY[rowInfo.organizationEventCategory?.eventCategory?.service?.code][i]?.name) return;
            return (
              <tr key={idx}>
                <td className={'ta-center'}>{idx + 1}</td>
                <td>{EVENT_PROPERTY.BODY[rowInfo.organizationEventCategory?.eventCategory?.service?.code][i]?.name}</td>
                {!is112 && <td className={'ta-center'}>구분자</td>}
                <td>{parsingData.body[i]}</td>
              </tr>
            );
          })}
      </>
    );
  };

  // !!! 첨부파일 목록
  renderAttachmentData = () => {
    if(!this.props.rowInfo?.ES_112_MTMDA_RECEPT?.ES_112_MTMDA_RECEPT_ATCHS?.length > 0) return null;
    return (
      <table style={{ width: '100%', marginTop: 30 }}>
        <thead>
          <tr>
            {Object.keys(EVENT_PROPERTY.ATTACHMENT.ATT_112).map((i) => {
              return <td key={i}>{i}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={5} className={'separate-field'}>
              첨부파일
            </td>
          </tr>
          {this.props.rowInfo?.ES_112_MTMDA_RECEPT?.ES_112_MTMDA_RECEPT_ATCHS.map((item) => {
            return (
              <tr key={item.SEQ_NO}>
                {Object.keys(EVENT_PROPERTY.ATTACHMENT.ATT_112).map((i, idx) => {
                  return <td key={item.SEQ_NO + "-" + idx}>{item[i]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  render() {
    const is112 = this.props.rowInfo?.ES_112_MTMDA_RECEPT || false;
    return (
      <ReactModal className={`raw-modal`} initWidth={700} initHeight={620} disableResize={true} top={'-10%'} left={'30%'} onRequestClose={() => {}} isOpen={true}>
        <div
          className={'body'}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Escape') this.props.onClose();
          }}>
          <div className={'close'} onClick={this.props.onClose}>
            &times;
          </div>
          <div className={'header'}>전문</div>
          <div className={'table-container'}>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <td width={50}>번호</td>
                  <td width={120}>필드</td>
                  {!is112 && <td width={60}>길이</td>}
                  <td width={`calc(100% - ${is112 ? 170 : 210}px)`}>설명</td>
                </tr>
              </thead>
              <tbody>{this.renderRawData(is112)}</tbody>
            </table>
            {is112 && this.renderAttachmentData(is112)}
          </div>
        </div>
        {this.apiUtils.renderLoading()}
      </ReactModal>
    );
  }
}
