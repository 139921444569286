import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import '../css/myPage/facilityManagement.css';
import FacilityDetailModal from '../components/facilityManage/modals/FacilityDetailModal';
import DeleteFacilityModal from '../components/facilityManage/modals/DeleteFacilityModal';
import RegiFacilityModal from '../components/facilityManage/modals/RegiFacilityModal';
import { ReactComponent as Marker } from '../icons/marker/marker-point.svg';
import AlertMessage from '../components/utilities/AlertMessage';
import HeaderComponent from '../components/HeaderComponent';
import { statusOptions } from '../constants/SelectOptions';
import APIUtils, { fetchApi } from '../utils/APIUtils';
import EventEmitter from '../utils/EventEmitter';
import Container from '../components/Container';
import SelectBox from '../components/SelectBox';
import { loadPlayer } from 'rtsp-relay/browser';
import { useHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import useSelect from '../hooks/useSelect';
import useInput from '../hooks/useInput';
import { api_tta } from '../api/api_tta';
import Table from '../components/Table';
import Input from '../components/Input';
// import Input from '../components/common/Input/Input';
import moment from 'moment';
import DeleteBtn from '../components/Table/DeleteBtn/DeleteBtn';
import RightBtn from '../components/Table/RightBtn/RightBtn';

const emitter = EventEmitter.getInstance();
const CctvStatus = ({ facility }) => {
  const ref = useRef(null);
  const [condition, setCondition] = useState('로딩중...');
  const disabledCondition = condition === '이상';

  useEffect(() => {
    const facilityCCTV = facility.facilityCctv;
    if (!facility.active) return setCondition('비활성');
    if (!ref.current) return;

    loadPlayer({
      url: `${api_tta.getBaseURL().replace('http', 'ws')}/stream/${facilityCCTV.id}?stream=${facilityCCTV.rtspPath}`,
      canvas: ref.current,
      onDisconnect() {
        setCondition('이상');
      },
    }).then((r) => {
      setCondition('정상');
    });
  }, [ref, facility]);

  return (
    <div style={{ color: disabledCondition ? 'red' : 'white' }}>
      <canvas ref={ref} className='cctv-display-none' />
      {condition}
    </div>
  );
};

const CctvMapMarker = ({ facility }) => {
  const history = useHistory();

  const GoToGIS = () => {
    history.push('/gis');
    emitter.emit('moveTo', { lat: facility.latitude, lng: facility.longitude, withMarker: false });
  };

  return <Marker style={{ cursor: 'pointer' }} onClick={GoToGIS} />;
};

const eventColumns = [
  {
    field: 'id',
    headerName: '시설물ID',
    width: 80,
  },
  {
    field: 'organizationFacilityType',
    headerName: '종류',
    width: 80,
    valueFormatter: (params) => {
      return params.value.facilityType?.name;
    },
  },
  {
    field: 'name',
    headerName: '시설물 이름',
    flex: 2,
  },
  {
    field: 'addressStreet',
    headerName: '위치',
    flex: 3,
    valueGetter: (params) => {
      return params.value + ' / ' + params.row.addressStreetDetail;
    },
  },
  {
    field: 'code',
    headerName: '코드',
    flex: 1.2,
    valueFormatter: (params) => {
      if (params.value === null) return '미지정';
      return params.value;
    },
  },
  {
    field: 'active',
    headerName: '활성 상태',
    flex: 1,
    valueFormatter: (params) => {
      if (params.value === 1) return '활성';
      return '비활성';
    },
  },
  {
    field: 'status',
    headerName: '동작 상태',
    flex: 1,
    renderCell: ({ row }) => <CctvStatus facility={row} />,
  },
  {
    field: 'createdAt',
    headerName: '등록일',
    flex: 1,
    valueFormatter: (params) => {
      return moment(params.value).format('YY.MM.DD');
    },
  },
  {
    field: '',
    headerName: '지도에서 보기',
    flex: 1,
    renderCell: ({ row }) => <CctvMapMarker facility={row} />,
  },
];

const apiUtils = new APIUtils();
const PAGE_LIMIT = 6;

const FacilityManage = inject('cctvStore')(
  observer(({ cctvStore }) => {
    const [facilityRegiOpen, setFacilityRegiOpen] = useState(false);
    const [facilityDetailOpen, setFacilityDetailOpen] = useState(false);
    const [facilityDeleteOpen, setFacilityDeleteOpen] = useState(false);

    const [selectedRowData, setSelectedRowData] = useState('');

    const [totalCount, setTotalCount] = useState(0);
    const [rows, setRows] = useState([]);
    const [curPage, setCurPage] = useState(1);

    const [checkedRows, setCheckedRows] = useState([]);

    const searchInput = useInput('');
    const statusSelection = useSelect([]);
    const typeSelection = useSelect([]);

    const fetchedData = rows.find((row) => row.uuid === selectedRowData?.uuid);

    const RegiOpen = () => {
      setFacilityRegiOpen(true);
    };

    const DeleteOpen = () => {
      if (checkedRows.length < 1) return AlertMessage.alertMessage('error', '삭제하실 시설물을 선택해주세요');
      if (checkedRows.length > 1) return AlertMessage.alertMessage('error', '두개 이상의 시설물은 선택하실 수 없습니다');
      setSelectedRowData(checkedRows[0]);
      setFacilityDeleteOpen(true);
    };

    const DetailOpen = (params) => {
      setFacilityDetailOpen(true);
      setSelectedRowData(params.row);
    };

    const facilityTypeOptions = () => {
      const typesArr = [];
      fetchApi(`/v1/me/organization/facility-type`, `get`).then((res) => {
        res.map((e) => {
          return typesArr.push({
            value: e?.organizationFacilityType.id,
            label: e?.name,
          });
        });
      });
      return typesArr;
    };

    const fetchTable = (e = null, p = 1) => {
      setCurPage(p);
      apiUtils
        .fetchApi(`/v1/me/organization/facility`, 'get', {
          q: searchInput.value,
          limit: PAGE_LIMIT,
          offset: p ? (p - 1) * PAGE_LIMIT : 0,
          ob: 'id',
          od: 'DESC',
          active: statusSelection.value,
          organizationFacilityTypeId: typeSelection.value,
        })
        .then((tableData) => {
          setRows(tableData.rows);
          setTotalCount(tableData.count);
        })
        .catch((err) => console.log('[Line:122 err]', err));
    };

    useLayoutEffect(() => {
      fetchTable();
    }, [searchInput.value, statusSelection.value, typeSelection.value]);

    return (
      <Container>
        <HeaderComponent pageTitle={'Facility'} />
        <div className='facility-container'>
          <div className='content'>
            <div className='right'>
              <h3 className={'header'}>시설물 관리</h3>
              <div className='search-title'>시설물 조회</div>
              <div className='search-container'>
                <div className='left'>
                  <SelectBox isClearable onChange={typeSelection.onChange} variant={'primary'} options={facilityTypeOptions()} placeholder={'종류'}></SelectBox>
                  <SelectBox isClearable onChange={statusSelection.onChange} variant={'primary'} options={statusOptions} placeholder={'상태'}></SelectBox>
                  <Input type={'text'} variant={'primary'} value={searchInput.value} onChange={searchInput.onChange} placeholder={'시설물 이름/ID 검색'} width={300} />
                  {/*<Input theme={'dark'} />*/}
                </div>
                <RightBtn onClick={RegiOpen}>+ CCTV 등록</RightBtn>
              </div>
              <div style={{ width: '100%', height: '75%', position: 'relative' }}>
                <Table
                  columns={eventColumns}
                  rows={rows}
                  checkbox
                  leftBtn={DeleteBtn(DeleteOpen)}
                  onRowClick={(params) => setSelectedRowData(params.row)}
                  onRowDoubleClick={DetailOpen}
                  pageSize={PAGE_LIMIT}
                  rowCount={totalCount}
                  curPage={curPage}
                  onCustomPaginationChange={fetchTable}
                  disableSelectionOnClick
                  componentsProps={{
                    row: {
                      style: {
                        background: 'linear-gradient(90.12deg, #313644 0.02%, #1E2530 79.25%)',
                        fontWeight: 'bold',
                        borderRadius: 10,
                        marginBottom: 10,
                      },
                    },
                  }}
                  onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRows = rows.filter((row) => selectedIDs.has(row.id));
                    setCheckedRows(selectedRows);
                  }}
                />
                {apiUtils.renderLoading()}
              </div>
            </div>
          </div>
          {/* 등록 - C */}
          {facilityRegiOpen && <RegiFacilityModal open={facilityRegiOpen} setOpen={setFacilityRegiOpen} fetchTable={fetchTable} />}
          {/* 조회 - R */}
          {facilityDetailOpen && <FacilityDetailModal open={facilityDetailOpen} setOpen={setFacilityDetailOpen} setFacilityDeleteOpen={setFacilityDeleteOpen} rowInfo={fetchedData} fetchTable={fetchTable} />}
          {/* 삭제 - D */}
          {facilityDeleteOpen && <DeleteFacilityModal open={facilityDeleteOpen} setOpen={setFacilityDeleteOpen} rowInfo={selectedRowData} setFacilityDetailOpen={setFacilityDetailOpen} fetchTable={fetchTable} />}
        </div>
      </Container>
    );
  }),
);

export default FacilityManage;
