import React from 'react';
import { DialogContent, DialogTitle, Modal } from '@mui/material';
import { ReactComponent as CLOSE } from '../icons/close.svg';
import ModalStyle from './ModalStyle';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import Button from './Button';

const BaseModal = ({ title, closeIcon, leftBtnLabel, rightBtnLabel, handleRightBtn, handleLeftBtn, handleClose, type, ...rest }) => {
  const titleStyle = {
    fontSize: 22,
    fontWeight: 'bold',
    textAlign: 'center',
  };
  const contentStyle = {
    fontSize: 16,
    fontWeight: '400',
    textAlign: 'center',
    lineHeight: 1.2,
    padding: 0,
    overflow: 'visible',
    height: 'auto',
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Escape') {
      handleClose ? handleClose() : handleLeftBtn();
    }
  };

  const height = rest.height ? rest.height : 230;
  const width = rest.width ? rest.width : 450;

  return (
    <Modal onClose={handleKeyPress} {...rest}>
      <Box sx={ModalStyle(width, height, 3)}>
        <Grid container justifyContent={'center'} alignItems={'center'}>
          {title && (
            <Grid item>
              <DialogTitle sx={titleStyle}>{title}</DialogTitle>
            </Grid>
          )}
          {closeIcon && (
            <Grid container item flex={1} justifyContent={'end'}>
              <CLOSE style={{ cursor: 'pointer' }} onClick={() => handleClose()} />
            </Grid>
          )}
        </Grid>
        <DialogContent sx={contentStyle}>{rest.children}</DialogContent>
        <Grid container justifyContent={'center'} columnGap={'15px'}>
          {handleLeftBtn && (
            <Grid item>
              <Button onClick={handleLeftBtn}>{leftBtnLabel}</Button>
            </Grid>
          )}
          {handleRightBtn && (
            <Grid item>
              <Button onClick={handleRightBtn} variant={'primary'} type={type}>
                {rightBtnLabel}
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default BaseModal;
