const CheckboxGroup = ({ children, label, ...rest }) => {
  const containerStyle = {
    display: 'flex',
    marginBottom: 18,
  };
  const labelStyle = {
    fontSize: 16,
    width: rest.labelwidth ? rest.labelwidth : 110,
    textAlign: 'left',
  };
  const checkboxContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  };
  return (
    <fieldset style={containerStyle}>
      <label style={labelStyle}>{label}</label>
      <div style={checkboxContainerStyle}>{children}</div>
    </fieldset>
  );
};

export default CheckboxGroup;
