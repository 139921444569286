import React from 'react';
import { ReactComponent as VALID } from '../../icons/setting/password_valid.svg';
import { ReactComponent as INVALID } from '../../icons/setting/password_invalid.svg';

const regNotContainSpace = /^\S+$|^$/;
const regContainSpecialChar = /.*[!@#$%^&*()\-_=+[\]{};:'",.<>/?]+.*/;
const regContainAlphabet = /.*[a-zA-Z]+.*/;
const regMoreThan6 = /.{6,}/;
const regLessThan32 = /^.{0,32}$/;

const styles = {
  passwordPolicy: {
    item: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
    },
    text: {
      marginLeft: 7,
    },
    textOrange: {
      color: '#FF9800',
    },
    textGreen: {
      color: '#4CAF50',
    },
  },
};

type Props = {
  password: string;
  getPasswordPoliyResult: Function;
};

export const PASSWORD_RESULT = {
  DO_NOT_CONTAIN_SPACE: {
    status: false,
    message: "비밀번호는 공백을 포함할 수 없습니다.",
    policyMessage: "공백 불가"
  },
  PASSWORD_TOO_SHORT: {
    status: false,
    message: "비밀번호는 6자 이상이어야 합니다.",
    policyMessage: "6자 이상"
  },
  SPECIAL_CHARACTERS_MISSING: {
    status: false,
    message: "비밀번호는 1자 이상의 특수문자를 포함해야 합니다.",
    policyMessage: "특수문자 1개 이상 포함"
  },
  ALPHABET_MISSING: {
    status: false,
    message: "비밀번호는 1자 이상의 알파벳을 포함해야 합니다.",
    policyMessage: "알파벳 1개 이상 포함"
  },
  PASSWORD_TOO_LONG: {
    status: false,
    message: "비밀번호는 32자 이하여야 합니다.",
    policyMessage: "32자 이하"
  },
  AVAILABLE: {
    status: true,
    message: "사용할 수 있는 비밀번호 입니다.",
    policyMessage: "사용가능"
  }
}

export default class PasswordPolicyComponent extends React.Component<Props, any> {
  renderPolicyItem = (reg: RegExp, title: string) => {
    const isPass = reg.test(this.props.password);
    return (
      <div style={styles.passwordPolicy.item}>
        {isPass ? <VALID /> : <INVALID />}
        <span style={{ ...styles.passwordPolicy.text, ...(isPass ? { ...styles.passwordPolicy.textGreen } : { ...styles.passwordPolicy.textOrange }) }}>{title}</span>
      </div>
    );
  };

  getPasswordAvailableResult = (password: string) => {
    let bol = PASSWORD_RESULT.AVAILABLE;
    if (!regMoreThan6.test(password)) (bol = PASSWORD_RESULT.PASSWORD_TOO_SHORT);
    else if (!regContainSpecialChar.test(password)) (bol = PASSWORD_RESULT.SPECIAL_CHARACTERS_MISSING);
    else if (!regContainAlphabet.test(password)) (bol = PASSWORD_RESULT.ALPHABET_MISSING);
    else if (!regLessThan32.test(password)) (bol = PASSWORD_RESULT.PASSWORD_TOO_LONG);
    else if (!regNotContainSpace.test(password)) (bol = PASSWORD_RESULT.DO_NOT_CONTAIN_SPACE);
    this.props.getPasswordPoliyResult(bol);
  };

  render() {
    const { password } = this.props;
    this.getPasswordAvailableResult(password);
    return (
      <>
        <div style={{ position: 'absolute', top: -8, left: 12, backgroundColor: '#FFF', padding: '0 4px' }}>비밀번호 정책</div>
        {this.renderPolicyItem(regMoreThan6, '6자 이상')}
        {this.renderPolicyItem(regContainSpecialChar, '특수문자 1개 이상 포함')}
        {this.renderPolicyItem(regContainAlphabet, '알파벳 1개 이상 포함')}
        {this.renderPolicyItem(regLessThan32, '32자 이하')}
        {this.renderPolicyItem(regNotContainSpace, '공백 불가')}
      </>
    );
  }
}
