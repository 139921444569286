import React, { useEffect, useState } from 'react';
import '../../../css/groupManagement/department/departmentDeleteModal.css';
import AlertMessage from '../../utilities/AlertMessage';
import BaseModal from '../../BaseModal';
import { fetchApi } from '../../../utils/APIUtils';

const DeleteDepartmentModal = ({ open, setOpen, detailOpen, permissions, rowInfo, groupUuid, fetchTable }) => {
  const handleDelBtn = () => {
    fetchApi(`/v1/me/organization/group/${groupUuid}`, 'delete')
      .then((res) => {
        AlertMessage.alertMessage('success', '삭제가 성공적으로 완료되었습니다');
        fetchTable();
        setOpen(false);
        detailOpen(false);
      })
      .catch((err) => console.log('[Line:11 err]', err));
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <BaseModal width={540} height={340} title={'부서 삭제'} open={open} rightBtnLabel={'삭제'} leftBtnLabel={'취소'} handleRightBtn={handleDelBtn} handleLeftBtn={handleClose}>
      <div className='department-delete-modal-container'>
        <div className='confirm'>
          <strong>{rowInfo?.name}</strong> 을(를) 삭제하시겠습니까?
        </div>
        <div className='detail'>
          <div className='each-detail'>
            <div className='title'>회원:</div>
            <div>{rowInfo?.users?.length}명</div>
          </div>
          <div className='each-detail'>
            <div className='title permission'>이벤트 접근 권한:</div>
            <div className={'list'}>{permissions?.join()}</div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteDepartmentModal;
