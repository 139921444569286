import { useEffect, useState } from 'react';
import '../../../css/groupManagement/department/departmentEditModal.css';
import EventAccessCheckBoxes from '../../EventAccessCheckBoxes';
import AlertMessage from '../../utilities/AlertMessage';
import CheckboxGroup from '../../CheckboxGroup';
import { FormProvider, useForm } from 'react-hook-form';
import BaseModal from '../../common/Modal/BaseModal';
import Divider from '../../Divider';
import APIUtils from '../../../utils/APIUtils';
import Input from '../../Input';

const apiUtils = new APIUtils();

const EditDepartmentModal = ({ open, setOpen, rowInfo, groupUuid, fetchPermissions, fetchTable, setIsPermissionFetched }) => {
  const { register, handleSubmit, reset, ...rest } = useForm();

  useEffect(() => {
    rest.setValue('name', rowInfo?.name);
  }, [rest, rowInfo?.name]);

  const onSubmit = (data) => {
    const permissions = [];
    for (const [key, value] of Object.entries(data)) {
      if (key.includes('_permission_')) {
        const [organizationServiceId, type] = key.split('_permission_');
        value &&
          permissions.push({
            organizationServiceId,
            type,
          });
      }
    }

    apiUtils
      .fetchApi(`/v1/me/organization/group/${groupUuid}`, 'patch', {
        name: data.name,
        permissions: permissions,
      })
      .then(() => {
        AlertMessage.alertMessage('success', '수정이 성공적으로 완료되었습니다');
        setIsPermissionFetched(true);
        fetchTable();
        fetchPermissions();
        setOpen(false);
      })
      .finally(() => {
        setIsPermissionFetched(false);
      })
      .catch((err) => console.log('[Line:26 err]', err));
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  return (
    <BaseModal title={'그룹 정보 수정'} open={open} rightBtnTitle={'수정'} leftBtnTitle={'취소'} handleRightBtn={handleSubmit(onSubmit)} handleLeftBtn={handleClose} handleCloseBtn={handleClose} divider>
      <FormProvider register={register} handleSubmit={handleSubmit} reset={reset} {...rest}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='modal-department-edit-container'>
            <Input name={'name'} {...register('name')} label={'그룹명'} placeholder={'그룹명을 입력해주세요'} />
            <CheckboxGroup labelwidth={140} label={'서비스 권한'}>
              <EventAccessCheckBoxes register={register} groupUuid={groupUuid} category={'group'} />
            </CheckboxGroup>
          </div>
        </form>
      </FormProvider>
      {apiUtils.renderLoading()}
    </BaseModal>
  );
};

export default EditDepartmentModal;
