import axios from 'axios';
import { create } from 'apisauce';

// Local development server
// export const baseURL: string = 'http://localhost:4000';

// KKM-MPB-15(TTA 내부망)
// export const baseURL: string = 'http://192.168.3.111:4000';

// KKM-MPB-15(WiFi KKM)
// export const baseURL: string = 'http://192.168.140.129:4000';

// Development server
// export const baseURL: string = 'http://10.0.100.135:4000';

// Production server
export const baseURL: string = 'https://api.anuro.zeroweb.kr';

const axiosInstance = axios.create({ timeout: 30000, baseURL, headers: {} });

// Add a request interceptor
axiosInstance.interceptors.request.use(async (config) => {
  // const httpMetric = firebase.perf().newHttpMetric(config.url, config.method.toUpperCase());
  // config.metadata = { httpMetric };

  // add any extra metric attributes if needed
  // await httpMetric.putAttribute('baseURL', config.baseURL);
  // await httpMetric.putAttribute('User-Agent', config.headers['User-Agent']);
  // await httpMetric.start();

  return config;
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  async (response) => {
    // Request was successful, e.g. HTTP code 200s
    // console.log('axios.response:onFulfilled():response', response);
    // const { httpMetric } = response.config.metadata;

    // add any extra metric attributes if needed
    // httpMetric.putAttribute('baseURL', baseURL);

    // await httpMetric.setHttpResponseCode(response.status);
    // await httpMetric.setResponseContentType(response.headers['content-type']);
    // await httpMetric.stop();

    return response;
  },
  async (error) => {
    // Request failed, e.g. HTTP code 500
    console.warn('axios.response:onRejected():error', error);
    // const { httpMetric } = error.config.metadata;

    // add any extra metric attributes if needed
    // httpMetric.putAttribute('userId', '12345678');

    // await httpMetric.setHttpResponseCode(error.response.status);
    // await httpMetric.setResponseContentType(error.response.headers['content-type']);
    // await httpMetric.stop();

    return Promise.reject(error);
  },
);

// @ts-ignore
export const api_tta = create({ axiosInstance: axiosInstance });
