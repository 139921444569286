import React from 'react';
import Button from '@mui/material/Button';
import styles from './ModalButton.module.css';

interface Props {
  children: React.ReactNode;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  className?: string;
  styles?: React.CSSProperties | undefined;
  onClick: any;
}

const ModalButton = (props: Props) => {
  return (
    <Button className={props.className} variant={props.variant} style={props.styles} onClick={props.onClick}>
      {props.children}
    </Button>
  );
};

export default ModalButton;
