import React, { useEffect, useState } from 'react';
import '../../../css/myPage/userDetail.css';
import ProfileIcon from '../../../icons/profile_icon.png';
import BaseModal from '../../common/Modal/BaseModal';
import { fetchApi } from '../../../utils/APIUtils';
import Divider from '../../Divider';
import EditInfoModal from './EditInfoModal';

const renderType = (rowInfo) => {
  let MASTER = rowInfo?.groups && rowInfo?.groups[0]?.ThroughGroupUser?.master === 1;
  if (MASTER) {
    return '마스터';
  } else {
    return '일반';
  }
};

const UserDetailModal = ({ open, setOpen, rowInfo, isDeleteOpen, fetchTable, setIsPermissionFetched }) => {
  const [permissions, setPermissions] = useState([]);
  const [serviceLists, setServiceLists] = useState([]);
  const [editOpen, setEditOpen] = useState(false);

  const margin = 15;

  const handleClose = () => {
    setOpen(false);
  };

  const openDeleteModal = () => {
    setOpen(false);
    isDeleteOpen(true);
  };

  const openEditModal = () => {
    setEditOpen(true);
  };

  const fetchPermission = () => {
    fetchApi(`/v1/me/organization/user/${rowInfo?.uuid}/permissions`, 'get')
      .then((el) => {
        setPermissions(el);
        return el;
      })
      .then((e) => {
        const serviceListsArr = [];
        e.map((el) => serviceListsArr.push(el.name));
        setServiceLists(serviceListsArr);
      });
  };

  useEffect(() => {
    fetchPermission();
  }, [rowInfo]);

  return (
    <BaseModal open={open} leftBtnTitle={'삭제'} rightBtnTitle={'정보 수정'} handleLeftBtn={openDeleteModal} handleCloseBtn={handleClose} handleRightBtn={openEditModal}>
      <div className='user-detail-modal-container'>
        <div className='header-container'>
          <img className='icon' src={ProfileIcon} alt={'profile'} />
          <div className='name'>{rowInfo?.profile?.name}</div>
          <div className='department'>{rowInfo?.groups ? rowInfo.groups[0]?.name : null}</div>
        </div>
        <div className='detail-container'>
          <Divider marginTop={margin} marginBottom={margin} />
          <div className='each-detail'>
            <div className='title'>아이디</div>
            <div className={'detail'}>{rowInfo?.username}</div>
          </div>
          <Divider marginTop={margin} marginBottom={margin} />
          <div className='each-detail'>
            <div className='title'>연락처</div>
            <div className={'detail'}>{rowInfo?.profile?.phoneNumber}</div>
          </div>
          <Divider marginTop={margin} marginBottom={margin} />
          <div className='each-detail'>
            <div className='title'>이벤트 권한</div>
            <div className={'detail'}>{serviceLists?.join()}</div>
          </div>
          <Divider marginTop={margin} marginBottom={margin} />
          <div className='each-detail'>
            <div className='title'>계정 권한</div>
            <div className={'detail'}>{renderType(rowInfo)}</div>
          </div>
          <Divider marginTop={margin} marginBottom={margin} />
        </div>
      </div>
      {/* 수정 - U */}
      <EditInfoModal open={editOpen} setOpen={setEditOpen} rowInfo={rowInfo} fetchTable={fetchTable} fetchPermission={fetchPermission} setIsPermissionFetched={setIsPermissionFetched} />
    </BaseModal>
  );
};

export default UserDetailModal;
