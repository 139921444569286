import moment from 'moment/moment';
import React from 'react';
import DetailItem from './DetailItem';
import {IEvent} from 'anuro-platform-core';
import styles from '../../../../css/event/eventDetailModal/DetailInfo.module.css';

interface Props {
  rowInfo: IEvent;
}

const DetailList = ({ rowInfo }: Props) => {
  return (
    <div className={styles.detail_list}>
      <DetailItem title={'발생일'} detail={moment(rowInfo?.issuedAt).format('YY년 M월 D일 HH:mm:ss')} />
      <DetailItem title={'제목'} detail={rowInfo?.title || ''} />
      <DetailItem title={'담당자'} detail={rowInfo?.chargeUser?.username || '미배정'} />
      <DetailItem title={'위치'} detail={rowInfo.addressStreet || ''} />
      <DetailItem title={'상세주소'} detail={rowInfo.addressStreetDetail || ''} />
      <DetailItem title={'상세내용'} detail={rowInfo.message || ''} />
    </div>
  );
};

export default DetailList;
