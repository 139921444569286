import {useEffect, useState} from 'react';
import {FormProvider, useForm, useFormContext} from 'react-hook-form';
import {defaultCheckboxList} from '../constants/SelectOptions';
import AlertMessage from './utilities/AlertMessage';
import {PERMISSION_TYPE} from 'anuro-platform-core';
import {fetchApi} from '../utils/APIUtils';
import Menu from '@mui/material/Menu';
import '../css/serviceIconList.css';
import Checkbox from './Checkbox';

const ServiceIcon = ({ organizationService, permissions, userUuid, fetchPermission, category, groupUuid }) => {
  const form = useForm();
  const permission = permissions.find((p) => p.organizationServiceId === organizationService.id);

  const onSubmit = (data) => {
    let newPermissions = [];
    for (const [key, value] of Object.entries(data)) {
      if (key.includes('_permission_')) {
        const [organizationServiceId, type] = key.split('_permission_');
        value &&
          newPermissions.push({
            organizationServiceId: Number(organizationServiceId),
            type,
          });
      }
    }

    newPermissions = permissions
      .filter((p) => p.organizationServiceId !== organizationService.id)
      .map(({ organizationServiceId, allowedActions }) => {
        return allowedActions.map((a) => ({
          organizationServiceId,
          type: a.type,
        }));
      })
      .flat()
      .concat(newPermissions);

    fetchApi(`/v1/me/organization/${category}/${userUuid}`, `patch`, {
      permissions: newPermissions,
      groupUuid,
    })
      .then(() => {
        AlertMessage.alertMessage('success', '수정이 완료되었습니다');
        fetchPermission();
      })
      .finally(() => {
        handleClose();
      })
      .catch((err) => console.log('[Line:75 err]', err));
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }} onClick={(e) => {}}>
      <img className={`icon-img ${permission?.allowedActions.length > 0 ? null : 'filter'}`} src={organizationService.iconUrl} id='basic-button' aria-controls={open ? 'basic-menu' : undefined} aria-haspopup='true' aria-expanded={open ? 'true' : undefined} onClick={handleClick} />
      <FormProvider {...form}>
        <Menu
          id='basic-menu'
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          <PermissionCheckboxes serviceId={organizationService.id} onSubmit={onSubmit} permissions={permission?.allowedActions || []} />
        </Menu>
      </FormProvider>
    </div>
  );
};

const PermissionCheckboxes = ({ serviceId, permissions, onSubmit }) => {
  const form = useFormContext();

  return (
    <div className={'checkbox-modal-container'}>
      {defaultCheckboxList.map((obj) => {
        let checked = permissions.find((p) => p.type === obj.name) ? true : false;
        const checkboxKey = serviceId + '_permission_' + obj.name;
        return <Checkbox key={serviceId + obj.name} defaultChecked={checked} label={obj.label} {...form.register(checkboxKey)} />;
      })}
      <div style={{ cursor: 'pointer', borderTop: '1px solid lightgray', paddingTop: 8 }} onClick={() => onSubmit(form.getValues())}>
        적용
      </div>
    </div>
  );
};

const PermissionLabel = ({ organizationService, permissions }) => {
  const permission = permissions.find((p) => p.organizationServiceId === organizationService.id);

  const checkedPermissions = permission?.allowedActions?.map((p) => p.type);
  const setColor = () => {
    if (checkedPermissions?.includes(PERMISSION_TYPE.D)) {
      return '#EC3900';
    } else if (checkedPermissions?.includes(PERMISSION_TYPE.C)) {
      return '#00AC45';
    } else if (checkedPermissions?.includes(PERMISSION_TYPE.U)) {
      return '#00B3EC';
    } else {
      return '#767676';
    }
  };

  return (
    <div
      style={{
        backgroundColor: setColor(),
        minWidth: 70,
        borderRadius: 5,
        textAlign: 'center',
        padding: '0 8px',
        position: 'absolute',
        fontSize: 12,
        color: 'white',
      }}>
      {checkedPermissions?.join().replaceAll(',', '/')}
    </div>
  );
};

const ServiceIconList = ({ uuid, category, isPermissionFetched, groupUuid }) => {
  const [list, setList] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const fetchServiceList = () => {
    fetchApi(`/v1/me/organization/service`, `get`)
      .then((el) => setList(el))
      .catch((err) => console.log('[Line:28 err]', err));
  };

  const fetchPermission = () => {
    fetchApi(`/v1/me/organization/${category}/${uuid}/permissions`, 'get')
      .then((el) => {
        setPermissions(el);
      })
      .catch((err) => console.log('[Line:36 err]', err));
  };

  useEffect(() => {
    fetchServiceList();
    fetchPermission();
  }, [isPermissionFetched]);

  return list.map((service, idx) => (
    <div
      key={idx}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: 45,
        justifyContent: 'flex-end',
      }}>
      <ServiceIcon organizationService={service.organizationService} groupUuid={groupUuid} category={category} userUuid={uuid} permissions={permissions} fetchPermission={fetchPermission} />
      <PermissionLabel organizationService={service.organizationService} permissions={permissions} />
    </div>
  ));
};

export default ServiceIconList;
