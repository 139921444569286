import { Button } from "@material-ui/core";
import { RiKakaoTalkFill } from "react-icons/ri";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookSquare, FaApple } from "react-icons/fa";

import "../../css/social-login-buttons.css";

export const KakaoLoginButton = (props) => {
  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <Button
      className="kakao-btn social-btn"
      variant="contained"
      onClick={onClick}
    >
      <RiKakaoTalkFill />
      <span className="social-label">Login with Kakao</span>
    </Button>
  );
};

export const NaverLoginButton = (props) => {
  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <Button
      className="naver-btn  social-btn"
      variant="contained"
      onClick={onClick}
    >
      <span className="naver-icon">N</span>
      <span className="social-label">Login with Naver</span>
    </Button>
  );
};

export const GoogleLoginButton = (props) => {
  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <Button
      className="google-btn  social-btn"
      variant="contained"
      onClick={onClick}
    >
      <FcGoogle />
      <span className="social-label">Login with Google</span>
    </Button>
  );
};

export const FacebookLoginButton = (props) => {
  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <Button
      className="facebook-btn  social-btn"
      variant="contained"
      onClick={onClick}
    >
      <FaFacebookSquare />
      <span className="social-label">Login with Facebook</span>
    </Button>
  );
};

export const AppleLoginButton = (props) => {
  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <Button
      className="apple-btn  social-btn"
      variant="contained"
      onClick={onClick}
    >
      <FaApple />
      <span className="social-label">Login with Apple</span>
    </Button>
  );
};
