import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import CreatableSelect from "react-select/creatable";
import ModalStyle from "../ModalStyle";

import "../../css/event/smsDetailModal.css";

import Select from "react-select";
import moment from "moment";
import MapNaver from "../MapNaver";

const SMSDetailModal = ({ smsDetailOpen, setSmsDetailOpen }) => {
  const handleClose = () => {
    setSmsDetailOpen(false);
  };

  const eventLeftOptions = [
    { value: "chocolate", label: "119 긴급출동" },
    { value: "strawberry", label: "112 긴급출동" },
    { value: "vanilla", label: "114 전화" },
  ];
  const eventRightOptions = [
    { value: "chocolate", label: "화재" },
    { value: "strawberry", label: "강도" },
    { value: "vanilla", label: "궁금함" },
  ];
  const personLeftOptions = [
    { value: "chocolate", label: "해운대구관제센터1" },
    { value: "strawberry", label: "해운대구관제센터2" },
    { value: "vanilla", label: "해운대구관제센터3" },
  ];
  const personRightOptions = [
    { value: "chocolate", label: "박이름" },
    { value: "strawberry", label: "김이름" },
    { value: "vanilla", label: "홍이름" },
  ];

  return (
    <Modal
      open={smsDetailOpen}
      // onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={ModalStyle(900, 600, 5)}>
        <div id="modal-title" className="modal-sms-title">
          <h2>SMS 내용</h2>
        </div>
        <div className="sms-edit-modal">
          <div className="sms-send-row">
            <div className="sms-row-title">템플릿 유형</div>
            <RadioGroup row name="row-radio-buttons-group">
              <FormControlLabel
                value="civil"
                control={<Radio />}
                label="시민용"
              />
              <FormControlLabel
                value="sms_req"
                control={<Radio />}
                label="SMS 요청"
              />
              <FormControlLabel
                value="event_end"
                control={<Radio />}
                label="이벤트 종료"
              />
            </RadioGroup>
          </div>
          <div className="sms-send-row">
            <div className="sms-row-title">이벤트 유형</div>
            <RadioGroup row name="row-radio-buttons-group">
              <FormControlLabel value="fire" control={<Radio />} label="화재" />
              <FormControlLabel
                value="rescue"
                control={<Radio />}
                label="구조, 구급"
              />
              <FormControlLabel
                value="car_accident"
                control={<Radio />}
                label="교통사고"
              />
            </RadioGroup>
          </div>
          <div className="sms-send-row">
            <div className="sms-row-title">연락처</div>
            <CreatableSelect
              className="sms-number-select"
              isMulti
              name="colors"
              options={eventLeftOptions}
              placeholder="전화번호를 입력해주세요"
            />
          </div>
          <div className="sms-send-row">
            <div className="sms-row-title">제목</div>
            <input
              type="text"
              className="sms-row-input"
              placeholder="제목을 입력해주세요"
            ></input>
          </div>
          <div className="sms-send-row">
            <div className="sms-row-title">내용</div>
            <textarea
              className="sms-row-input contents"
              placeholder="내용을 입력해주세요"
            ></textarea>
          </div>
          <div className="sms-list-btn-conatiner">
            <button className="cancle-btn" onClick={handleClose}>
              닫기
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default SMSDetailModal;
