import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import CreatableSelect from "react-select/creatable";
import ModalStyle from "../ModalStyle";
import "../../css/event/smsEditModal.css";

const SMSEditModal = ({ smsEditOpen, setSmsEditOpen, setSmsCompleteOpen }) => {
  const handleClose = () => {
    setSmsEditOpen(false);
  };

  const clickSendBtn = () => {
    setSmsEditOpen(false);
    setSmsCompleteOpen(true);
  };

  const eventLeftOptions = [
    { value: "chocolate", label: "119 긴급출동" },
    { value: "strawberry", label: "112 긴급출동" },
    { value: "vanilla", label: "114 전화" },
  ];

  return (
    <Modal
      open={smsEditOpen}
      // onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={ModalStyle(900, 600, 5)}>
        <div id="modal-title" className="modal-sms-title">
          <h2>SMS 내용 수정</h2>
        </div>
        <div className="sms-edit-container">
          <div className="each-row">
            <div className="title">템플릿 유형</div>
            <RadioGroup row name="row-radio-buttons-group">
              <FormControlLabel
                value="civil"
                control={<Radio />}
                label="시민용"
              />
              <FormControlLabel
                value="sms_req"
                control={<Radio />}
                label="SMS 요청"
              />
              <FormControlLabel
                value="event_end"
                control={<Radio />}
                label="이벤트 종료"
              />
            </RadioGroup>
          </div>
          <div className="each-row">
            <div className="title">이벤트 유형</div>
            <RadioGroup row name="row-radio-buttons-group">
              <FormControlLabel value="fire" control={<Radio />} label="화재" />
              <FormControlLabel
                value="rescue"
                control={<Radio />}
                label="구조, 구급"
              />
              <FormControlLabel
                value="car_accident"
                control={<Radio />}
                label="교통사고"
              />
            </RadioGroup>
          </div>
          <div className="each-row">
            <div className="title">연락처</div>
            <CreatableSelect
              className="select"
              isMulti
              name="colors"
              options={eventLeftOptions}
              placeholder="전화번호를 입력해주세요"
            />
          </div>
          <div className="each-row">
            <div className="title">제목</div>
            <input
              type="text"
              className="input"
              placeholder="제목을 입력해주세요"
            ></input>
          </div>
          <div className="each-row">
            <div className="title">내용</div>
            <textarea
              className="input textarea"
              placeholder="내용을 입력해주세요"
            ></textarea>
          </div>
          <div className="btn-conatiner-wrapper">
            <div className="btn-container">
              <button className="btn-cancle btn" onClick={handleClose}>
                취소
              </button>
            </div>
            <div className="btn-container">
              <button className="btn-save btn">저장하기</button>
              <button className="btn-send btn" onClick={clickSendBtn}>
                보내기
              </button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default SMSEditModal;
