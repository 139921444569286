import React, { forwardRef } from 'react';
import ReactSelect, { Props as SelectProps } from 'react-select';

interface Props extends SelectProps {
  variant?: 'bright' | 'dark' | 'primary';
  label?: string;
  containerWidth?: number | string;
  width?: number | string;
  height?: number | string;
}

const SelectBox = forwardRef(({ variant, options, placeholder, name, label, ...props }: Props, ref: any) => {
  const primary = variant === 'primary';
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    marginBottom: primary ? 0 : 18,
    width: props.containerWidth,
    textAlign: 'left',
  };
  const labelStyle: React.CSSProperties = {
    fontSize: 16,
    width: 110,
    textAlign: 'left',
  };
  const selectStyle = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      width: props.width ? props.width : 180,
      height: props.height ? props.height : 40,
      backgroundColor: primary ? '#313644' : 'white',
      color: 'yellow',
      border: primary ? 0 : '1px solid #DDDDDD',
      borderRadius: 5,
      marginRight: 8,
      fontSize: 14,
      textAlign: 'left',
      cursor: 'pointer',
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: primary ? 'lightGray' : 'gray',
    }),
    singleValue: (baseStyle) => ({
      ...baseStyle,
      color: primary ? 'white' : 'black',
      width: '100%',
    }),
    input: (baseStyle) => ({
      ...baseStyle,
      color: primary ? 'white' : 'black',
      border: '0px',
    }),
  };

  return (
    <div style={containerStyle}>
      {label ? <label style={labelStyle}>{label}</label> : null}
      <ReactSelect name={name} ref={ref} styles={selectStyle} options={options} placeholder={placeholder} {...props} components={{ IndicatorSeparator: () => null }} />
    </div>
  );
});

export default SelectBox;
