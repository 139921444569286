import { Box, Modal } from '@mui/material';
import React from 'react';
import ModalStyle from './ModalStyle';
import '../css/confirmDialogModal.css';
import APIUtils from '../utils/APIUtils';
import AlertMessage from './utilities/AlertMessage';
import EventEmitter from '../utils/EventEmitter';

const apiUtils = new APIUtils();
const emitter = EventEmitter.getInstance();
const EventDeleteConfirmModal = (props) => {
  const { confirmDialogOpen, isConfirmDialogOpen, setPrevModal, title, detail, deleteData, fetchData } = props;
  const handleClose = () => {
    isConfirmDialogOpen(false);
    if (setPrevModal !== undefined) {
      setPrevModal(true);
    }
  };

  const handleOk = () => {
    deleteData.map((i, idx) => {
      apiUtils
        .fetchApi(`/v1/me/organization/event/${i.organizationEventCategory.organizationServiceId}/${i.uuid}`, 'delete')
        .then(() => {
          if (deleteData.length - 1 === idx) {
            // emitter.emit('removeMarker', { markerType: 'eventCircle' });
            emitter.emit('removeMarker', { markerType: 'event' });
            setTimeout(() => {
              AlertMessage.alertMessage('success', '성공적으로 삭제가 완료되었습니다');
              isConfirmDialogOpen(false);
              fetchData();
            }, 1000);
          }
        })
        .catch((err) => console.log('[Line:22 err]', err));
    });
  };

  return (
    <Modal
      open={confirmDialogOpen}
      // onClose={handleClose}
      className='delete-modal-container'>
      <Box sx={ModalStyle(500, 250, 5)}>
        <div className='modal-dialog-container'>
          <h2>{title}</h2>
          <div className='detail'>{detail}</div>
          <div className='btn-container'>
            <button className='btn-cancel' onClick={handleClose}>
              취소
            </button>
            <button className='btn-ok' onClick={handleOk}>
              확인
            </button>
          </div>
        </div>
        {apiUtils.renderLoading()}
      </Box>
    </Modal>
  );
};

export default EventDeleteConfirmModal;
