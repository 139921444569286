import React from 'react';
import '../../../css/myPage/deleteFacility.css';
import BaseModal from '../../BaseModal';
import { fetchApi } from '../../../utils/APIUtils';
import AlertMessage from '../../utilities/AlertMessage';

const DeleteFacilityModal = ({ open, setOpen, rowInfo, fetchTable, setFacilityDetailOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelBtn = () => {
    fetchApi(`/v1/me/organization/facility/${rowInfo.uuid}`, `delete`)
      .then(() => {
        AlertMessage.alertMessage('success', '삭제되었습니다');
        fetchTable();
        setOpen(false);
      })
      .catch((err) => console.log('[Line:21 err]', err));
  };

  return (
    <BaseModal title={'시설물 삭제'} open={open} leftBtnLabel={'취소'} rightBtnLabel={'삭제'} handleLeftBtn={handleClose} handleRightBtn={handleDelBtn}>
      <div className='facility-del-content confirm'>
        삭제한 후에는 되돌릴 수 없습니다. <br /> 선택한 시설물을 정말 삭제하시겠습니까?
      </div>
    </BaseModal>
  );
};

export default DeleteFacilityModal;
