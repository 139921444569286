import React, { useEffect, useState } from 'react';
import '../../../css/event/smsModal.css';
import AlertMessage from '../../utilities/AlertMessage';
import SmsListDetailModal from './SmsListDetailModal';
import APIUtils from '../../../utils/APIUtils';
import moment from 'moment/moment';
import Select from 'react-select';
import Table from '../../Table';
import SMSDeletedModal from '../SMSDeletedModal';
import DeleteSms from '../../eventManage/modals/DeleteSms';
import Input from '../../Input';

const apiUtils = new APIUtils();
const PAGE_LIMIT = 6;

const SmsListComponent = ({}) => {
  const [curPage, setCurPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [checkedRows, setCheckedRows] = useState([]);

  const [smsListDetailOpen, setSmsListDetailOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [smsDeleteOpen, setSmsDeleteOpen] = useState(false);

  let intervalTimer = null;

  const smsSendListColumns = [
    {
      field: 'createdAt',
      headerName: '발송일',
      width: 180,
      valueGetter: (params) => moment(params.value).format('YY-MM-DD HH:mm:ss'),
    },
    {
      field: 'to',
      headerName: '수신자',
      width: 150,
      valueGetter: (params) => {
        let tos = [];
        try {
          tos = JSON.parse(params.value);
        } catch (e) {
          console.error('To value is not valid json object');
        }

        if (tos.length > 1) {
          return `${tos.length}명의 수신자`;
        } else if (tos.length === 1) {
          return tos[0];
        } else {
          return '';
        }
      },
    },

    {
      field: 'message',
      headerName: '발송내용',
      flex: 1,
    },
  ];

  const fetchSmsSendList = (q = '', p = 1) => {
    setCurPage(p);
    apiUtils
      .fetchApi('/v1/message-service', 'get', {
        limit: PAGE_LIMIT,
        offset: p ? (p - 1) * PAGE_LIMIT : 0,
        ob: 'id',
        od: 'DESC',
        temp: 0,
        q,
      })
      .then((res) => {
        console.log('[Line:53 r]', res);
        setRows(res.rows);
        setTotalCount(res.count);
      });
  };

  useEffect(() => {
    fetchSmsSendList();
  }, []);

  const handleSMSDelBtn = () => {
    setSmsDeleteOpen(true);
  };

  const handleDeleteBtn = () => {
    if (checkedRows.length < 1) return AlertMessage.alertMessage('error', '삭제할 이벤트를 선택해 주세요');
    const deleteEventList = rows.map((i) => {
      if (checkedRows.includes(i.id)) return i;
    });
    setSelectedRowData(deleteEventList);
    setSmsDeleteOpen(true);
  };

  const leftBtn = () => {
    return (
      <button className='btn-del' onClick={handleDeleteBtn}>
        선택 삭제
      </button>
    );
  };

  const rightBtn = () => {
    return <div style={{ width: 134 }}></div>;
  };

  return (
    <div className='sms-list-container'>
      <div className='sms-list-header'>
        <div className='title'>발송 {rows.length}건</div>
        <div className='search-container'>
          <input
            type='text'
            className='search'
            placeholder='검색어를 입력해주세요'
            width={'100%'}
            onChange={(e) => {
              clearTimeout(intervalTimer);
              fetchSmsSendList(e.target.value);
            }}></input>
        </div>
      </div>
      <div className='table-container'>
        <div style={{ width: '100%', height: '500px', position: 'relative' }}>
          <Table
            columns={smsSendListColumns}
            rows={rows}
            variant={'bright'}
            checkbox
            leftBtn={leftBtn()}
            rightBtn={rightBtn()}
            pageSize={PAGE_LIMIT}
            rowCount={totalCount}
            curPage={curPage}
            sx={{
              '& .MuiSvgIcon-root': {
                color: 'grey',
              },
              '& .Mui-selected .MuiSvgIcon-root': {
                color: '#496DED',
              },
            }}
            onCustomPaginationChange={fetchSmsSendList}
            onRowDoubleClick={(params) => {
              setSmsListDetailOpen(true);
              setSelectedRowData(params.row);
            }}
            componentsProps={{
              row: {
                style: {
                  borderRadius: 10,
                  marginBottom: 10,
                  backgroundColor: '#fff',
                  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
                },
              },
            }}
            onSelectionModelChange={(ids) => {
              let checkedData = [];
              rows.map((i) => {
                if (ids.includes(i.id)) return checkedData.push(i);
              });
              setCheckedRows(checkedData);
            }}
          />
          {apiUtils.renderLoading()}
        </div>
      </div>
      <SmsListDetailModal rowInfo={selectedRowData} smsListDetailOpen={smsListDetailOpen} setSmsListDetailOpen={setSmsListDetailOpen} />
      <DeleteSms open={smsDeleteOpen} setOpen={setSmsDeleteOpen} deleteData={checkedRows} fetchData={fetchSmsSendList} />
    </div>
  );
};

export default SmsListComponent;
