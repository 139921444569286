import { useEffect, useState } from 'react';
import '../../../css/event/sms/smsEditModal.css';
import { Controller, useForm } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import APIUtils from '../../../utils/APIUtils';
import { Box, Modal } from '@mui/material';
import ModalStyle from '../../ModalStyle';
import Divider from '../../Divider';
import Input from '../../Input';
import moment from 'moment';
import AlertMessage from '../../utilities/AlertMessage';

const apiUtils = new APIUtils();

const SmsEditModal = ({ rowInfo, open, setOpen, setTabValue }) => {
  const { handleSubmit, reset, control, register } = useForm();
  const [eventInfo, setEventInfo] = useState({});
  const handleClose = () => {
    setEventInfo({});
    setOpen(false);
  };

  const onSubmit = (data) => {
    apiUtils
      .fetchApi(`/v1/message-service`, `post`, {
        temp: 0,
        message: data.message,
        to: data.to.map((e) => e.value),
        eventUuid: rowInfo?.eventUuid,
      })
      .then(() => {
        apiUtils.fetchApi(`/v1/message-service/${rowInfo?.uuid}`, 'delete');
      })
      .then(() => {
        AlertMessage.alertMessage('success', 'SMS 전송이 성공적으로 완료되었습니다');
        setTabValue('2');
      })
      .catch((err) => console.log('[Line:27 err]', err));
  };

  const receivers = rowInfo && JSON.parse(rowInfo?.to);
  const selectDefault = receivers?.map((e) => {
    return {
      label: e,
      value: e,
    };
  });

  useEffect(() => {
    reset({
      to: selectDefault,
      message: rowInfo?.message,
    });
  }, []);

  useEffect(() => {
    const fetchEventInfo = () => {
      apiUtils
        .fetchApi(`/v1/me/organization/event/${rowInfo?.eventUuid}`, 'get')
        .then((res) => {
          setEventInfo(res);
        })
        .catch((err) => console.log('[Line:24 err]', err));
    };

    rowInfo?.eventUuid && fetchEventInfo();
  }, [rowInfo]);

  const ReceiverOptions = () => {
    return (
      <Controller
        name='to'
        control={control}
        render={({ field }) => {
          return <CreatableSelect defaultValue={rowInfo?.to} width={'calc(100% - 110px)'} isMulti name={'to'} placeholder={'전화번호를 입력해주세요'} {...field} />;
        }}
      />
    );
  };

  return (
    <Modal open={open} onClose={handleClose} className='sms-modal-container'>
      <Box sx={ModalStyle(1000, 580, 5)}>
        <div className='sms-edit-container'>
          <div className='header'>임시저장 상세내용</div>
          <Divider marginTop={15} marginBottom={20} color={'#999999'} />
          <div className='each-row'>
            <div className='title'>이벤트</div>
            {eventInfo?.organizationEventCategory?.organizationService?.service?.name ? (
              <div className='detail'>
                <div className='main'>
                  {eventInfo?.organizationEventCategory?.organizationService?.service?.name} ({eventInfo?.organizationEventCategory?.eventCategory?.name})
                </div>
                <div className='sub'>
                  발생위치: {eventInfo?.addressStreet} {eventInfo?.addressStreetDetail}
                </div>
                <div className='sub wrapper'>
                  <div className='left'>이벤트 ID: {eventInfo?.code}</div>
                  <div>발생일시: {moment(eventInfo?.issuedAt).format('YY-MM-DD HH:mm:ss')}</div>
                </div>
              </div>
            ) : (
              '미지정'
            )}
          </div>
          <div className='each-row'>
            <div className='title'>수신자</div>
            <ReceiverOptions />
          </div>
          <Input width={'calc(100% - 110px)'} textarea height={120} label={'내용'} placeholder='상세내용을 입력해주세요' {...register('message')} />
          <div className='btn-container'>
            <button className='btn-close btn' onClick={handleClose}>
              닫기
            </button>
            <button className='btn-send btn' onClick={handleSubmit(onSubmit)}>
              보내기
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default SmsEditModal;
