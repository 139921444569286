import React, { useEffect, useState } from 'react';
import '../../css/event/eventEditModal.css';
import { Controller, useForm } from 'react-hook-form';
import AlertMessage from '../utilities/AlertMessage';
import EventEmitter from '../../utils/EventEmitter';
import { fetchApi } from '../../utils/APIUtils';
import AddressInput from '../AddressInput';
import SelectBox from '../SelectBox';
import BaseModal from '../common/Modal/BaseModal';
import Input from '../Input';
import moment from 'moment';

const EventEditModal = ({ open, setOpen, rowInfo, fetchData }) => {
  const emitter = EventEmitter.getInstance();
  const CURRENT_TIME = moment().format('YY-MM-DD HH:mm:ss');
  const { register, handleSubmit, reset, control, watch } = useForm();
  const [address, setAddress] = useState();

  const watchType = watch('eventService');
  const serviceId = watchType?.id;

  const eventDefault = rowInfo?.organizationEventCategory
    ? {
        label: rowInfo?.organizationEventCategory.eventCategory.service.name,
        value: rowInfo?.organizationEventCategory?.organizationServiceId,
      }
    : null;

  const categoryDefault = rowInfo?.organizationEventCategory
    ? {
        label: rowInfo.organizationEventCategory.eventCategory.name,
        value: rowInfo.organizationEventCategory.id,
      }
    : null;

  const chargedPersonDefault = rowInfo?.chargeUser
    ? {
        label: rowInfo?.chargeUser.username,
        value: rowInfo?.chargeUser.uuid,
      }
    : null;

  useEffect(() => {
    reset({
      eventService: eventDefault,
      category: categoryDefault,
      chargeUserUuid: chargedPersonDefault,
      username: rowInfo?.chargeUser?.username,
      message: rowInfo?.message,
      addressStreetDetail: rowInfo?.addressStreetDetail,
      title: rowInfo?.title,
    });
  }, [rowInfo]);

  if (!rowInfo) return null;

  const onSubmit = (data) => {
    fetchApi(`/v1/me/organization/event/${eventDefault.value || data.eventService.id}/${rowInfo.uuid}`, `patch`, {
      organizationEventCategoryId: data?.category?.value,
      addressStreet: address?.roadAddr,
      latitude: address?.latitude,
      longitude: address?.longitude,
      chargeUserUuid: data?.chargeUserUuid?.value,
      message: data?.message,
      addressStreetDetail: data?.addressStreetDetail,
      title: data?.title,
    })
      .then((r) => {
        AlertMessage.alertMessage('success', '이벤트 수정이 완료되었습니다');
        fetchData();
        setOpen(false);
        emitter.emit('removeMarker', { markerType: 'search' });
      })
      .catch((err) => console.log('[Line:32 err]', err));
  };
  const handleClose = () => {
    setOpen(false);
  };

  const EventOptions = () => {
    const eventsArr = [];
    fetchApi(`/v1/me/organization/service`, `get`).then((res) => {
      res.map((e) => {
        return eventsArr.push({
          value: e?.uuid,
          label: e?.name,
          id: e?.organizationService.id,
        });
      });
    });

    return (
      <Controller
        name='eventService'
        control={control}
        render={({ field }) => {
          return <SelectBox defaultValue={eventDefault} containerWidth={'100%'} label={'이벤트'} name={'eventService'} width={'100%'} options={eventsArr} placeholder={'이벤트를 선택해주세요'} {...field} />;
        }}
      />
    );
  };

  const CategoryOptions = () => {
    const categoryArr = [];
    if (serviceId) {
      fetchApi(`/v1/me/organization/service/${serviceId}/event-category`, `get`).then((res) => {
        res.map((e) => {
          return categoryArr.push({
            value: e?.organizationEventCategory.id,
            label: e?.name,
          });
        });
      });
    }
    return (
      <Controller
        name='category'
        control={control}
        render={({ field }) => {
          return <SelectBox defaultValue={categoryDefault} containerWidth={'100%'} label={'유형'} name={'category'} width={'100%'} options={categoryArr} placeholder={'유형을 선택해주세요'} {...field} />;
        }}
      />
    );
  };

  const UserListOptions = () => {
    const userListArr = [];
    fetchApi(`/v1/me/organization/user`, `get`).then((res) => {
      res.rows.map((e) => {
        return userListArr.push({
          value: e?.uuid,
          label: `${e?.profile?.name}(${e?.username})`,
        });
      });
    });
    return (
      <Controller
        name='chargeUserUuid'
        control={control}
        render={({ field }) => {
          return <SelectBox defaultValue={chargedPersonDefault} containerWidth={'100%'} label={'담당자'} name={'chargeUserUuid'} width={'100%'} options={userListArr} placeholder={'담당자를 선택해주세요'} {...field} />;
        }}
      />
    );
  };

  return (
    <BaseModal title={'이벤트 정보 수정'} open={open} rightBtnTitle={'수정'} handleCloseBtn={handleClose} leftBtnTitle={'취소'} handleLeftBtn={handleClose} handleRightBtn={handleSubmit(onSubmit)} divider>
      <div className={'event-edit-container'}>
        <Input width={'100%'} label={'일시'} {...register('date')} disabled defaultValue={CURRENT_TIME} />
        <Input width={'100%'} name={'title'} {...register('title')} label={'제목'} placeholder={'제목을 입력해주세요'} />
        <EventOptions />
        <CategoryOptions />
        <div className='each-row'>
          <div className='title'>위치</div>
          <AddressInput defaultValue={rowInfo.addressStreet} label={'address'} {...register('address')} className='detail' width={'100%'} placeholder='위치를 입력해주세요' radius={5} height={40} padding={10} onChange={(e) => setAddress(e)} />
        </div>
        <Input width={'100%'} name={'addressStreetDetail'} {...register('addressStreetDetail')} label={'상세주소'} placeholder={'상세주소를 입력해주세요'} />
        <UserListOptions />
        <Input width={'100%'} textarea height={120} label={'상세내용'} placeholder='상세내용을 입력해주세요' {...register('message')} />
      </div>
    </BaseModal>
  );
};

export default EventEditModal;
