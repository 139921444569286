import { useEffect, useState } from 'react';
import '../css/permissionFilter.css';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { defaultCheckboxList } from '../constants/SelectOptions';
import { fetchApi } from '../utils/APIUtils';
import Menu from '@mui/material/Menu';
import Checkbox from './Checkbox';

const ServiceIcon = ({ organizationService, permissions }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => {}}>
      <img className={`icon-img ${permissions?.length > 0 ? null : 'filter'}`} alt={'icon'} src={organizationService.iconUrl} />
    </div>
  );
};

const PermissionCheckboxes = ({ serviceId }) => {
  const form = useFormContext();

  return (
    <div className={'filter-checkbox-modal-container'}>
      {defaultCheckboxList.map((obj) => {
        const checkboxKey = serviceId + '_permission_' + obj.name;
        return <Checkbox key={checkboxKey + obj.name} fontSize={14} label={obj.label} {...form.register(checkboxKey)} />;
      })}
    </div>
  );
};

const AllPermissionCheckbox = ({ serviceList }) => {
  const form = useFormContext();
  const [checkedAll, setCheckedAll] = useState(false);

  const toggleCheckAll = () => {
    setCheckedAll(!checkedAll);
    let newPermissions = [];
    if (checkedAll === false) {
      for (const [key] of Object.entries(form.getValues())) {
        if (key.includes('_permission_')) {
          form.setValue(key, !checkedAll);
          const [organizationServiceId, type] = key.split('_permission_');
          newPermissions.push({
            organizationServiceId: Number(organizationServiceId),
            type,
          });
        }
      }
    } else {
      newPermissions = [];
      for (const [key] of Object.entries(form.getValues())) {
        if (key.includes('_permission_')) {
          form.setValue(key, !checkedAll);
        }
      }
    }

    form.setValue('permissions', newPermissions);
  };

  const onChange = () => {
    let newPermissions = [];
    for (const [key, value] of Object.entries(form.getValues())) {
      if (key.includes('_permission_')) {
        const [organizationServiceId, type] = key.split('_permission_');
        value &&
          newPermissions.push({
            organizationServiceId: Number(organizationServiceId),
            type,
          });
      }
    }
    form.setValue('permissions', newPermissions);
  };

  return (
    <div
      style={{
        minHeight: '200px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // padding: '0 10px',
        width: '470px',
      }}>
      <div onChange={onChange} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {serviceList.map((service, idx) => {
          return <PermissionCheckboxes key={idx} serviceId={service?.organizationService?.id} />;
        })}
      </div>
      <div
        onClick={toggleCheckAll}
        style={{
          cursor: 'pointer',
          paddingTop: '8px',
          borderTop: '1px solid lightgray',
          width: '100%',
          textAlign: 'center',
        }}>
        {checkedAll ? '전체 해제' : '전체 선택'}
      </div>
    </div>
  );
};

const PermissionLabel = ({ organizationService, permissions }) => {
  const permission = permissions.filter((p) => p.organizationServiceId === organizationService.id);
  const checkedPermissions = permission?.map((p) => p.type);

  const setColor = () => {
    if (checkedPermissions?.includes('D')) {
      return '#EC3900';
    } else if (checkedPermissions?.includes('C')) {
      return '#00AC45';
    } else if (checkedPermissions?.includes('U')) {
      return '#00B3EC';
    } else {
      return '#767676';
    }
  };

  return (
    checkedPermissions.length !== 0 && (
      <div
        style={{
          backgroundColor: setColor(),
          minWidth: 70,
          borderRadius: 5,
          textAlign: 'center',
          padding: '1px 6px',
          position: 'absolute',
          fontSize: 12,
          color: 'white',
          height: 18,
        }}>
        {checkedPermissions?.join().replaceAll(',', '/')}
      </div>
    )
  );
};

const PermissionFilter = ({ uuid, category, setPermissionList }) => {
  const [list, setList] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const form = useForm();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (form.watch('permissions')) {
      setPermissionList(form.watch('permissions'));
      setPermissions(form.watch('permissions'));
    }
  }, [form.watch('permissions')]);

  const fetchServiceList = () => {
    fetchApi(`/v1/me/organization/service`, `get`)
      .then((el) => setList(el))
      .catch((err) => console.log('[Line:28 err]', err));
  };

  useEffect(() => {
    fetchServiceList();
  }, []);

  return (
    <>
      <div style={{ display: 'flex', width: '450px', justifyContent: 'space-evenly' }} id='basic-button' aria-controls={open ? 'basic-menu' : undefined} aria-haspopup='true' aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
        {list.map((service, idx) => (
          <div
            key={idx}
            style={{
              // paddingLeft: 38,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}>
            <ServiceIcon organizationService={service.organizationService} permissions={permissions} />
            <PermissionLabel organizationService={service.organizationService} permissions={permissions} />
          </div>
        ))}
      </div>
      <FormProvider {...form}>
        <Menu
          id='basic-menu'
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          <AllPermissionCheckbox serviceList={list} />
        </Menu>
      </FormProvider>
    </>
  );
};

export default PermissionFilter;
