import {useState} from 'react';
import '../../../css/groupManagement/user/userInfoEditModal.css';
import EventAccessCheckBoxes from '../../EventAccessCheckBoxes';
import CompleteAndCloseModal from '../../CompleteAndCloseModal';
import AlertMessage from '../../utilities/AlertMessage';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import CheckboxGroup from '../../CheckboxGroup';
import APIUtils, {fetchApi} from '../../../utils/APIUtils';
import RadioGroup from '../../RadioGroup';
import {USER} from 'anuro-platform-core';
import BaseModal from '../../common/Modal/BaseModal';
import SelectBox from '../../SelectBox';
import Button from '../../Button';
import Input from '../../Input';
import Radio from '../../Radio';

const apiUtils = new APIUtils();

const RegiUserModal = ({ open, setOpen, fetchTable }) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    formState,
    formState: { errors },
    ...rest
  } = useForm({
    defaultValues: {
      type: 'NORMAL',
    },
  });
  const [closeOpen, setCloseOpen] = useState(false);

  const [isUniqueId, setIsUniqueId] = useState(false);

  const onSubmit = (data) => {
    const permissions = [];
    for (const [key, value] of Object.entries(data)) {
      if (key.includes('_permission_')) {
        const [organizationServiceId, type] = key.split('_permission_');
        value &&
          permissions.push({
            organizationServiceId,
            type,
          });
      }
    }

    isUniqueId
      ? fetchApi(`/v1/me/organization/user`, `post`, {
          groupUuid: data.groupUuid?.value,
          name: data.name,
          username: data.userId,
          password: data.password,
          phoneNumber: data.phoneNum,
          permissions: permissions,
          type: data.type,
        })
          .then((res) => {
            AlertMessage.alertMessage('success', '사용자 추가가 완료되었습니다');
            fetchTable();
          })
          .finally(() => {
            setOpen(false);
            reset();
          })
          .catch((err) => console.log('[Line:75 err]', err))
      : AlertMessage.alertMessage('error', '중복체크를 해주세요');
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const DepartmentOptions = () => {
    const departmentsArr = [];
    fetchApi(`/v1/me/organization/group?ob=id&od=DESC`, `get`).then((res) => {
      res.rows.map((e) => {
        return departmentsArr.push({
          value: e?.uuid,
          label: e?.name,
        });
      });
    });

    return (
      <Controller
        name='groupUuid'
        control={control}
        render={({ field }) => {
          return <SelectBox containerWidth={'100%'} label={'그룹'} name={'groupUuid'} width={'100%'} options={departmentsArr} placeholder={'그룹명을 선택해주세요'} {...field} />;
        }}
      />
    );
  };

  const checkDuplication = () => {
    const userId = getValues('userId');
    fetchApi(`/v1/me/organization/user/check`, `post`, { username: userId })
      .then((res) => {
        if (res === 'OK') {
          setIsUniqueId(true);
          AlertMessage.alertMessage('success', '사용 가능한 아이디입니다');
        } else {
          AlertMessage.alertMessage('error', '중복된 아이디입니다');
        }
      })
      .catch((err) => {
        if (err.status === 409) {
          AlertMessage.alertMessage('error', '중복된 아이디입니다');
        }
      });
  };

  return (
    <BaseModal title={'사용자 추가'} open={open} leftBtnTitle={'취소'} rightBtnTitle={'등록'} handleCloseBtn={handleClose} handleLeftBtn={handleClose} handleRightBtn={handleSubmit(onSubmit)} divider>
      <div className='user-edit-container'>
        <div style={{ width: '100%', position: 'relative' }}>
          <FormProvider getValues={getValues} register={register} handleSubmit={handleSubmit} reset={reset} {...rest}>
            <DepartmentOptions />
            {apiUtils.renderLoading()}
            <Input
              width={'100%'}
              name={'name'}
              {...register('name', {
                required: true,
              })}
              errortype={errors?.name?.type}
              label={'사용자 이름'}
              placeholder={'이름을 입력해주세요'}
            />
            <div style={{ display: 'flex' }}>
              <Input
                width={'100%'}
                name={'userId'}
                {...register('userId', {
                  required: true,
                  maxLength: USER.USERNAME_MAX_LENGTH,
                  minLength: USER.USERNAME_MIN_LENGTH,
                })}
                errortype={errors?.userId?.type}
                requirement={{ maxLength: USER.USERNAME_MAX_LENGTH, minLength: USER.USERNAME_MIN_LENGTH }}
                label={'사용자 ID'}
                placeholder={'아이디를 입력해주세요'}
              />
              <Button style={{ marginLeft: 14, height: 40, width: 100 }} variant={'primary'} onClick={checkDuplication}>
                중복체크
              </Button>
            </div>
            <Input
              width={'100%'}
              name={'password'}
              type={'password'}
              {...register('password', {
                required: true,
                maxLength: USER.PASSWORD_MAX_LENGTH,
                minLength: USER.PASSWORD_MIN_LENGTH,
              })}
              errortype={errors?.password?.type}
              requirement={{ maxLength: USER.PASSWORD_MAX_LENGTH, minLength: USER.PASSWORD_MIN_LENGTH }}
              label={'비밀번호'}
              placeholder={'비밀번호를 입력해주세요'}
            />
            <Input width={'100%'} name={'phoneNum'} {...register('phoneNum')} label={'연락처'} placeholder={'ex) 010-1234-1234'} />
            <CheckboxGroup label={'서비스 권한'}>
              <EventAccessCheckBoxes register={register} category={'user'} />
              {/*<PermissionList services={services} permissions={[]} isGroup={false} mode={'all'} />*/}
            </CheckboxGroup>
            <RadioGroup label={'회원등급'}>
              <Radio value={'NORMAL'} name={'NORMAL'} {...register('type')}>
                일반
              </Radio>
              <Radio value={'MASTER'} name={'MASTER'} {...register('type')}>
                마스터
              </Radio>
            </RadioGroup>
          </FormProvider>
        </div>
      </div>
      <CompleteAndCloseModal title={'수정완료'} detail={'이벤트 등록이 정상적으로 처리되었습니다'} open={closeOpen} setOpen={setCloseOpen} />
    </BaseModal>
  );
};

export default RegiUserModal;
