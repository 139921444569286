// 이벤트 상태
import {SERVICE} from "anuro-platform-core";

export const EVENT_STATUS = {
	// OCCUR: {code: "OCCUR", name: "상황발생"},
	STANDBY: {code: "STANDBY", name: "상황발생"},
	IN_PROGRESS: {code: "IN_PROGRESS", name: "진행중"},
	TERMINATED: {code: "TERMINATED", name: "상황종료"},
}

export const EVENT_DEFAULT_ICON = {
	ST331: require("../icons/eventIcon/event-119.png"),    // 3.3.1.119 긴급출동 지원서비스 시스템 연계
	SD332: require("../icons/eventIcon/event-disaster.png"),    // 3.3.2. 긴급재난상황 지원서비스 시스템 연계
	ST333: require("../icons/eventIcon/event-weak.png"),    // 3.3.3. 사회적 약자 지원서비스 시스템 연계
	SH334: require("../icons/eventIcon/event-112.png"),    // 3.3.4. 112 긴급출동 지원서비스 시스템 연계
	ST335: require("../icons/eventIcon/event-112-general.png"),    // 3.3.5. 112 종합상황실 긴급영상 지원서비스 시스템 연계
}

// 진행구분코드 | 2 | 10-발생, 40-정보변경, 50-해제, 90-취소, 91-종료, 99-로그인상태
export const EVENT_PROGRESS_CODE2 = {
	10: {code: 10, name: "발생", icon: ""},
	40: {code: 40, name: "정보변경", icon: ""},
	50: {code: 50, name: "상황해제", icon: ""},
	90: {code: 90, name: "취소", icon: ""},
	91: {code: 91, name: "상황종료", icon: ""},
}

export const EVENT_PROGRESS_CODE = {
	OCCUR: {code: 10, name: "발생", icon: ""},
	MODIFY: {code: 40, name: "정보변경", icon: ""},
	RELEASE: {code: 50, name: "상황해제", icon: ""},
	CANCEL: {code: 90, name: "취소", icon: ""},
	TERMINATED: {code: 91, name: "상황종료", icon: ""},
}


export const EVENT_PROPERTY = {
	HEADER : {
		CLASSIFICATION_CODE: {code: "CLASSIFICATION_CODE", name: "전문구분코드", length: 2},
		PROGRESS_CODE: {code: "PROGRESS_CODE", name: "진행구분코드", length: 2},
		LOGIN_DATE: {code: "LOGIN_DATE", name: "로그인일시", length: 14},
		TRANSMISSION_CODE: {code: "TRANSMISSION_CODE", name: "송신시스템코드", length: 3},
		RECEIVE_CODE: {code: "RECEIVE_CODE", name: "수신시스템코드", length: 3},
		SENDING_DATE: {code: "SENDING_DATE", name: "발송일시", length: 14},
		DATA_LENGTH: {code: "DATA_LENGTH", name: "DATA 길이", length: 10},
	},
	BODY: {
		// !!! 119 긴급출동 지원 서비스 시스템 연계
		[SERVICE.ST331]: {
			TRANSMISSION_NUMBER: {code: "TRANSMISSION_NUMBER", name: "발생번호"},
			EVENT_NAME: {code: "EVENT_NAME", name: "종별명"},
			SECONDARY_EVENT_NAME: {code: "SECONDARY_EVENT_NAME", name: "종별세부명"},
			LONGITUDE: {code: "LONGITUDE", name: "발생위치(경도)"},
			LATITUDE: {code: "LATITUDE", name: "발생위치(위도)"},
			OCCURRENCE_LOCATION: {code: "OCCURRENCE_LOCATION", name: "발생위치"},
			DESIGNATED_LAW_CODE: {code: "DESIGNATED_LAW_CODE", name: "법정동코드시도"},
			OCCURRENCE_DATE: {code: "OCCURRENCE_DATE", name: "발생일시"},
			SENDER_ID: {code: "SENDER_ID", name: "전송자아이디"},
			END: {code: "END", name: "END(세미콜론)"},
		},
		// !!! 긴급재난상황 지원 서비스 시스템 연계
		[SERVICE.SD332]: {},
		// !!! 사회적 약자 지원 서비스 시스템 연계
		[SERVICE.ST333]: {
			NUMBER: {code: "NUMBER", name: "발생번호"},
			SERVICE_TYPE: {code: "SERVICE_TYPE", name: "서비스유형"},
			LONGITUDE: {code: "LONGITUDE", name: "사건지점위치(경도)"},
			LATITUDE: {code: "LATITUDE", name: "사건지점위치(위도)"},
			OCCURRENCE_LOCATION: {code: "OCCURRENCE_LOCATION", name: "사고지점전체주소"},
			DESIGNATED_LAW_CODE: {code: "DESIGNATED_LAW_CODE", name: "지역코드"},
			Ref_ID: {code: "Ref_ID", name: "신고자 ID"},
			NAME: {code: "NAME", name: "이름"},
			PHONE: {code: "PHONE", name: "핸드폰번호"},
			BIRTH: {code: "BIRTH", name: "출생일"},
			GENDER: {code: "GENDER", name: "성별"},
			ADDRESS: {code: "ADDRESS", name: "주소(주거지)"},
			PROTECTOR_NAME: {code: "PROTECTOR_NAME", name: "보호자이름"},
			PROTECTOR_PHONE: {code: "PROTECTOR_PHONE", name: "보호자핸드폰"},
			OCCURRENCE_DATE: {code: "OCCURRENCE_DATE", name: "발생일시"},
			IMAGE: {code: "IMAGE", name: "이미지"},
			PERSONAL_INFORMATION: {code: "PERSONAL_INFORMATION", name: "신상정보"},
			NOTE: {code: "NOTE", name: "특이사항"},
			SENDER_ID: {SENDER_ID: "SENDER_ID", name: "사용자 아이디"},
			END: {code: "END", name: "END(세미콜론)"},
		},
		// !!! 112 긴급출동 지원 서비스 시스템 연계
		[SERVICE.SH334]: {
			EVT_OCR_NO: {code: "EVT_OCR_NO", name: "접수번호"},
			RECEPT_YMD: {code: "RECEPT_YMD", name: "접수일자"},
			MTMDA_PRCS_TY_CD: {code: "MTMDA_PRCS_TY_CD", name: "접수유형"},
			MTMDA_PRCS_STATE: {code: "MTMDA_PRCS_STATE", name: "처리상태"},
			TITLE: {code: "TITLE", name: "제목"},
			IMG_START_YMD_HMS: {code: "IMG_START_YMD_HMS", name: "영상시작시각"},
			IMG_END_YMD_HMS: {code: "IMG_END_YMD_HMS", name: "영상종료시각"},
			POINT_X: {code: "POINT_X", name: "경도"},
			POINT_Y: {code: "POINT_Y", name: "위도"},
			ADRES_NM: {code: "ADRES_NM", name: "위치주소"},
			CONTS: {code: "CONTS", name: "내용"},
			RECEPT_PRCS_YN: {code: "RECEPT_PRCS_YN", name: "수집완료 여부"},
			RECEPT_PRCS_YMD_HMS: {code: "RECEPT_PRCS_YMD_HMS", name: "수집일자"},
			FILE_ID_RETURN_YMD_HMS: {code: "FILE_ID_RETURN_YMD_HMS", name: "파일ID 처리일자"},
			RGS_DATE: {code: "RGS_DATE", name: "등록일자"},
			RECEPT_NM: {code: "RECEPT_NM", name: "접수담당자"},
			RECEPT_TELNO: {code: "RECEPT_TELNO", name: "접수담당자연락처"},
			RECV_NO: {code: "RECV_NO", name: "사건접수번호"},
			UPD_DATE: {code: "UPD_DATE", name: "수정일시"},
		},
		// !!! 112 종합상황실 긴급영상 지원 시스템 연계
		[SERVICE.ST335]: {
			CASE_NUMBER: {code: "CASE_NUMBER", name: "사건번호"},
			ACCIDENT_GRADE: {code: "ACCIDENT_GRADE", name: "사고등급"},
			ACCIDENT_TYPE: {code: "ACCIDENT_TYPE", name: "사고유형"},
			LONGITUDE: {code: "LONGITUDE", name: "발생위치(경도)"},
			LATITUDE: {code: "LATITUDE", name: "발생위치(위도)"},
			OCCURRENCE_LOCATION: {code: "OCCURRENCE_LOCATION", name: "사고지점전체주소"},
			DESIGNATED_LAW_CODE: {code: "DESIGNATED_LAW_CODE", name: "지역코드"},
			OCCURRENCE_DATE: {code: "OCCURRENCE_DATE", name: "사건발생일시"},
			SENDER_ID: {code: "SENDER_ID", name: "사용자 아이디"},
			END: {code: "END", name: "END(세미콜론)"},
		},
	},
	ATTACHMENT: {
		ATT_112: {
			EVT_OCR_NO: {code: "EVT_OCR_NO", name: "접수번호", length: 40},
			FILE_ID: {code: "FILE_ID", name: "파일ID", length: 40},
			IMG_TY_CD: {code: "IMG_TY_CD", name: "파일유형", length: 1},
			IMG_URL: {code: "IMG_URL", name: "파일명", length: 200},
			SEQ_NO: {code: "SEQ_NO", name: "일련번호", length: 10},
		}
	}
}
