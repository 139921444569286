import React, { useEffect, useState } from 'react';
import '../../../css/groupManagement/department/departmentDetailModal.css';
import { ReactComponent as CROWN } from '../../../icons/group/crown_black_icon.svg';
import DeleteDepartmentModal from './DeleteDepartmentModal';
import ProfileIcon from '../../../icons/profile_icon.png';
import EditDepartmentModal from './EditDepartmentModal';
import BaseModal from '../../common/Modal/BaseModal';
import { fetchApi } from '../../../utils/APIUtils';
import Divider from '../../Divider';

const DepartmentDetailModal = ({ open, setOpen, rowInfo, groupUuid, fetchTable, setIsPermissionFetched }) => {
  const [permissions, setPermissions] = useState([]);
  const [serviceLists, setServiceLists] = useState([]);
  const [departmentEditOpen, setDepartmentEditOpen] = useState(false);
  const [departmentDelOpen, setDepartmentDelOpen] = useState(false);
  const margin = 25;

  const fetchPermissions = () => {
    fetchApi(`/v1/me/organization/group/${groupUuid}/permissions`, 'get')
      .then((el) => {
        setPermissions(el);
        return el;
      })
      .then((e) => {
        const serviceListsArr = [];
        e.map((el) => serviceListsArr.push(el.name));
        setServiceLists(serviceListsArr);
      })
      .catch((err) => console.log('[Line:36 err]', err));
  };

  useEffect(() => {
    fetchPermissions();
  }, [groupUuid, rowInfo]);

  const masterUsers = () => {
    let masterUsers = [];
    rowInfo?.users.map((e) => {
      if (e.ThroughGroupUser.master === 1) return masterUsers.push(e.username);
    });
    return masterUsers.join(', ');
  };

  return (
    <BaseModal open={open} leftBtnTitle={'삭제'} rightBtnTitle={'정보 수정'} handleCloseBtn={() => setOpen(false)} handleLeftBtn={() => setDepartmentDelOpen(true)} handleRightBtn={() => setDepartmentEditOpen(true)}>
      <div className='department-detail-modal-container'>
        <div className='header-container'>
          <img className='icon' src={ProfileIcon} alt={'profile'} />
          <div className='department'>{rowInfo?.name}</div>
          <div className='name-container'>
            <div className='master name'>
              <CROWN />
              <div style={{ marginLeft: 8 }}>{masterUsers()}</div>
            </div>
            <div className='name'>&nbsp;· {rowInfo?.users?.length}명</div>
          </div>
        </div>
        <div className='detail-container'>
          <Divider marginTop={margin} marginBottom={margin} />
          <div className='each-detail'>
            <div className='title'>이벤트 권한</div>
            <div className={'list'}>{serviceLists?.join()}</div>
          </div>
          <Divider marginTop={margin} marginBottom={margin} />
        </div>
      </div>
      <EditDepartmentModal open={departmentEditOpen} setOpen={setDepartmentEditOpen} rowInfo={rowInfo} groupUuid={groupUuid} fetchPermissions={fetchPermissions} fetchTable={fetchTable} setIsPermissionFetched={setIsPermissionFetched} />
      <DeleteDepartmentModal permissions={serviceLists} open={departmentDelOpen} setOpen={setDepartmentDelOpen} detailOpen={setOpen} rowInfo={rowInfo} groupUuid={groupUuid} fetchTable={fetchTable} />
    </BaseModal>
  );
};

export default DepartmentDetailModal;
