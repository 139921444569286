import { forwardRef } from 'react';
import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLInputElement> {
  label?: string;
}

const Checkbox = forwardRef(({ label, name, ...props }: Props, ref) => {
  const isMoreThanTwoChar = label.length > 2;
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,
    marginBottom: 25,
    width: props.width ? props.width : 'auto',
    textAlign: 'left',
    ...props.containerstyle,
  };
  const labelStyle = {
    width: isMoreThanTwoChar ? 120 : 60,
    cursor: 'pointer',
    fontSize: props.fontSize ? props.fontSize : 'auto',
    ...props.labelstyle,
  };

  return (
    <div style={containerStyle}>
      <input name={name} id={name + '-id'} type={'checkbox'} {...props} ref={ref} />
      <label style={labelStyle} htmlFor={name + '-id'}>
        {label}
      </label>
    </div>
  );
});

export default Checkbox;
