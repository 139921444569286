import React from "react";
import {HiMinus, HiPlus} from "react-icons/hi";
import APIUtils from "../../utils/APIUtils";
import {IFacilityCctv} from "anuro-platform-core";

type Props = {
	cctv: IFacilityCctv
}

export default class CCTVControlComponent extends React.Component<Props, any> {

	apiUtils = new APIUtils();
	onMouseDown = (key) => {
		if(!this.props.cctv.onvifAuthId) return null;
		this.apiUtils.fetchApi(`/v1/cctv/${this.props.cctv.id}/action/move`, "post", {key});
	}
	onMouseUp = () => {
		if(!this.props.cctv.onvifAuthId) return null;
		this.apiUtils.fetchApi(`/v1/cctv/${this.props.cctv.id}/action/stop`, "post");
	}

	renderNotSupported = () => {
		return (
			<div style={{position: "absolute", width: 100, height: "100%", backgroundColor: "#000000AA", zIndex: 1, borderRadius: 10, color: "#FFFFFF", fontSize: 14, fontWeight: "bold", textAlign: "center", display: "flex", alignItems: "center"}}>
				<div>지원하지 않는<br/>기기입니다</div>
			</div>
		)
	}

	render() {
		return (
				<div className={'control'} style={{position: "relative"}}>
					{!this.props.cctv.onvifAuthId && this.renderNotSupported()}
					<div className={'direction'}>
						<div className={'title'}>DIRECTION</div>
						<div className={'direction-control'}>
							<div className={'control-btn'} style={{ borderRadius: '5px', margin: '0 5px 5px 0' }}>
								{/*<img src={require('../../icons/arrow2.png')} />*/}
							</div>
							<div className={'control-btn'} style={{ borderRadius: '5px 5px 0 0', height: 33 }} onMouseDown={() => this.onMouseDown("up")} onMouseUp={this.onMouseUp}>
								<img src={require('../../icons/arrow1.png')} />
							</div>
							<div className={'control-btn'} style={{ borderRadius: '5px', margin: '0 0 5px 5px' }}>
								{/*<img src={require('../../icons/arrow2.png')} />*/}
							</div>
							<div className={'control-btn'} style={{ borderRadius: '5px 0 0 5px', width: 33 }} onMouseDown={() => this.onMouseDown("left")} onMouseUp={this.onMouseUp}>
								<img src={require('../../icons/arrow1.png')} />
							</div>
							<div className={'control-btn center disabled'} style={{ padding: 2, boxSizing: 'content-box' }}></div>
							<div className={'control-btn'} style={{ borderRadius: '0 5px 5px 0', width: 33 }} onMouseDown={() => this.onMouseDown("right")} onMouseUp={this.onMouseUp}>
								<img src={require('../../icons/arrow1.png')} />
							</div>
							<div className={'control-btn'} style={{ borderRadius: '5px', margin: '2px 5px 0 0' }}>
								{/*<img src={require('../../icons/arrow2.png')} />*/}
							</div>
							<div className={'control-btn'} style={{ borderRadius: '0 0 5px 5px', height: 31 }} onMouseDown={() => this.onMouseDown("down")} onMouseUp={this.onMouseUp}>
								<img src={require('../../icons/arrow1.png')} />
							</div>
							<div className={'control-btn'} style={{ borderRadius: '5px', margin: '2px 0 0 5px' }}>
								{/*<img src={require('../../icons/arrow2.png')} />*/}
							</div>
						</div>
					</div>
					<div className={'divide'} />
					<div className={'zoom'}>
						<div className={'title'}>ZOOM</div>
						<div className={'zoom-control'}>
							<div className={'control-btn'} style={{ borderRadius: '5px 0 0 5px' }} onMouseDown={() => this.onMouseDown("zoom out")} onMouseUp={this.onMouseUp}>
								<HiMinus size={14} />
							</div>
							<div className={'control-btn'} style={{ borderRadius: '0 5px 5px 0' }} onMouseDown={() => this.onMouseDown("zoom in")} onMouseUp={this.onMouseUp}>
								<HiPlus size={14} />
							</div>
						</div>
					</div>
				</div>

		)
	}
}
