import '../../../css/groupManagement/department/departmentRegiModal.css';
import EventAccessCheckBoxes from '../../EventAccessCheckBoxes';
import AlertMessage from '../../utilities/AlertMessage';
import CheckboxGroup from '../../CheckboxGroup';
import { FormProvider, useForm } from 'react-hook-form';
import BaseModal from '../../common/Modal/BaseModal';
import APIUtils from '../../../utils/APIUtils';
import Input from '../../Input';

const RegiDepartmentModal = ({ open, setOpen, fetchTable }) => {
  const { register, handleSubmit, reset, ...rest } = useForm();
  const apiUtils = new APIUtils();

  const onSubmit = (data) => {
    const permissions = [];
    for (const [key, value] of Object.entries(data)) {
      if (key.includes('_permission_')) {
        const [organizationServiceId, type] = key.split('_permission_');
        value &&
          permissions.push({
            organizationServiceId,
            type,
          });
      }
    }

    apiUtils
      .fetchApi(`/v1/me/organization/group`, 'post', {
        name: data.name,
        permissions: permissions,
      })
      .then((res) => {
        setOpen(false);
        AlertMessage.alertMessage('success', '생성이 성공적으로 완료되었습니다');
        fetchTable();
        reset();
      })
      .catch((err) => console.log('[Line:26 err]', err));
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  return (
    <BaseModal title={'신규 그룹 등록'} open={open} rightBtnTitle={'등록'} leftBtnTitle={'취소'} handleRightBtn={handleSubmit(onSubmit)} handleLeftBtn={handleClose} handleCloseBtn={handleClose} divider>
      <FormProvider register={register} handleSubmit={handleSubmit} reset={reset} {...rest}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='modal-department-regi-container'>
            <Input name={'name'} {...register('name')} label={'그룹명'} placeholder={'그룹명을 입력해주세요'} />
            <CheckboxGroup labelwidth={140} label={'서비스 권한'}>
              <EventAccessCheckBoxes category={'group'} />
            </CheckboxGroup>
          </div>
        </form>
      </FormProvider>
      {apiUtils.renderLoading()}
    </BaseModal>
  );
};

export default RegiDepartmentModal;
