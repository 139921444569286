import { useState } from 'react';

const useSelect = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const onChangeSelect = (e) => {
    if (!e) {
      setValue([]);
      return;
    }
    const targetValue = e.value;
    setValue(targetValue);
  };

  return { value, onChange: onChangeSelect };
};

export default useSelect;
