import React from 'react';
import {SETTING_PANEL, SETTING_TAB} from './SettingModal';
import APIUtils from '../../utils/APIUtils';
import {IUser} from 'anuro-platform-core';
import {Tooltip} from '@mui/material';
import AlertMessage from '../utilities/AlertMessage';
import ObjectUtils from "../../utils/ObjectUtils";
import {inject} from "mobx-react";
import SessionStore from "../../stores/SessionStore";

export enum EDIT_MODE {
  NORMAL,
  CREATE,
  EDIT,
  DELETE,
}

type Props = {
  user: IUser;
  handleData: Function;
  fetchUserInformation: Function;
  sessionStore: SessionStore;
  updateUserInfo: Function;
};

type States = {
  user: IUser;
  mode: EDIT_MODE,
};

const styles = {
  item: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: "space-between",
      margin: "2px 0 20px 2px",
      width: "calc(100% - 4px)",
      borderRadius: 10,
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.35)',
      backgroundColor: "#FFFFFF",
      padding: "15px 20px",
    } as React.CSSProperties,
    input: {
      width: "calc(100% - 80px)",
      borderLeft: "1px solid #ccc",
      textAlign: "right",
      backgroundColor: "#FFF"
    } as React.CSSProperties
  },
};

@inject("sessionStore")
export default class ModifyInformationPanelComponent extends React.Component<Props, States> {
  apiUtils = new APIUtils();
  state = {
    user: {...this.props.user},
    mode: EDIT_MODE.NORMAL,
  };

  goBack = async () => {
    if(!ObjectUtils.areObjectsSame(this.state.user, this.props.sessionStore?.user || {})) {
      const result = await AlertMessage.confirmMessage("바뀐 정보가 있습니다. 그래도 뒤로 가시겠습니까?");
      if(result) {
        this.props.updateUserInfo(JSON.parse(JSON.stringify(this.props.sessionStore.user)));
        this.props.handleData(SETTING_PANEL.PROFILE, SETTING_TAB.PROFILE);
      }
    } else {
      this.props.handleData(SETTING_PANEL.PROFILE, SETTING_TAB.PROFILE);
    }
  }

  // !!! 사용자 이미지 변경
  updateUserProfile = (file: any) => {
    let formData = new FormData();
    formData.append('image', file);
    this.apiUtils.fetchApi('/v1/me', 'patch', { formData }).then((r) => {
      AlertMessage.alertMessage('success', '프로필 사진이 수정되었습니다');
      let user: IUser = { ...this.state.user };
      user.profile && (user.profile.imageUrl = URL.createObjectURL(file));
      this.setState({ user });
      this.props.fetchUserInformation();
    });
  };

  onChange = (key: string, value: any) => {
    this.setState((prevState): any => {
      // @ts-ignore
      const nestedObject = { ...prevState };
      const keys = key.split('.');
      let object = nestedObject;
      for (let i = 0; i < keys.length - 1; i++) {
        object = object[keys[i]];
      }
      object[keys[keys.length - 1]] = value;
      return { key: nestedObject };
    });
  }

  renderProfileItem = (item: IUser, label: string, key: string) => {
    return (
      <div style={styles.item.container}>
        <span>{label}</span>
        <input disabled={this.state.mode === EDIT_MODE.NORMAL} style={styles.item.input} value={ObjectUtils.getObjectByString(item, key) || ""} onChange={(e) => {this.onChange("user." + key, e.target.value)}} />
      </div>
    )
  }

  onSubmit = () => {
    if(this.state.mode === EDIT_MODE.EDIT && !ObjectUtils.areObjectsSame(this.state.user, this.props.sessionStore?.user || {})) {
      this.apiUtils.fetchApi("/v1/me", "patch", this.state.user.profile).then(r => {
        AlertMessage.alertMessage('success', "정보가 성공적으로 수정되었습니다");

        this.props.sessionStore.setUser(JSON.parse(JSON.stringify(this.state.user)));
        this.props.updateUserInfo(JSON.parse(JSON.stringify(this.state.user)));
      });
    }
    this.setState({"mode": this.state.mode === EDIT_MODE.NORMAL ? EDIT_MODE.EDIT : EDIT_MODE.NORMAL});
  }

  render() {
    const { user } = this.state;
    return (
      <div className={'right'} style={{ position: 'relative' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 20,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <div className={'cursor'} onClick={this.goBack}>
            <span style={{ marginRight: 10 }}>&#60;</span>
            정보수정
          </div>
          <div className={`txt-btn ${!ObjectUtils.areObjectsSame(this.state.user, this.props.sessionStore?.user || {}) && "active"}`} onClick={this.onSubmit}>{this.state.mode === EDIT_MODE.NORMAL ? "수정" : "저장"}</div>
        </div>
        <div style={{ position: 'relative', height: 'calc(100% - 36px)', overflowY: 'auto' }}>
          <input type={'file'} id={'file'} style={{ display: 'none' }} onChange={(e: any) => this.updateUserProfile(e.target.files[0])} />
          <div className={'service-icon-container'}>
            <label htmlFor='file'>
              <Tooltip title={'아이콘을 눌러 변경하세요'} placement={'top'} arrow>
                {this.state.user.profile?.imageUrl ? (
                  <img className={'cursor'} src={this.state.user.profile.imageUrl} />
                ) : (
                  <div className={'no-img cursor'}>
                    <span>+</span>
                  </div>
                )}
              </Tooltip>
            </label>
            <div style={{ marginTop: 30 }}>
              {this.renderProfileItem(user, "이름", "profile.name")}
              {this.renderProfileItem(user, "전화번호", "profile.phoneNumber")}
              {this.renderProfileItem(user, "직통번호", "profile.directNumber")}
              {this.renderProfileItem(user, "내선번호", "profile.extensionNumber")}
              {this.renderProfileItem(user, "이메일", "profile.email")}
              {this.renderProfileItem(user, "주소", "profile.addressStreet")}
              {this.renderProfileItem(user, "상세주소", "profile.addressStreetDetail")}
              {this.renderProfileItem(user, "자기소개", "profile.introduce")}
            </div>
          </div>
        </div>
        {this.apiUtils.renderLoading()}
      </div>
    );
  }
}
