import React from 'react';
import {api_tta} from '../../api/api_tta';
import {withRouter} from 'react-router-dom';
import {StorageUtils} from '../../utils/StorageUtils';
import {WSC} from '../../service/WSC';
import {logWithTs, USER} from 'anuro-platform-core';
import moment from 'moment';
import ChangePasswordModal from './ChangePasswordModal';
import {SETTING_PANEL, SETTING_TAB} from './SettingModal';
import TransferAccountModal from './TransferAccountModal';
import {ReactComponent as PROFILE} from "../../icons/setting/profile.svg";
import {ReactComponent as PASSWORD_CHANGE} from "../../icons/setting/password-change.svg";
import {ReactComponent as LAST_LOG} from "../../icons/setting/last-log.svg";
import {ReactComponent as PERMISSION} from "../../icons/setting/permission.svg";
import {ReactComponent as LOGOUT} from "../../icons/setting/logout.svg";

class SettingProfilePanelComponent extends React.Component {
  state = {
    isPasswordChangeModalOpen: false,
    isTransferAccountModalOpen: false,
  };
  // !!! 로그아웃
  logout = async () => {
    try {
      const res = await api_tta.delete('/v1/auth/session');
      if (res.ok) {
        logWithTs('세션삭제 성공');
      } else {
        logWithTs('세션삭제 실패', res);
      }
    } catch (err) {
      logWithTs(err);
    }

    // 브라우저에 저장된 JWT 토큰 삭제
    StorageUtils.removeAuthorization();

    // API 인스턴스에 저장된 Authorization 삭제
    api_tta.deleteHeader('Authorization');

    // Web Socket Client 연결 끊기
    WSC.getInstance().stop(true);

    try {
      typeof this.props.sessionStore?.reset === 'function' && this.props.sessionStore?.reset();
    } catch (e) {}

    this.props.history.push('/sign-in');
  };

  handleModal = (modal, value) => this.setState({ [modal]: value });

  render() {
    const { userProfile, handleData } = this.props;
    return (
      <div className={'right profile'} style={{ position: 'absolute' }}>
        <div className={'item cursor hover-opacity hover-shadow'} onClick={() => handleData(SETTING_PANEL.PROFILE_MODIFY_INFORMATION, SETTING_TAB.PROFILE)}>
          <PROFILE />
          <div className={'text-container'}>
            <div>정보수정</div>
          </div>
        </div>
        <div className={'item cursor hover-opacity hover-shadow'} onClick={() => this.handleModal('isPasswordChangeModalOpen', true)}>
          <PASSWORD_CHANGE />
          <div className={'text-container'}>
            <div>비밀번호 변경</div>
            <div className={'small-txt'}>마지막으로 변경한 날짜: {userProfile?.passwordHistories ? moment(userProfile.passwordHistories[0]?.createdAt).format('YYYY.MM.DD HH:mm:ss') : ''}</div>
          </div>
        </div>
        <div className={'item cursor hover-opacity hover-shadow'} onClick={() => handleData(SETTING_PANEL.PROFILE_LOGIN_HISTORY, SETTING_TAB.PROFILE)}>
          <LAST_LOG />
          <div className={'text-container'}>
            <div>로그인 기록</div>
            <div className={'small-txt'}>마지막으로 로그인한 날짜: {userProfile?.authenticationLogs ? moment(userProfile.authenticationLogs[0]?.createdAt).format('YYYY.MM.DD HH:mm:ss') : ''}</div>
          </div>
        </div>
        {userProfile.type === USER.TYPE.ACCOUNT_HOLDER && (
          <div className={'item cursor hover-opacity hover-shadow'} onClick={() => this.handleModal('isTransferAccountModalOpen', true)}>
            <PERMISSION />
            <div className={'text-container'}>
              <div>소유자 계정 이전</div>
              <div className={'small-txt'}>조직 내 다른 계정으로 소유자 권한을 이전합니다</div>
            </div>
          </div>
        )}
        <div className={'item cursor hover-opacity hover-shadow'} onClick={this.logout}>
          <LOGOUT />
          <div className={'text-container'}>
            <div>로그아웃</div>
          </div>
        </div>
        {this.state.isPasswordChangeModalOpen && <ChangePasswordModal title='비밀번호 변경' onClose={() => this.handleModal('isPasswordChangeModalOpen', false)} />}
        {this.state.isTransferAccountModalOpen && <TransferAccountModal title='소유자 계정 이전' fetchUserInformation={this.props.fetchUserInformation} accountProfile={userProfile} onClose={() => this.handleModal('isTransferAccountModalOpen', false)} />}
      </div>
    );
  }
}

export default withRouter(SettingProfilePanelComponent);
