import { useEffect, useState } from 'react';
import '../css/myPage/userManage.css';
import CommonHeader from '../components/HeaderComponent';
import APIUtils from '../utils/APIUtils';
import Container from '../components/Container';
import Table from '../components/Table';
import {ReactComponent as REFRESH} from "../icons/refresh2.svg";
import "../css/statistics.css";

const apiUtils = new APIUtils();

const tableNames = {
  ELIKMAAWS10M: {en: 'ELIKMAAWS10M', ko: '기상정보AWS'},
  ELIHRFRFHR: {en: 'ELIHRFRFHR', ko: '우량시단위'},
  ELIKHCACC: {en: 'ELIKHCACC', ko: '고속도로특별상황관리'},
  ELIFOAFORESTMAP: {en: 'ELIFOAFORESTMAP', ko: '산불발생정보'},
  ELIKMADFSSHRT: {en: 'ELIKMADFSSHRT', ko: '동네예보'},
  ELIKMAEARTHINFM: {en: 'ELIKMAEARTHINFM', ko: '지진현황'},
  ELIRTSAOCCURID: {en: 'ELIRTSAOCCURID', ko: '시가지도로돌발상황정보'},
  ELINEMEMRE: {en: 'ELINEMEMRE', ko: '응급복구장비'},
  ELIKMAINFORM: {en: 'ELIKMAINFORM,', ko: '기상특보'},
  ELINEMFIRS: {en: 'ELINEMFIRS', ko: '소방서'},
}

const REFRESH_STATUS = {
  NOT_START: "자동갱신",
  START: "5초 뒤 갱신",
  ING: "갱신중...",
  FAILED: "갱신실패",
}

const Disaster = () => {
  const [datas, setDatas] = useState({});
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedTable, setSelectedTable] = useState("ELIKMAAWS10M");


  const [refreshTxt, setRefreshTxt] = useState(REFRESH_STATUS.NOT_START);
  const [isAutoRefresh, setIsAutoRefresh] = useState(false);

  let refreshStatus = REFRESH_STATUS.NOT_START;
  let timer = null;

  useEffect(() => {
    fetchDisasterData();
    return (() => {
      timer && clearInterval(timer);
    });
  }, []);

  useEffect(() => {
    fetchRows(datas);
  }, [selectedTable]);

  const fetchDisasterData = () => {
    if(refreshStatus === REFRESH_STATUS.ING) {
      setRefreshTxt(REFRESH_STATUS.ING);
      clearInterval(timer);
    }
    apiUtils.fetchApi("/v1/me/organization/disaster", "get", {
      od: "DESC"
    }).then((r) => {
      setTimeout(() => {
        setDatas(r);
        fetchRows(r);
        if(refreshStatus === REFRESH_STATUS.ING) {
          refresh();
        }
      }, 1000);
    }).catch(err => {
      if(refreshStatus === REFRESH_STATUS.ING) {
        setRefreshTxt(REFRESH_STATUS.FAILED);
        setTimeout(() => {
          setIsAutoRefresh(false);
          setRefreshTxt(REFRESH_STATUS.NOT_START);
        }, 2000);
      }
    });
  }

  const fetchRows = (data) => {
    if(data[selectedTable]?.length > 0) {
      const objKeys = Object?.keys(data[selectedTable][0]);
      let tempColumns = [{field: "no", headerName: "no"}];
      objKeys.map((i, idx) => {
        tempColumns.push({ field: i, headerName: i, minWidth: 150, width: objKeys[idx]?.toString().length > data[selectedTable][0][i]?.toString()?.length ? objKeys[idx]?.toString().length * 25 : data[selectedTable][0][i]?.toString()?.length * 25 || 100 });
        // tempColumns.push({ field: i, headerName: i, width: 'auto'});
      });
      setColumns(tempColumns);
      setRows(data[selectedTable].map((i, idx) => {
        return {no: idx + 1, ...i}
      }));
    }
  }

  const renderSelect = () => {
    return Object.keys(tableNames).map((i, idx) => {
      return <option key={idx} value={i}>{tableNames[i].ko} ({i})</option>;
    });
  };

  useEffect(() => {
    if(isAutoRefresh) {
      refreshStatus = REFRESH_STATUS.ING
      refresh();
    } else {
      setRefreshTxt(REFRESH_STATUS.NOT_START);
      timer = null;
    }
    return () => {
      clearInterval(timer);
    }
  }, [isAutoRefresh]);

  const refresh = () => {
    let cnt = 5;
    setRefreshTxt(cnt-- + "초 뒤 갱신");
    timer = setInterval(() => {
      setRefreshTxt(cnt-- + "초 뒤 갱신");
      if(cnt === -1) {
        refreshStatus = REFRESH_STATUS.ING;
        fetchDisasterData();
      }
    }, 1000);
  }

  return (
    <Container>
      <CommonHeader pageTitle={'Department'} />

      <div className='user-container disaster-container'>
        <div className='content'>
          <div className='right'>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
              <select style={{ padding: 10, borderRadius: 4, width: 300 }} value={selectedTable} onChange={e => {
                setIsAutoRefresh(false)
                setRows([]);
                setSelectedTable(e.target.value);
              }}>
                {renderSelect()}
              </select>
              <div
                onClick={() => {
                  fetchDisasterData();
                  setIsAutoRefresh(!isAutoRefresh);
                }}
                style={{color: "white", width: 135, borderRadius: 5, backgroundColor: refreshTxt === REFRESH_STATUS.NOT_START ? "#496DED" : refreshTxt === REFRESH_STATUS.FAILED ? "#EC3900" :"#1C4F7E", cursor: "pointer", display: "flex", flexDirection: "row", alignItems: "center", padding: "10px 12px"}}>
                <REFRESH className={refreshTxt === "갱신중..." ? "indicator run" : "indicator stop"} style={{marginRight: 10}}/>{refreshTxt}
              </div>
            </div>
            <div style={{ width: '100%', height: '90%', position: 'relative' }}>
              <Table
                columns={columns}
                rows={rows}
                unlimited
                getRowId={(row: any) => row.no}
                componentsProps={{
                  row: {
                    style: {
                      background: 'linear-gradient(90.12deg, #313644 0.02%, #1E2530 79.25%)',
                      fontWeight: 'bold',
                      borderRadius: 10,
                      marginBottom: 10,
                    },
                  },
                }}
              />
              {apiUtils.renderLoading()}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Disaster;
