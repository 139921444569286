import React from 'react';
import moment from 'moment';
import AddressInput from '../AddressInput';
import {MODE_LIST} from './EventListPanelComponent';
import {toJS} from 'mobx';
import EventEmitter from '../../utils/EventEmitter';
import APIUtils from '../../utils/APIUtils';
import {IEventCategory, IService, IUser, WSSEvent} from 'anuro-platform-core';
import {Autocomplete, TextField} from '@mui/material';
import AlertMessage from "../utilities/AlertMessage";
import {WSC} from "../../service/WSC";
import HandlingSocketData, {EXECUTE_TYPE} from "../utilities/HandlingSocketData";

export default class EventWriteComponent extends React.Component {
  emitter = EventEmitter.getInstance();
  apiUtils = new APIUtils();

  state = {
    serviceList: [],
    categoryList: [],
    userList: [],

    isChanged: false,

    selectBoxValue: {serviceList: {}, categoryList: {}, userList: {}},
    form: {
      title: "",
      issuedAt: 0,
      code: '',
      status: 'STANDBY',
      message: '',
      latitude: 0,
      longitude: 0,
      addressStreet: "",
      addressStreetDetail: "",
      organizationEventCategoryId: 0,
      chargeUserUuid: '',

      facilityCctvSnapshotUuid: "",
      capturedImageList: [],
    },
  };

  componentDidMount() {
    this.fetchServiceList();
    this.fetchUserList();

    WSC.getInstance().socket.on(WSSEvent[WSSEvent.FACILITY_CCTV_SNAPSHOT_DEL], this.onWSSSnapshot);
  }

  componentWillUnmount() {
    WSC.getInstance().socket.off(WSSEvent[WSSEvent.FACILITY_CCTV_SNAPSHOT_DEL]);
  }

  onWSSSnapshot = (snapshot) => {
    const result = HandlingSocketData.update(EXECUTE_TYPE.DEL, this.state.form.capturedImageList, snapshot, "id");
    console.log(result);
    this.setState({
      form: {
        ...this.state.form,
        capturedImageList: result || []
      }
    });
  }

  // !!! 사용자 정보 api 호출
  fetchUserList = () =>
    this.apiUtils.fetchApi('/v1/me/organization/user', 'get', { ob: 'id', od: 'DESC' }).then((r) => {
      this.setState({ userList: r.rows, form: {...this.state.form, chargeUserUuid: r.rows[0].uuid}, selectBoxValue: {...this.state.selectBoxValue, userList: r.rows[0]} });
    });
  // !!! 서비스 목록 api 호출
  fetchServiceList = () => {
    this.apiUtils.fetchApi('/v1/me/organization/service', 'get').then((r) => {
      this.setState({
        serviceList: r,
        selectBoxValue: {...this.state.selectBoxValue, serviceList: r[0]},
        form: {
          ...this.state.form, code: r[0].code,
          title: r[0]?.organizationService?.name
        }
      });
      this.fetchCategoryList(r[0]);
    });
  };
  // !!! 서비스 카테고리 api 호출
  fetchCategoryList = (service: IService) => {
    const parsedService = typeof service === 'string' ? JSON.parse(service) : service;
    this.apiUtils.fetchApi(`/v1/me/organization/service/${parsedService.organizationService.id}/event-category`, 'get').then((r: IEventCategory[]) => {
      this.setState({
        selectBoxValue: {...this.state.selectBoxValue, serviceList: service, categoryList: r[0]},
        form: {
          ...this.state.form,
          code: parsedService.code,
          organizationEventCategoryId: r[0].organizationEventCategory.id,
          ...(!this.state.isChanged && {title: service?.organizationService?.name})
        },
        categoryList: r,
      });
    });
  };

  addCapturedImage = (imageList) => {
    let tempCapturedImageList = [];

    imageList.map(i => {
      const idx = this.state.form.capturedImageList.findIndex(j => j.uuid === i.uuid);
      if(idx === -1) tempCapturedImageList.push(i);
    });

    tempCapturedImageList = [...toJS(this.state.form.capturedImageList), ...tempCapturedImageList];

    this.setState({
      form: {
        ...this.state.form,
        capturedImageList: tempCapturedImageList,
        facilityCctvSnapshotUuid: tempCapturedImageList?.map(i => i.uuid) || []
      }
    });
  };

  renderEmpty = () => (
    <div className={'event-write-photo-empty'}>
      <img src={require('../../icons/attachment.png')} style={{ marginBottom: 15 }} />
      <div style={{ marginBottom: 2 }}>CCTV 상세화면 또는 캡쳐함에서</div>
      <div>사진을 선택해 첨부해 주세요</div>
    </div>
  );

  // !!! 캡쳐보관함에 있는 이미지 가져오는 함수
  renderPhotoItem = () =>
    this.state.form.capturedImageList.map((i , index) => (
      <div className={'event-write-photo-item'} key={index}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ marginRight: 14 }}>{i.name}</div>
        </div>
        <div style={{ fontSize: 20, color: '#FFFFFF', cursor: 'pointer' }} onClick={() => this.deleteImage(i)}>
          &times;
        </div>
      </div>
    ));

  deleteImage = (item) => {
    let tempCapturedImageList = [...toJS(this.state.form.capturedImageList)];
    const index = tempCapturedImageList.findIndex((i) => i.id === item.id);
    if (index > -1) {
      tempCapturedImageList.splice(index, 1);
    }
    this.setState({
      form: {
        ...this.state.form,
        capturedImageList: [...tempCapturedImageList],
        facilityCctvSnapshotUuid: tempCapturedImageList.map(i => i.uuid)
      },
    });
  };

  changeListMode = () => {
    this.emitter.emit('removeMarker', { markerType: 'search' });
    this.props.changeEventListMode(MODE_LIST);
  };

  onChange = (key, value) => {
    this.setState({
      ...(key === "title" && {isChanged: true}),
      form: {
        ...this.state.form,
        [key]: value,
      }
    });
  }

  onSelectChange = (key, value, dataSet, options) => {
    this.setState({
      selectBoxValue: {
        ...this.state.selectBoxValue,
        [dataSet]: options,
      },
      form: {
        ...this.state.form,
        [key]: value,
      }
    });
  }

  // !!! 주소 검색 반환 함수
  setAddress = (address) => {
    this.setState({ form: {
      ...this.state.form,
        latitude: address.latitude,
        longitude: address.longitude,
        addressStreet: address.roadAddr,
      }
    });
  };

  // !!! 신규 이벤트 작성
  onSubmit = () => {
    if (!this.state.form.addressStreet) return AlertMessage.alertMessage("info", "주소를 검색해주세요");
    this.setState({
      form: {...this.state.form, issuedAt: moment().unix()}
    }, () => {
      this.apiUtils.fetchApi(`/v1/me/organization/event/${this.state.selectBoxValue.serviceList.organizationService.id}`, "post", {...this.state.form}).then(r => {
        this.props.setMessageBox('신규 이벤트가 생성되었습니다');
        this.props.fetchEventList();
        this.changeListMode();
      })
    })
  };

  // !!! 셀렉트 컴포넌트 렌더링
  renderAutoComplete = (dataSet, labelProperty, key) => {
    if(this.state[dataSet]?.length === 0) return null;
    return (
      <Autocomplete
        disablePortal
        className={'event-write-input'}
        options={this.state[dataSet]}
        getOptionLabel={(option: IUser) => {
          if(dataSet === "serviceList") return option["organizationService"][labelProperty] || [];
          if(dataSet === "userList") return option?.profile?.name + "(" + option["username"] + ")";
          else return option[labelProperty] || [];
        }}
        value={this.state.selectBoxValue[dataSet]}
        sx={{ display: 'inline-block', padding: "0 !important"}}
        onChange={(e, option) => {
          if (key === "code") { // service 선택 시
            this.onChange(key, option["code"]);
            this.fetchCategoryList(option);
          } else if (key === "organizationEventCategoryId") { // category 선택 시
            this.onSelectChange(key, option["organizationEventCategory"]["id"], dataSet, option);
          } else if (key === "chargeUserUuid") { // user 선택 시
            this.onSelectChange(key, option["uuid"], dataSet, option);
          }
        }}
        renderInput={(params) => {
          return <TextField
            {...params}
            variant="standard"
            InputProps={{...params.InputProps, disableUnderline: true, style: {height: 33}}}
            inputProps={{...params.inputProps, style: {color: '#FFFFFF', paddingLeft: 15}}}
          />;
        }}
      />
    )
  }

  render() {
    return (
      <div className={'event-write-container'}>
        <div className={'event-write-title'}>신규 이벤트 작성</div>
        <div className={'event-write-time'}>{moment().format('YYYY년 M월 D일')}</div>
        <input placeholder={'서비스 제목을 입력해주세요'} value={this.state.form.title} onChange={e => this.onChange("title", e.target.value)} className={'event-write-input'} />
        <AddressInput placeholder={'주소를 입력해주세요'} cn={'event-write-input'} width={'100%'} scrollType={'scroll-b'} inputStyle={{ border: 'none' }} resultPanelStyle={{ maxHeight: 430, bigFontSize: 16, smallFontSize: 13 }} onChange={this.setAddress} />
        <div style={{marginBottom: 10}}/>
        <input placeholder={'상세주소를 입력해주세요'} onChange={e => this.onChange("addressStreetDetail", e.target.value)} className={'event-write-input'} />
        {/*!!! 서비스*/}
        {this.renderAutoComplete("serviceList", "name", "code")}
        {/*!!! 서비스 카테고리*/}
        {this.renderAutoComplete("categoryList", "name", "organizationEventCategoryId")}
        {/*!!! 담당자*/}
        {this.renderAutoComplete("userList", "username", "chargeUserUuid")}

        <textarea className={'event-write-input scroll-b'} onChange={(e) => this.onChange('message', e.target.value)} placeholder={'상황내용을 알려주세요'} />

        <div className={'event-write-photo scroll-b'} style={{ border: this.state.form.capturedImageList?.length === 0 ? '1px dashed #8088A0' : 'none' }}>
          {this.state.form.capturedImageList?.length === 0 && this.renderEmpty()}
          {this.renderPhotoItem()}
        </div>

        <button className={'submit'} onClick={this.onSubmit}>
          전송하기
        </button>
        <button className={'cancel'} onClick={this.changeListMode}>
          취소
        </button>
        {this.apiUtils.renderLoading()}
      </div>
    );
  }
}
