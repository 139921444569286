import React from 'react';
import Chart from 'react-apexcharts';
import {TextField} from '@mui/material';
import moment from 'moment';
import APIUtils from '../../utils/APIUtils';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import koLocale from 'date-fns/locale/ko';

export const localeMap = {
  ko: {
    ...koLocale,
    localize: {
      month: koLocale?.localize?.month || [],
      day: koLocale?.localize?.day || [],
      amPM: ['오전', '오후'],
    },
  },
};

type Props = {
  type: 'monthly' | 'daily';
};

export default class ProgressChart extends React.Component<Props, any> {
  apiUtils = new APIUtils();

  state = {
    series: [],
    startDate: '',
    endDate: '',
    options: {},
  };

  constructor(props: any) {
    super(props);

    // @ts-ignore
    this.state = {
      // @ts-ignore
      startDate: this.props.type === 'daily' ? moment().subtract(6, 'days').format('YYYY.MM.DD') : moment().subtract(2, 'months').format('YYYY.MM'),
      endDate: this.props.type === 'daily' ? moment().format('YYYY.MM.DD') : moment().format('YYYY.MM'),
      options: {
        chart: {
          backgroundColor: '#313644',
          type: 'line',
          toolbar: {
            show: false,
          },
          dropShadow: {
            enabled: true,
            top: 2,
            left: 2,
            blur: 1,
            opacity: 0.5,
          },
        },
        colors: ['#FF0000', '#F66627', '#14C1A2', '#3874EA', '#FFF32A'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        markers: {
          size: 5,
          strokeColors: 'transparent',
        },
        xaxis: {
          labels: {
            style: {
              colors: '#FFFFFF',
            },
            formatter: (val: any) => {
              return val;
              // return moment(val).format("YY-MM")
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: ['#FFFFFF'],
            },
            formatter: (val: number) => {
              return val.toFixed(0);
            },
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          // floating: true,
          // offsetY: 100,
          // offsetX: -5,
          labels: {
            colors: '#FFFFFF',
          },
        },
      },
    };
  }

  componentDidMount() {
    this.fetchChartData();
  }

  fetchChartData = () => {
    this.apiUtils
      .fetchApi(`/v1/me/organization/event/statistics/${this.props.type}`, 'get', {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
      .then((r: any) => {
        this.setState({ series: r });
      });
  };

  onChange = (key: string, value: string) => {
    this.setState({ [key]: value }, () => {
      this.fetchChartData();
    });
  };

  render() {
    console.log(this.state.startDate, this.state.endDate);
    return (
      <>
        <div className={'progressChart'}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 10,
              justifyContent: 'space-between',
            }}>
            <div
              style={{
                fontSize: 14,
                color: '#FFFFFF',
                marginBottom: 10,
                fontWeight: 'bold',
              }}>
              {this.props.type === 'daily' ? '일별' : '월별'}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap['ko']}>
              <DatePicker
                InputProps={{
                  style: {backgroundColor: "#313644", width: this.props.type == "daily" ? 160 : 140, color: "#FFF", borderRadius: 8},
                }}
                views={this.props.type === 'daily' ? ['day'] : ['year', 'month']}
                disableFuture
                inputFormat={this.props.type == "daily" ? 'yyyy.MM.dd' : 'yyyy.MM'}
                value={this.state.startDate}
                onChange={(newValue) => this.onChange('startDate', newValue || "")}
                renderInput={(params) => <TextField {...params} />}
              />
                <span style={{ color: '#727894', fontWeight: 'bold', margin: '0 10px' }}>~</span>
                <DatePicker
                  InputProps={{
                    style: {backgroundColor: "#313644", width: this.props.type == "daily" ? 160 : 140, color: "#FFF", borderRadius: 8},
                  }}
                  views={this.props.type === 'daily' ? ['day'] : ['year', 'month']}
                  disableFuture
                  inputFormat={this.props.type == "daily" ? 'yyyy.MM.dd' : 'yyyy.MM'}
                  value={this.state.startDate}
                  onChange={(newValue) => this.onChange('endDate', newValue || "")}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: '#313644', padding: '30px 20px 0 20px', borderRadius: 8, position: 'relative' }}>
          <Chart key={Math.random()} options={this.state.options} series={this.state.series} type='line' width={'100%'} height={350} />
          {this.apiUtils.renderLoading()}
        </div>
      </>
    );
  }
}
