import React from 'react';
import Address from '../models/Address';
import EventEmitter from '../utils/EventEmitter';
import {ReactComponent as SEARCH} from "../icons/search.svg";

export default class AddressInput extends React.Component {
  emitter = EventEmitter.getInstance();

  state = {
    address: this.props.defaultValue || '',
    isSearched: false,
    result: [],
    isTyping: false,
  };

  onChangeHandler = (e) => {
    clearTimeout(this.endTyping);
    this.setState(
      {
        isTyping: true,
        address: e.target.value,
      },
      () => {
        if (this.state.isTyping) {
          this.endTyping = setTimeout(() => this.searchAddress(), 300);
        }
      },
    );
  };

  onKeyPress = (e) => {
    if (e.keyCode === 13) {
      this.searchAddress();
    } else if (e.keyCode === 8) {
      clearTimeout(this.endTyping);
    }
  };

  searchAddress = () => {
    this.setState(
      {
        isTyping: false,
      },
      () => {
        Address.searchAddress(this.state.address).then((res) => {
          let a = res;
          if (this.state.address.length === 0) a = [{ roadAddr: '주소를 상세히 입력해주세요' }];
          this.setState({
            result: a,
            isSearched: true,
          });
        });
      },
    );
  };

  selectAddress = (item: Address) => {
    this.setState({
      isSearched: false,
      address: item.road_address_name,
    });

    this.props.onChange &&
        this.props.onChange({
          ...item,
          roadAddr: item.road_address_name,
          latitude: item.y,
          longitude: item.x,
        });
      this.emitter.emit('moveTo', { lat: item.y, lng: item.x, withMarker: true });
    // });
  };

  render() {
    return (
      <div
        className={'address-input'}
        style={{
          position: 'relative',
          width: this.props.width,
        }}>
        <input
          style={{
            border: this.props.inputStyle?.border ? this.props.inputStyle : '1px solid #DDDDDD',
            borderRadius: this.state.isSearched && this.state.address.length > 0 ? `${this.props.radius}px ${this.props.radius}px 0 0` : this.props.radius,
            boxSizing: 'border-box',
            padding: this.props?.padding,
            paddingRight: this.props.searchIcon ? 50 : 'inherit',
            width: '100%',
            height: this.props?.height,
          }}
          className={this.props.cn}
          value={this.state.address}
          placeholder={this.props.placeholder}
          onChange={this.onChangeHandler}
          onKeyDown={(e) => this.onKeyPress(e)}
          onClick={() => this.setState({ isSearched: false })}
        />
        {this.props.searchIcon && (
          <div
            style={{
              position: 'absolute',
              right: 15,
              top: this.state.address?.length > 0 ? '55%' : '52%',
              transform: 'translate(0, -50%)',
            }}>
            {this.state.address.length > 0 ? (
              <img
                src={require('../icons/btn_serch_reset.png')}
                onClick={() => {
                  this.setState({ address: '' });
                  this.props.clearMarker('search');
                }}
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <SEARCH onClick={this.searchAddress} style={{ cursor: 'pointer' }}/>
            )}
          </div>
        )}
        {this.state.isSearched && this.state.address.length > 0 && (
          <div
            className={`results ${this.props.scrollType || 'a'}`}
            style={{
              width: '100%',
              position: 'absolute',
              top: this.props.height,
              zIndex: 100,
              backgroundColor: this.props.darkTheme ? '#3F4452' : '#FFFFFF',
              maxHeight: this.props?.resultPanelStyle?.maxHeight || 500,
              minHeight: this.props.height || '100%',
              boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
              borderRadius: '0 0 10px 10px',
              border: this.props.inputStyle?.border ? this.props.inputStyle : '1px solid #CFCFCF',
              borderTop: 'none',
              overflowY: 'auto',
            }}>
            {this.state.result.length > 0 &&
              this.state.result.map((item: Address, index) => (
                <div
                  className={this.props.darkTheme ? 'dark-theme' : 'white-theme'}
                  onClick={() => this.selectAddress(item)}
                  key={index}
                  style={{
                    minHeight: this.props.height,
                    padding: this.props?.resultPanelStyle?.padding || '15px 10px',
                    borderBottom: this.state.result.length === 1 || this.state.result.length - 1 == index ? 'none' : '1px solid #EEE',
                    display: 'flex',
                    alignItems: 'flex-start',
                    fontSize: this.props.fontSize || this.props.resultPanelStyle?.bigFontSize || 16,
                  }}>
                  {item.road_address_name !== '주소를 상세히 입력해주세요' && <img src={require('../icons/marker/marker3-b.png')} style={{ margin: '0 3px' }} />}
                  <div>
                    <div style={{ marginBottom: 5, padding: '0 5px', color: '#222222' }}>{item?.road_address_name}</div>
                    <div
                      style={{
                        fontSize: this.props.resultPanelStyle?.smallFontSize || 13,
                        color: '#AAA',
                        padding: '0 5px',
                      }}>
                      {item?.address_name}
                    </div>
                    <div
                      style={{
                        fontSize: this.props.resultPanelStyle?.smallFontSize || 13,
                        color: '#AAA',
                        padding: '5px 5px 0 5px',
                      }}>
                      {item?.place_name}
                    </div>
                  </div>
                </div>
              ))}
            {this.state.result.length === 0 && (
              <div
                style={{
                  minHeight: this.props.height,
                  padding: '20px 15px 15px 15px',
                  borderBottom: '1px solid #EEE',
                }}>
                <div
                  style={{
                    fontSize: this.props.fontSize || this.props.resultPanelStyle?.bigFontSize || 16,
                    padding: '0 5px',
                  }}>
                  {'검색 결과가 없습니다'}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
