import React from "react";
import {Tooltip} from "@mui/material";

const styles = {
	bottom_news_container: {
		width: "100%",
		padding: "22px 15px 22px 30px",
		display: "flex",
		justifyContent: "space-between"
	},
	news_text: {
		fontSize: 14,
		color: "#7C8495"
	},
	version_text: {
		fontSize: 13,
		color: "#696C73",
	}
}
export default class GISBottomComponent extends React.Component {

	render() {
		return (
				<div style={styles.bottom_news_container}>
					<div style={styles.news_text}>
						NEWS | 국토부 국토교통과학기술진흥원, 수소도시 콘퍼런스 개최
					</div>
					<div style={styles.version_text}>
						Updates Ver. 0.8. 2022
						<Tooltip title={"소켓 연결상태: 정상"} placement={"top"} arrow>
							<span style={{marginLeft: 10, color: "#78D700"}}>●</span>
						</Tooltip>
					</div>
				</div>
		)
	}
}
