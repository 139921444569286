import React from "react";

export default class ImageButtonWithText extends React.Component {

	render() {
		const {id, onClick, isCheck, text, style} = this.props;
		return (
			<div id={id} onClick={onClick} style={{display: "flex", flexDirection: "column", textAlign: "center", backgroundColor: isCheck ? '#496DED' : '#FFFFFF', ...style}}>
				{
					this.props.img?.toString().includes("data:image") ?
						<img src={this.props.img} /> : this.props.img
				}
				<span style={{fontSize: 10, marginTop: 5, color: isCheck ? 'white' : 'black'}}>{text}</span>
			</div>
		)
	}
}
