import { useEffect } from 'react';
import AlertMessage from '../../utilities/AlertMessage';
import { Controller, useForm } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { fetchApi } from '../../../utils/APIUtils';
import Input from '../../Input';
import moment from 'moment';

const SmsSendComponent = ({ rowInfo, setTabValue }) => {
  const { handleSubmit, reset, control, register, watch } = useForm();
  const messageContent = watch('message');

  useEffect(() => {
    const status = (() => {
      switch (rowInfo[0]?.status) {
        case 'STANDBY':
          return '상황발생';
        case 'IN_PROGRESS':
          return '처리중';
        case 'TERMINATED':
          return '상황종료';
        default:
          return '';
      }
    })();

    reset({
      message: rowInfo[0]?.organizationEventCategory.eventCategory.service.name ? rowInfo[0]?.organizationEventCategory.eventCategory.service.name + '(' + rowInfo[0]?.code + ')' + ' ' + status + ' 상태' : null,
    });
  }, [rowInfo]);

  const clickSendBtn = (data) => {
    fetchApi(`/v1/message-service`, `post`, {
      temp: 0,
      message: data.message,
      to: data.to.map((e) => e.value),
      eventUuid: rowInfo[0] ? rowInfo[0].uuid : undefined,
    })
      .then(() => {
        AlertMessage.alertMessage('success', '전송이 성공적으로 완료되었습니다');
        setTabValue('2');
      })
      .catch((err) => console.log('[Line:27 err]', err));
  };

  const clickTempSaveBtn = (data) => {
    fetchApi(`/v1/message-service`, `post`, {
      temp: 1,
      message: data.message,
      to: data.to.map((e) => e.value),
      eventUuid: rowInfo[0] ? rowInfo[0].uuid : undefined,
    })
      .then((res) => {
        AlertMessage.alertMessage('success', '임시저장이 성공적으로 완료되었습니다');
        setTabValue('3');
      })
      .catch((err) => console.log('[Line:27 err]', err));
  };

  const ReceiverOptions = () => {
    return (
      <Controller
        name='to'
        control={control}
        render={({ field }) => {
          return <CreatableSelect isMulti className='select' name={'to'} placeholder={'전화번호를 입력해주세요'} {...field} />;
        }}
      />
    );
  };

  function getBytes(str) {
    let character;
    let charBytes = 0;

    for (let i = 0; i < str?.length; i += 1) {
      character = str?.charAt(i);

      if (character.length > 4) charBytes += 2;
      else charBytes += 1;
    }

    return charBytes;
  }

  return (
    <div className='sms-send-container'>
      <div className='each-row'>
        <div className='title'>이벤트</div>
        {rowInfo[0]?.organizationEventCategory.eventCategory.service.name ? (
          <div>
            <div style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 6 }}>
              {rowInfo[0]?.organizationEventCategory.eventCategory.service.name}({rowInfo[0]?.organizationEventCategory.eventCategory.name})
            </div>
            <div style={{ fontSize: 14, color: '#666666', marginBottom: 4 }}>
              발생위치: {rowInfo[0]?.addressStreet} {rowInfo[0]?.addressStreetDetail}
            </div>
            <div style={{ fontSize: 14, color: '#666666', marginBottom: 4 }}>
              <span style={{ marginRight: 10 }}>이벤트 ID: {rowInfo[0]?.code}</span>
            </div>
            <div style={{ fontSize: 14, color: '#666666' }}>
              <span>발생일시: {moment(rowInfo[0]?.issuedAt).format('YY-MM-DD HH:mm:ss')}</span>
            </div>
          </div>
        ) : (
          '미지정'
        )}
      </div>
      <div className='each-row'>
        <div className='title'>수신자</div>
        <ReceiverOptions />
      </div>
      <Input width={'100%'} textarea height={120} label={'내용'} placeholder='상세내용을 입력해주세요' {...register('message')} />
      <div className='btn-container'>
        <button className='btn-save btn' onClick={handleSubmit(clickTempSaveBtn)}>
          임시저장
        </button>
        <button className='btn-send btn' onClick={handleSubmit(clickSendBtn)}>
          보내기
        </button>
      </div>
    </div>
  );
};

export default SmsSendComponent;
