import { useEffect } from 'react';
import '../../../css/groupManagement/user/userInfoEditModal.css';
import EventAccessCheckBoxes from '../../EventAccessCheckBoxes';
import AlertMessage from '../../utilities/AlertMessage';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import CheckboxGroup from '../../CheckboxGroup';
import APIUtils, { fetchApi } from '../../../utils/APIUtils';
import RadioGroup from '../../RadioGroup';
import BaseModal from '../../common/Modal/BaseModal';
import SelectBox from '../../SelectBox';
import Input from '../../Input';
import Radio from '../../Radio';
import PermissionList from '../../Permission/PermissionList';

const apiUtils = new APIUtils();

const EditInfoModal = ({ open, setOpen, rowInfo, fetchTable, fetchPermission, setIsPermissionFetched }) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    ...rest
  } = useForm();

  const radioDefault = rowInfo?.groups && rowInfo?.groups[0]?.ThroughGroupUser?.master === 1 ? 'MASTER' : 'NORMAL';

  const selectDefault = rowInfo?.groups
    ? {
        label: rowInfo.groups && rowInfo.groups[0]?.name,
        value: rowInfo.groups && rowInfo.groups[0]?.uuid,
      }
    : null;

  useEffect(() => {
    reset({
      groupUuid: selectDefault,
      name: rowInfo?.profile?.name,
      userId: rowInfo?.username,
      phoneNum: rowInfo?.profile?.phoneNumber,
      type: radioDefault,
    });
  }, [rowInfo]);

  const onSubmit = (data) => {
    const permissions = [];
    for (const [key, value] of Object.entries(data)) {
      if (key.includes('_permission_')) {
        const [organizationServiceId, type] = key.split('_permission_');
        value &&
          permissions.push({
            organizationServiceId,
            type,
          });
      }
    }

    fetchApi(`/v1/me/organization/user/${rowInfo?.uuid}`, `patch`, {
      name: data.name,
      permissions: permissions,
      password: data.password,
      groupUuid: data?.groupUuid?.value,
      type: data.type,
      phoneNumber: data.phoneNum,
    })
      .then((res) => {
        AlertMessage.alertMessage('success', '수정이 완료되었습니다');
        setIsPermissionFetched(true);
        fetchTable();
        fetchPermission();
        setOpen(false);
      })
      .finally(() => {
        setIsPermissionFetched(false);
      })
      .catch((err) => console.log('[Line:75 err]', err));
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const DepartmentOptions = () => {
    const departmentsArr = [];
    fetchApi(`/v1/me/organization/group?ob=id&od=DESC`, `get`)
      .then((res) => {
        res.rows.map((e) => {
          return departmentsArr.push({
            value: e?.uuid,
            label: e?.name,
          });
        });
      })
      .catch((err) => console.log('[Line:83 err]', err));

    return (
      <Controller
        name='groupUuid'
        control={control}
        render={({ field }) => {
          return (
            <div>
              <SelectBox isClearable defaultValue={selectDefault} label={'그룹'} containerWidth={'100%'} name={'groupUuid'} width={'100%'} options={departmentsArr} placeholder={'그룹명을 선택해주세요'} {...field} />
            </div>
          );
        }}
      />
    );
  };

  return (
    <BaseModal title={'사용자 정보 수정'} open={open} leftBtnTitle={'취소'} rightBtnTitle={'등록'} handleCloseBtn={handleClose} handleLeftBtn={handleClose} handleRightBtn={handleSubmit(onSubmit)} divider>
      <div className='user-edit-container'>
        <FormProvider register={register} handleSubmit={handleSubmit} reset={reset} {...rest}>
          {apiUtils.renderLoading()}
          <DepartmentOptions />
          <Input
            width={'100%'}
            name={'name'}
            {...register('name', {
              required: true,
            })}
            errortype={errors?.name?.type}
            label={'사용자 이름'}
            placeholder={'이름을 입력해주세요'}
          />
          <Input width={'100%'} name={'userId'} {...register('userId')} label={'사용자 ID'} placeholder={'아이디를 입력해주세요'} disabled />
          <Input width={'100%'} name={'password'} type={'password'} {...register('password')} label={'비밀번호'} placeholder={'비밀번호를 입력해주세요'} />
          <Input width={'100%'} name={'phoneNum'} {...register('phoneNum')} label={'연락처'} placeholder={'연락처를 입력해주세요'} />
          <CheckboxGroup label={'서비스 권한'}>
            <EventAccessCheckBoxes register={register} category={'user'} groupUuid={rowInfo?.uuid} />
            {/*<PermissionList isGroup={false} mode={'all'} uuid={rowInfo.uuid} />*/}
          </CheckboxGroup>
          <RadioGroup label={'회원등급'}>
            <Radio value={'NORMAL'} name={'NORMAL'} {...register('type')}>
              일반
            </Radio>
            <Radio value={'MASTER'} name={'MASTER'} {...register('type')}>
              마스터
            </Radio>
          </RadioGroup>
        </FormProvider>
      </div>
    </BaseModal>
  );
};

export default EditInfoModal;
