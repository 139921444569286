import React, {createRef} from 'react';
import CCTVViewModal from './CCTVViewModal';
import {inject, observer} from 'mobx-react';
import moment from 'moment/moment';
import {CCTV_STATUS_AVAILABLE, CCTV_STATUS_UNAVAILABLE} from '../../stores/CCTVStore';
import EventEmitter from '../../utils/EventEmitter';
import {loadPlayer} from 'rtsp-relay/browser';
import APIUtils from '../../utils/APIUtils';
import {api_tta} from '../../api/api_tta';
import ImageUtils from '../../utils/ImageUtils';
import {ReactComponent as CAPTURE} from '../../icons/camera.svg';
import {ReactComponent as VIDEO_OFF} from '../../icons/video-off.svg';
import {logWithTs} from "anuro-platform-core";

@inject('cctvStore')
@observer
export default class CCTVSideComponent extends React.Component {
  emitter = EventEmitter.getInstance();
  apiUtils = new APIUtils();
  cctvCanvasRef = [];

  intervalTimer = null;

  playerList = [];

  state = {
    isOpenCCTV: [],
    captureImg: [],
  };

  componentDidMount() {
    this.emitter.on('fetchCCTVList', this.fetchCCTVList);
    this.emitter.on('openCCTVModal', ({ item, open }) => this.CCTVModalHandler(item, open));

    this.fetchCCTVList();
  }

  componentWillUnmount() {
    this.emitter.off('fetchCCTVList');
    this.emitter.off('openCCTVModal');
    try {
      this.playerList.map((i) => {
      try {
        i?.destroy?.();
        logWithTs('CCTVViewModal rtsp player destroyed');
      } catch (e) { logWithTs('Failed to destroy rtsp player', e) }
    });
    } catch (err) {
      console.log("[CCTVSideComponent.js] player destroy catch => ", err);
    }

    clearInterval(this.intervalTimer);
  }

  // !!! CCTV 목록 호출 함수
  fetchCCTVList = () => {
    this.playerList = [];
    this.intervalTimer = null;
    this.props.cctvStore.workingCCTVList = [];
    this.apiUtils.fetchApi('/v1/me/organization/facility', 'get').then((r) => {
      this.props.cctvStore.setCCTVList = r.rows.map((i) => {
        this.cctvCanvasRef.push(createRef());
        return { ...i, click: '', isOpen: false };
      });
      this.emitter.emit('headerButtonHandler', { key: 'cctvBtn', value: this.props.cctvStore.cctvList.length > 0 });
      this.emitter.emit('onChangeMarkerData', { key: 'cctvList', value: this.props.cctvStore.cctvList });

      this.renderRTSPCCTV();
      // this.intervalTimer = setInterval(() => {
      //   this.renderRTSPCCTV();
      // }, 10000);
    });
  };

  CCTVModalHandler = (item, open) => {
    let temp = [...this.props.cctvStore.getCCTVList];

    const idx = temp.findIndex((i) => i.uuid === item.uuid);
    if (idx > -1) {
      let isOpen = open !== undefined ? open : !temp[idx]?.isOpen;

      temp[idx] = {
        ...item,
        isOpen,
      };

      this.props.cctvStore.setCCTVList = [...temp];
      if (!isOpen) return;
      this.emitter.emit('moveTo', { lat: item.latitude, lng: item.longitude });
    }
  };

  renderRTSPCCTV = () => {
    this.props.cctvStore.getCCTVList.map((i, index) => {
      if (!this.cctvCanvasRef[index].current) return null;
      const facilityCCTV = i.facilityCctv;
      if (this.props.cctvStore.workingCCTVList.includes(i.uuid)) return;
      loadPlayer({
        url: `${api_tta.getBaseURL().replace('http', 'ws')}/stream/${facilityCCTV.id}?stream=${facilityCCTV.rtspPath}`,
        canvas: this.cctvCanvasRef[index]?.current,
        preserveDrawingBuffer: true,
        onDisconnect: () => {
          let workingCCTVList = [...this.props.cctvStore.workingCCTVList];
          const idx = workingCCTVList.findIndex((j) => {
            return j === i.uuid;
          });
          if (idx > -1) {
            workingCCTVList.splice(idx, 1);
            this.props.cctvStore.setWorkingCCTVList = workingCCTVList;
            this.emitter.emit('addCCTVMarker');
          }
        },
      }).then((r) => {
        let workingCCTVList = [...this.props.cctvStore.workingCCTVList];
        const idx = workingCCTVList.findIndex((j) => {
          return j === i.uuid;
        });
        if (idx > -1) return;
        workingCCTVList.push(i.uuid);
        this.props.cctvStore.setWorkingCCTVList = workingCCTVList;
        this.emitter.emit('addCCTVMarker');

        this.playerList.push(r);
      });
    });
  };

  onCapture = (item, index) => {
    if (!this.cctvCanvasRef[index].current) return null;

    const img = this.cctvCanvasRef[index].current.toDataURL('image/jpeg', 1);
    const formData = new FormData();
    formData.append('ts', moment().unix());
    formData.append('image', ImageUtils.dataURLtoFile(img));

    this.apiUtils.fetchApi(`/v1/me/organization/facility/cctv/${item.facilityCctv.id}/snapshot`, 'post', { formData }).then((r) => {
      this.emitter.emit('fetchCapturedImage');
    });
    this.props.cctvStore.fetchCapturedImage();
  };

  renderCCTVItems = () =>
    this.props.cctvStore.getCCTVList.map((item, index) => {
      return (
        <div className={'view-item hover-transform-3'} key={item.uuid}>
          <div style={{ position: 'relative' }}>
            <div className={'view'} onClick={() => this.CCTVModalHandler(item)}  style={{ border: item.click && '1px solid #455DDF' }}>
              <div className={item.active === CCTV_STATUS_UNAVAILABLE ? 'no-video' : ''} style={{ position: 'relative', width: '100%', height: '100%' }}>
                <div
                  style={{
                    width: '100%',
                    zIndex: 1,
                    fontSize: 11,
                    color: '#FFFFFF',
                    position: 'absolute',
                    bottom: 0,
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <div
                    style={{
                      border: '1px solid #FFFFFF',
                      padding: '2px 5px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}>
                    <span
                      style={{
                        marginRight: 3,
                        color: item.active ? (!this.props.cctvStore.workingCCTVList.includes(item.uuid) ? '#999999' : '#78D700') : '#f65440',
                      }}>
                      ●
                    </span>
                    {item.name}
                  </div>
                  {item.active === CCTV_STATUS_AVAILABLE && this.props.cctvStore.workingCCTVList.includes(item.uuid) && (
                    <div key={item.cctvId} onClick={(e) => {
                      e.stopPropagation();
                      this.onCapture(item, index);
                    }}>
                      <CAPTURE />
                    </div>
                  )}
                </div>
                <div style={{ width: '100%', height: '100%' }}>
                  {item.active === CCTV_STATUS_AVAILABLE ? (
                    <>
                      {!this.props.cctvStore.workingCCTVList.includes(item.uuid) && (
                        <div
                          style={{
                            borderRadius: 4,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '1px solid #656565',
                            fontSize: 12,
                            color: '#AAAAAA',
                            backgroundColor: '#FFFFFF30',
                            flexDirection: 'column',
                            position: 'absolute',
                          }}>
                          <VIDEO_OFF color={''} />
                          <p style={{ marginTop: 7, fontSize: 12, textAlign: 'center' }}>
                            스트림 없음
                            <br />
                            No stream available
                          </p>
                        </div>
                      )}
                      <canvas
                        key={`panel-cctv-screen-${item.uuid}`}
                        id={`panel-cctv-screen-${item.uuid}`}
                        ref={this.cctvCanvasRef[index]}
                        className={!this.props.cctvStore.workingCCTVList.includes(item.uuid) ? 'cctv-canvas-display-none' : 'cctv-canvas-display-block'}
                        style={{ objectFit: 'fill', width: '100%', height: '100%' }}
                      />
                    </>
                  ) : (
                    <div
                      style={{
                        borderRadius: 10,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}>
                      <VIDEO_OFF />
                      <p style={{ marginTop: 7, fontSize: 12, textAlign: 'center', lineHeight: 1.2 }}>
                        시설물이 비활성 상태입니다
                        <br />
                        Facility is disabled
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

  renderEmpty = () => (
    <div className={'view-item'} style={{ minWidth: 255, color: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      CCTV 목록이 없습니다
    </div>
  );

  render() {
    const { cctvStore } = this.props;
    return (
      <>
        <div className={this.props.open ? 'cctv-monitoring-bar open' : 'cctv-monitoring-bar close'}>
          {cctvStore.cctvList.length > 0 && <div className={'cctv-list hide-scroll'}>{this.renderCCTVItems()}</div>}
          {this.apiUtils.renderLoading()}
        </div>
        {cctvStore.cctvList.length === 0 && this.renderEmpty()}
        {cctvStore.getCCTVList.map((item, index) => {
          if (!item.isOpen) return null;
          const isWorking = this.props.cctvStore.workingCCTVList.includes(item.uuid);
          return <CCTVViewModal key={index} index={index} selectedCCTV={item} modalHandler={this.CCTVModalHandler} isWorking={isWorking} fetchCCTVList={this.fetchCCTVList} />;
        })}
      </>
    );
  }
}
