import {useEffect, useState} from 'react';
import '../css/event.css';
import {ReactComponent as EVENT_ICON} from '../icons/eventIcon/event_icon2.svg';
import EventDetailModal from '../components/event/EventDetailModal';
import {SERVICE, SocketParser, WSSEvent} from 'anuro-platform-core';
import {ReactComponent as SMS_SEND} from '../icons/sms_blue.svg';
import ConfirmDialogModal from '../components/ConfirmDialogModal';
import EventEditModal from '../components/event/EventEditModal';
import AlertMessage from '../components/utilities/AlertMessage';
import {eventStatusOptions} from '../constants/SelectOptions';
import CommonHeader from '../components/HeaderComponent';
import APIUtils, {fetchApi} from '../utils/APIUtils';
import SMSModal from '../components/event/SMSModal';
import 'react-datepicker/dist/react-datepicker.css';
import {EVENT_STATUS} from '../constants/Event';
import Container from '../components/Container';
import SelectBox from '../components/SelectBox';
import Tooltip from '@mui/material/Tooltip';
import useSelect from '../hooks/useSelect';
import DatePicker from 'react-datepicker';
import useInput from '../hooks/useInput';
import Table from '../components/Table';
import Input from '../components/Input';
import {WSC} from '../service/WSC';
import moment from 'moment';
import DeleteBtn from '../components/Table/DeleteBtn/DeleteBtn';
import RightBtn from '../components/Table/RightBtn/RightBtn';

const apiUtils = new APIUtils();
const PAGE_LIMIT = 6;

const Event = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [curPage, setCurPage] = useState(1);

  const [detailOpen, setDetailOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [smsOpen, setSmsOpen] = useState(false);
  const [smsCompleteOpen, setSmsCompleteOpen] = useState(false);
  const [smsDeleteOpen, setSmsDeleteOpen] = useState(false);
  const [eventQuiteOpen, setEventQuiteOpen] = useState(false);
  const [eventEditOpen, setEventEditOpen] = useState(false);

  const [selectedRowData, setSelectedRowData] = useState();

  // !!! Filtering
  const statusSelection = useSelect([]);
  const typeSelection = useSelect([]);
  const searchInput = useInput('');

  // !!! table handle
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [checkedRows, setCheckedRows] = useState([]);

  const fetchedData = rows.find((row) => row.uuid === selectedRowData?.uuid);

  // !!! 맵이 렌더링 되고 처음 시작
  useEffect(() => {
    fetchEventList();
    WSC.getInstance().socket.on(WSSEvent[WSSEvent.EVENT_ADD], onEventAdd);
    return () => {
      WSC.getInstance().socket.off(WSSEvent[WSSEvent.EVENT_ADD], onEventAdd);
    };
  }, [statusSelection.value, typeSelection.value, startDate, endDate, searchInput.value]);

  const onEventAdd = (event) => {
    console.log('[Line:74 event]', event);
    AlertMessage.eventResponseMessage(event);
    setRows((pre) => {
      return [...pre, event].sort((a, b) => b.id - a.id);
    });
  };

  const handleRowDoubleClick = (params) => {
    setDetailOpen(true);
    setSelectedRowData(params.row);
  };

  // !!! 이벤트 목록 호출 함수
  const fetchEventList = (e = null, p = 1) => {
    setCurPage(p);
    apiUtils
      .fetchApi('/v1/me/organization/event', 'get', {
        limit: PAGE_LIMIT,
        offset: p ? (p - 1) * PAGE_LIMIT : 0,
        ob: 'id',
        od: 'DESC',
        status: statusSelection.value,
        startTs: moment(startDate)?.startOf('day')?.unix() || undefined,
        endTs: moment(endDate)?.endOf('day')?.unix() || undefined,
        organizationServiceId: typeSelection.value,
        q: searchInput.value,
      })
      .then((r) => {
        setRows(r.rows);
        setTotalCount(r.count);
      });
  };

  const eventColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
    },
    {
      field: 'code',
      headerName: '코드',
      flex: 1.5,
    },
    {
      field: 'issuedAt',
      headerName: '발생일시',
      flex: 1,
      valueGetter: (params) => moment(params.value).format('YY-MM-DD HH:mm:ss'),
    },
    {
      field: 'addressStreet',
      headerName: '위치',
      flex: 2,
      renderCell: (params) => (
        <div>
          <div>{params.value}</div>
          <div style={{ color: '#8088A0', fontSize: 12 }}>
            {params.row.latitude}/{params.row.longitude}
          </div>
        </div>
      ),
    },
    {
      field: 'event_type',
      headerName: '이벤트 종류',
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.organizationEventCategory?.organizationService?.name}>
            <img src={params.row.organizationEventCategory?.organizationService?.iconUrl} alt={'icon'} className={'event-type'} />
          </Tooltip>
        );
      },
    },
    {
      field: 'event_category',
      headerName: '유형',
      valueGetter: (params) => params.row.organizationEventCategory?.eventCategory.name,
    },
    {
      field: 'in_charge_id',
      headerName: '전송자/담당자',
      flex: 2,
      valueGetter: (params) => {
        const parsingData = params?.row.raw ? SocketParser.parse(SERVICE[params?.row.organizationEventCategory?.eventCategory?.service?.code], params.row.raw) : '';
        const sender = parsingData?.body?.SENDER_ID || params.row?.reportUser?.username;
        const inCharge = params.row?.chargeUser?.username || '미배정';
        return `${sender} / ${inCharge}`;
      },
    },
    {
      field: 'status',
      headerName: '상태',
      flex: 1,
      renderCell: (params) => {
        const eventStatus = EVENT_STATUS[params.value].name;
        if (eventStatus === '상황발생') {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <EVENT_ICON />
              <div style={{ color: 'red', marginLeft: 4, fontWeight: 'bold' }}>{eventStatus}</div>
            </div>
          );
        } else if (eventStatus === '진행중') {
          return <div style={{ marginLeft: 4, fontWeight: 'bold' }}>{eventStatus}</div>;
        }
        return <div style={{ marginLeft: 4, fontWeight: 'bold', color: '#8088A0' }}>{eventStatus}</div>;
      },
    },
  ];

  // !!!
  const handleDeleteBtn = () => {
    if (checkedRows.length < 1) return AlertMessage.alertMessage('error', '삭제할 이벤트를 선택해 주세요');
    const deleteEventList = rows.map((i) => {
      if (checkedRows.includes(i.id)) return i;
    });
    setSelectedRowData(deleteEventList);
    setDeleteOpen(true);
  };

  const handleSmsSendBtn = () => {
    if (checkedRows.length > 1) return AlertMessage.alertMessage('error', '두개 이상의 이벤트는 선택하실 수 없습니다');
    setSmsOpen(true);
  };

  const eventTypeOptions = () => {
    const typesArr = [];
    fetchApi(`/v1/me/organization/service`, `get`).then((res) => {
      res.map((e) => {
        return typesArr.push({
          value: e?.organizationService.id,
          label: e?.name,
        });
      });
    });
    return typesArr;
  };

  return (
    <Container>
      <CommonHeader pageTitle={'Event'} />
      <div className='event-container'>
        <div className='content'>
          <div className='right'>
            <h3 className='header'>이벤트 내역</h3>
            <div className='search-title'>조회기간</div>
            <div className='function-container'>
              <div className={'left-side'}>
                <div className='date-container'>
                  <div>
                    <DatePicker placeholderText='시작일' isClearable className='date-picker' selected={startDate} onChange={(date) => setStartDate(date)} selectsStart startDate={startDate} endDate={endDate} />
                  </div>
                  <div className='wave'>~</div>
                  <div>
                    <DatePicker placeholderText='종료일' isClearable className='date-picker' selected={endDate} onChange={(date) => setEndDate(date)} selectsEnd endDate={endDate} minDate={startDate} />
                  </div>
                </div>
                <div className='search-container'>
                  <SelectBox width={200} isClearable onChange={typeSelection.onChange} variant={'primary'} options={eventTypeOptions()} placeholder={'이벤트 종류'}></SelectBox>
                  <SelectBox isClearable onChange={statusSelection.onChange} variant={'primary'} options={eventStatusOptions} placeholder={'상태'}></SelectBox>
                  <Input value={searchInput.value} onChange={searchInput.onChange} type={'text'} variant={'primary'} width={300} placeholder={'전송자/담당자 ID 검색'} />
                </div>
              </div>
              <RightBtn onClick={handleSmsSendBtn} className={''}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <SMS_SEND style={{ marginRight: 6 }} />
                  <span style={{ fontWeight: 'bold', fontSize: 14 }}>SMS 전송</span>
                </div>
              </RightBtn>
            </div>
            <div style={{ width: '100%', height: '75%', position: 'relative' }}>
              <Table
                columns={eventColumns}
                rows={rows}
                checkbox
                leftBtn={DeleteBtn(handleDeleteBtn)}
                pageSize={PAGE_LIMIT}
                rowCount={totalCount}
                curPage={curPage}
                onCustomPaginationChange={fetchEventList}
                onRowDoubleClick={handleRowDoubleClick}
                componentsProps={{
                  row: {
                    style: {
                      background: 'linear-gradient(90.12deg, #313644 0.02%, #1E2530 79.25%)',
                      fontWeight: 'bold',
                      borderRadius: 10,
                      marginBottom: 10,
                    },
                  },
                }}
                onSelectionModelChange={(ids) => {
                  let checkedData = [];
                  rows.map((i) => {
                    if (ids.includes(i.id)) return checkedData.push(i);
                  });
                  setCheckedRows(checkedData);
                }}
              />
              {apiUtils.renderLoading()}
            </div>
          </div>
        </div>
      </div>

      {/* 조회 - R */}
      {detailOpen && <EventDetailModal detailOpen={detailOpen} isDetailOpen={setDetailOpen} isDeleteOpen={setDeleteOpen} rowInfo={fetchedData} setEventQuiteOpen={setEventQuiteOpen} setEditOpen={setEventEditOpen} setDeleteData={setCheckedRows} fetchData={fetchEventList} />}
      {/* 수정 - U */}
      {eventEditOpen && <EventEditModal open={eventEditOpen} setOpen={setEventEditOpen} rowInfo={selectedRowData} fetchData={fetchEventList} />}
      {/* 삭제 - D */}
      {deleteOpen && <ConfirmDialogModal confirmDialogOpen={deleteOpen} isConfirmDialogOpen={setDeleteOpen} title={'이벤트 삭제'} detail={`한 번 삭제된 이벤트는 되돌릴 수 없습니다. 선택된 이벤트를 삭제하시겠습니까?`} deleteData={checkedRows} fetchData={fetchEventList} />}
      {/* 이벤트 종료 */}
      {eventQuiteOpen && <ConfirmDialogModal confirmDialogOpen={eventQuiteOpen} isConfirmDialogOpen={setEventQuiteOpen} setPrevModal={setDetailOpen} title={'이벤트 상황종료'} detail={'현재 처리중인 이벤트를 종료하시겠습니까?'} />}
      {/* SMS */}
      {smsOpen && <SMSModal smsOpen={smsOpen} setSmsOpen={setSmsOpen} setSmsCompleteOpen={setSmsCompleteOpen} smsDeleteOpen={smsDeleteOpen} setSmsDeleteOpen={setSmsDeleteOpen} rowInfo={checkedRows} />}
    </Container>
  );
};

export default Event;
