import AlertMessage from '../../utilities/AlertMessage';
import BaseModal from '../../BaseModal';
import { fetchApi } from '../../../utils/APIUtils';

const DeleteUserModal = ({ open, setOpen, isDetailOpen, rowInfo, fetchTable }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelBtn = () => {
    fetchApi(`/v1/me/organization/user/${rowInfo.uuid}`, `delete`)
      .then((res) => {
        AlertMessage.alertMessage('success', '삭제되었습니다');
        fetchTable();
        setOpen(false);
      })
      .catch((err) => console.log('[Line:15 err]', err));
  };

  return (
    <BaseModal title={'사용자 삭제'} open={open} leftBtnLabel={'취소'} rightBtnLabel={'삭제'} handleLeftBtn={handleClose} handleRightBtn={handleDelBtn}>
      <div style={{ marginBottom: 20 }}>
        <span style={{ color: '#496DED', fontWeight: 'bold' }}>
          {rowInfo?.username}({rowInfo?.profile?.name})
        </span>
        님을
        <br /> 삭제하시겠습니까?
      </div>
    </BaseModal>
  );
};

export default DeleteUserModal;
