import React, {createRef} from 'react';
import ReactModal from 'react-modal-resizable-draggable';
import { inject, observer } from 'mobx-react';
import EventEmitter from '../../utils/EventEmitter';
import { MODE_WRITE } from './EventListPanelComponent';
import APIUtils from '../../utils/APIUtils';
import AlertMessage from '../utilities/AlertMessage';
import { MODE } from './CCTVViewModal';

@inject('cctvStore')
@observer
export default class CCTVImageStorageModal extends React.Component {
  emitter = EventEmitter.getInstance();
  apiUtils = new APIUtils();
  storageModalRef = createRef();

  state = {
    mode: MODE.normal,
    numberOfSelectedImg: 0,
    deleteProcess: 0,
    selectAll: false,
  };

  componentDidMount() {
    this.props.cctvStore.fetchCapturedImage();
    this.selectAll(false);
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if(prevProps?.isMounted && this.props.open) {
      this.storageModalRef?.focus?.();
    }
  }

  countSelectedImg = () => {
    let cnt = this.props.cctvStore.capturedImageList.filter((i) => i.checked).length;
    this.setState({
      numberOfSelectedImg: cnt,
      selectAll: cnt > 0 && cnt === this.props.cctvStore.capturedImageList.length ? true : false,
    });
  };

  selectImg = (index, value) => {
    let tempArr = [...this.props.cctvStore.capturedImageList];
    tempArr[index].checked = value.target.checked;
    this.setState(
      {
        selectedImg: tempArr,
      },
      () => {
        this.countSelectedImg();
      },
    );
  };

  selectAll = (value) => {
    let tmpArr = this.props.cctvStore.capturedImageList.map((i) => {
      return { ...i, checked: value };
    });
    this.props.cctvStore.setCapturedImageList = tmpArr;
    this.countSelectedImg();
  };

  handleModal = (bol) => {
    this.props.headerButtonHandler('savedImgBtn', bol);
  };

  attachToEvent = (capturedImageList) => {
    this.emitter.emit('changeEventListMode', { mode: MODE_WRITE, data: capturedImageList.filter((i) => i.checked) });
  };

  // !!! 캡쳐화면 삭제 시
  deleteCapturedImage = async () => {
    const result = await AlertMessage.confirmMessage(`${this.state.numberOfSelectedImg}개의 갭쳐된 이미지를\n 삭제 하시겠습니까?`);
    if (!result) return null;
    this.setState(
      {
        mode: MODE.delete,
        deleteProcess: { idx: 1, total: this.state.numberOfSelectedImg },
      },
      () => {
        const numberOfSelectedImg = this.state.numberOfSelectedImg;
        let currentDeleteIdx = 1;

        this.props.cctvStore.capturedImageList.map((i, idx) => {
          if (i.checked) {
            this.apiUtils.fetchApi(`/v1/me/organization/facility/cctv/${i.cctvId}/snapshot/${i.uuid}`, 'delete').then((r) => {
              if (this.state.deleteProcess) {
                this.setState({
                  deleteProcess: {
                    total: numberOfSelectedImg,
                    idx: currentDeleteIdx++,
                  },
                });
              }
            });
          }

          if (this.props.cctvStore.capturedImageList.length - 1 === idx) {
            setTimeout(() => {
              this.emitter.emit('fetchCapturedImage');
              this.props.cctvStore.fetchCapturedImage();
              this.countSelectedImg();
              this.setState({
                mode: MODE.normal,
              });
            }, 1000);
          }
        });
      },
    );

    // let tempArr = [...this.props.cctvStore.capturedImageList];
    // tempArr.forEach((i) => {
    //   if (i.checked) {
    //     this.props.cctvStore.deleteCaptureImage(i.id);
    //   }
    // });
    // this.countSelectedImg();
  };

  // !!! 삭제 시 진행바 렌더링
  renderProgressBar = () => {
    let progress = ((this.state.deleteProcess.idx || 0) / (this.state.deleteProcess.total || 1)) * 100;
    return (
      <div
        style={{
          background: `linear-gradient(to right, #558B2F ${progress.toFixed(0) || 1}%, #757575 ${progress.toFixed(0) || 99}%)`,
          color: '#FFF',
          width: '100%',
          height: 40,
          borderRadius: 5,
          lineHeight: '40px',
          textAlign: 'center',
          fontWeight: 'bold',
          marginTop: 20,
        }}>
        {`${this.state.deleteProcess.idx || 0}/${this.state.deleteProcess.total || 0}(${progress.toFixed(1)}%)`}
      </div>
    );
  };

  render() {
    const { capturedImageList } = this.props.cctvStore;
    return (
      <ReactModal key='test' className={'gis-cctv-storage'} initWidth={470} minWidth={470} initHeight={580} minHeight={580} top={'5%'} left={'60%'} isOpen={this.props.open} onRequestClose={() => {}}>
        <div
          className='body'
          ref={(e) => (this.storageModalRef = e)}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              this.handleModal(false);
            }
          }}>
          <div className={'header'}>
            <div className='title'>최근 캡쳐화면 {capturedImageList.length}</div>
            <div
              className={'times'}
              onClick={() => {
                this.selectAll(false);
                this.handleModal(false);
              }}>
              &times;
            </div>
          </div>
          <div className={'content'} style={{ width: '100%', height: capturedImageList.length > 0 ? (this.state.mode === MODE.normal ? 'calc(100% - 130px)' : 'calc(100% - 180px)') : '100%', marginBottom: 20 }}>
            {capturedImageList.length === 0 && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#FFFFFF90',
                }}>
                캡쳐된 화면이 없습니다
              </div>
            )}
            {capturedImageList.length > 0 && (
              <div className={'select-all'}>
                <input type='checkbox' id={'select-all'} checked={this.state.selectAll} onChange={(e) => this.selectAll(e.target.checked)} />
                <label htmlFor={'select-all'}>전체 선택</label>
              </div>
            )}
            <div className={'image-container scroll-b'}>
              {capturedImageList.map((item, index) => (
                <label htmlFor={`t${index}`} className={'image-wrapper'} key={index}>
                  <img src={item.url} />
                  <input
                    type='checkbox'
                    id={`t${index}`}
                    checked={item.checked}
                    onChange={(value) => {
                      this.selectImg(index, value);
                    }}
                  />
                  {item.checked && <div className={'cover'} />}
                </label>
              ))}
            </div>
          </div>
          {this.state.mode === MODE.delete && this.renderProgressBar()}

          {capturedImageList.length > 0 && (
            <div className={'bottom'}>
              <div className={'delete-btn'} onClick={this.deleteCapturedImage}>
                삭제
              </div>
              <div className={'attach-btn'} onClick={() => this.attachToEvent(capturedImageList)}>
                <img src={require('../../icons/attachment2.png')} />
                {this.state.numberOfSelectedImg}개 첨부하기
              </div>
            </div>
          )}
        </div>
        {this.apiUtils.renderLoading()}
      </ReactModal>
    );
  }
}
