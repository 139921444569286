import React from 'react';
import Container from '../components/Container';
import HeaderComponent from '../components/HeaderComponent';
import EventListPanelComponent from '../components/gis/EventListPanelComponent';
import CCTVSideComponent from '../components/gis/CCTVSideComponent';
import GISBottomComponent from '../components/gis/GISBottomComponent';
import MapNaver from '../components/MapNaver';
import EventEmitter from '../utils/EventEmitter';
import CCTVImageStorageModal from '../components/gis/CCTVImageStorageModal';
import { inject, observer } from 'mobx-react';
import { ReactComponent as CCTV_BLUE } from "../icons/cctv_blue_with_round.svg";
import { ReactComponent as CCTV_GRAY } from "../icons/cctv_gray_with_round.svg";
import { ReactComponent as IMAGE_BLUE } from "../icons/image_blue_with_round.svg";
import { ReactComponent as IMAGE_GRAY } from "../icons/image_gray_with_round.svg";

@inject('cctvStore')
@observer
export default class GIS extends React.Component {
	emitter = EventEmitter.getInstance();
	map = null;

	state = {
		cctvBtn: false,
		savedImgBtn: false,

		isMounted: false,
	};

	componentDidMount() {
		this.emitter.on('headerButtonHandler', (p) => this.headerButtonHandler(p.key, p.value));

		this.setState({
			isMounted: true
		})
	}

	componentWillUnmount() {
		this.emitter.off('headerButtonHandler');
		this.emitter.clearAll();
	}

	handleCCTVPanel = (bol) =>
		this.setState({
			cctvBtn: bol,
		});

	headerButtonHandler = (key, value) => {
		this.setState({ [key]: value }, () => {
			if (key === 'cctvBtn') {
				this.handleCCTVPanel(this.state.cctvBtn);
				this.emitter.emit("resizeMap");
			} else if (key === 'savedImgBtn') {
			}
		});
	};

	render() {
		return (
			<Container>
				<HeaderComponent pageTitle={'GIS Monitoring'} isStrong={true}>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
						<span style={{ marginRight: 17, cursor: "pointer"}} onClick={() => this.headerButtonHandler('cctvBtn', !this.state.cctvBtn)}>
							{this.state.cctvBtn ? <CCTV_BLUE /> : <CCTV_GRAY/>}
						</span>
						{/*<img src={require(`../icons/map/cctv-${this.state.cctvBtn ? 'on' : 'off'}.png`)} style={{ marginRight: 17 }} className={'cursor'} onClick={() => this.headerButtonHandler('cctvBtn', !this.state.cctvBtn)} />*/}
						<div style={{ position: 'relative' }}>
							{this.props.cctvStore.capturedImageList.length > 0 && (
								<span style={{ position: 'absolute', right: -5, top: -3, fontSize: 14, backgroundColor: 'white', color: '#FF0000', borderRadius: 100, padding: '0 6px', fontWeight: 700, height: 13 }}>{this.props.cctvStore.capturedImageList.length}</span>
							)}
							<span style={{ cursor: "pointer"}} onClick={() => this.headerButtonHandler('savedImgBtn', !this.state.savedImgBtn)}>
								{this.state.savedImgBtn ? <IMAGE_BLUE /> : <IMAGE_GRAY/>}
							</span>
						</div>
					</div>
				</HeaderComponent>
				<div className={`gis-container ${this.state.cctvBtn ? 'open' : 'close'}`}>
					{/* 왼쪽 패널 - 이벤트, 웹 신호 차트 */}
					{/* 이벤트 목록 패널 */}
					<EventListPanelComponent />

					{/* 오른쪽 패널 - 맵*/}
					<div style={{ width: ' 100%', height: 'calc(100% - 40px)' }}>
						<div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
							{/* 맵 */}
							<div className={'map-container'}>
								<MapNaver zoom={15} position={{ lat: 35.172771695080264, lng: 129.12572467858556 }} maptool />
							</div>

							<div style={{ width: 30, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => {
									this.headerButtonHandler('cctvBtn', !this.state.cctvBtn);
							}}>
								<div style={{ color: '#03A8A8', transform: `rotate(${this.state.cctvBtn ? 0 : -180}deg)`, transitionDuration: 0.5 }}>&#9654;</div>
							</div>

							{/* cctv 패널*/}
							<CCTVSideComponent open={this.state.cctvBtn} closePanel={() => this.handleCCTVPanel(false)} temp={this.props.cctvStore.getCCTVList} />
						</div>

						<CCTVImageStorageModal headerButtonHandler={this.headerButtonHandler} open={this.state.savedImgBtn} isMounted={this.state.isMounted} />

						{/* 바텀 - 뉴스 */}
						<GISBottomComponent />
					</div>
				</div>
			</Container>
		);
	}
}
