import {BaseContext} from '../stores/BaseStore';
import React, {useContext} from 'react';
import useAsyncList from './useAsyncList';
import {IGroup, IService} from 'anuro-platform-core';

interface Props {
  children: React.ReactNode;
}

export const BaseProvider = (props: Props) => {
  const service = useAsyncList<IService[]>('/v1/me/organization/service');
  const group = useAsyncList<{ rows: IGroup[]; count: number }>('/v1/me/organization/group');

  return (
    <BaseContext.Provider
      value={{
        services: service.data || [],
        groups: group.data?.rows || [],
      }}>
      {props.children}
    </BaseContext.Provider>
  );
};

export const useBase = () => useContext(BaseContext);
