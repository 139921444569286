import { Box, Modal } from "@mui/material";
import React from "react";
import ModalStyle from "../ModalStyle";
import "../../css/event/smsDeleteModal.css";

const EventDeleteConfirmModal = ({ deleteConfirmOpen, isDeleteOpen, setDeleteConfirmOpen }) => {
	const handleClose = () => {
		isDeleteOpen(false);
		setDeleteConfirmOpen(false);
	};

	return (
		<Modal
			open={deleteConfirmOpen}
			// onClose={handleClose}
			className="delete-modal-conatiner"
		>
			<Box sx={ModalStyle(400, 200, 5)}>
				<div id="modal-description" className="modal-delete-container">
					<h2>삭제 완료</h2>
					<div className="detail">정상적으로 처리되었습니다</div>
					<div className="btn-conatiner">
						<button className="btn-ok" onClick={handleClose}>
							닫기
						</button>
					</div>
				</div>
			</Box>
		</Modal>
	);
};

export default EventDeleteConfirmModal;
