import React from 'react';
import Chart from 'react-apexcharts';
import APIUtils from '../../utils/APIUtils';
import { TextField } from '@mui/material';
import moment from 'moment/moment';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {localeMap} from "./ProgressChart";

type Props = {};
export default class DistributionChart extends React.Component<Props, any> {
  apiUtils = new APIUtils();

  state = {
    data: {} as any,
    startDate: '',
    endDate: '',
    options: {},
  };

  constructor(props: any) {
    super(props);

    this.state = {
      data: {} as any,
      startDate: moment().subtract(6, 'days').format('YYYY.MM.DD'),
      endDate: moment().format('YYYY.MM.DD'),
      options: {
        chart: {
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            offsetX: -50,
            startAngle: 0,
            endAngle: 270,
            track: {
              background: 'transparent',
            },
            hollow: {
              margin: 5,
              size: '30%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
        colors: ['#FF0000', '#F66627', '#14C1A2', '#3874EA', '#FFF32A'],
        labels: [],
        legend: {
          show: true,
          floating: true,
          fontSize: '12px',
          position: 'bottom',
          horizontalAlign: 'left',
          offsetX: 230,
          offsetY: 0,
          inverseOrder: true,
          labels: {
            colors: '#FFFFFF',
          },
          markers: {
            size: 0,
          },
          formatter: function (seriesName: any, opts: any) {
            return seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex] + "%";
          },
          itemMargin: {
            vertical: 3,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
  }

  // colors: ['#FF0000', '#F66627', '#14C1A2', '#3874EA', '#FFF32A'],

  componentDidMount() {
    this.fetchChartData();
  }

  fetchChartData = () => {
    this.apiUtils
      .fetchApi('/v1/me/organization/event/statistics/count', 'get', {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
      .then((r5) => {
        this.setState({
          data: r5,
          options: {
            ...this.state.options,
            labels: r5.labels,
          },
        });
      });
  };

  onChange = (key: string, value: string) => {
    this.setState({ [key]: value }, () => {
      this.fetchChartData();
    });
  };

  render() {
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 10,
            justifyContent: 'flex-end',
          }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap['ko']}>
              <DatePicker
                InputProps={{
                  style: {backgroundColor: "#313644", width: 160, color: "#FFF", borderRadius: 8},
                }}
                disableFuture
                inputFormat={'yyyy.MM.dd'}
                value={this.state.startDate}
                onChange={(newValue) => this.onChange('startDate', newValue || "")}
                renderInput={(params) => <TextField {...params} />}
              />
              <span style={{ color: '#727894', fontWeight: 'bold', margin: '0 10px' }}>~</span>
              <DatePicker
                InputProps={{
                  style: {backgroundColor: "#313644", width: 160, color: "#FFF", borderRadius: 8},
                }}
                disableFuture
                inputFormat={'yyyy.MM.dd'}
                value={this.state.endDate}
                onChange={(newValue) => this.onChange('endDate', newValue || "")}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div
          style={{
            position: 'relative',
            backgroundColor: '#313644',
            padding: '10px 0 0 10px',
            borderRadius: 8,
            height: 350,
          }}>
          <Chart options={this.state.options} series={this.state.data?.seriesInPercentage || []} type='radialBar' width={'100%'} height={'100%'} />
          {this.apiUtils.renderLoading()}
        </div>
      </>
    );
  }
}
