import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../css/nav.css';
import { ReactComponent as Setting } from '../icons/sideNavi/setting.svg';
import SettingModal from './settings/SettingModal';
import EventEmitter from '../utils/EventEmitter';
import { ReactComponent as GisOn } from '../icons/sideNavi/gis_on.svg';
import { ReactComponent as GisOff } from '../icons/sideNavi/gis_off.svg';
import { ReactComponent as CctvOn } from '../icons/sideNavi/cctv_on.svg';
import { ReactComponent as CctvOff } from '../icons/sideNavi/cctv_off.svg';
import { ReactComponent as EventOn } from '../icons/sideNavi/event_on.svg';
import { ReactComponent as EventOff } from '../icons/sideNavi/event_off.svg';
import { ReactComponent as UserManagementOn } from '../icons/sideNavi/user-management_on.svg';
import { ReactComponent as UserManagementOff } from '../icons/sideNavi/user-management_off.svg';
import { ReactComponent as DisasterOn } from '../icons/sideNavi/disaster_on.svg';
import { ReactComponent as DisasterOff } from '../icons/sideNavi/disaster_off.svg';
import { ReactComponent as StatisticsOn } from '../icons/sideNavi/statistics_on.svg';
import { ReactComponent as StatisticsOff } from '../icons/sideNavi/statistics_off.svg';

const menuItem = [
  {
    name: 'gis',
    IconOn: GisOn,
    IconOff: GisOff,
  },
  {
    name: 'facility',
    IconOn: CctvOn,
    IconOff: CctvOff,
  },
  {
    name: 'event',
    IconOn: EventOn,
    IconOff: EventOff,
  },
  {
    name: 'user-management',
    IconOn: UserManagementOn,
    IconOff: UserManagementOff,
  },
  {
    name: 'disaster',
    IconOn: DisasterOn,
    IconOff: DisasterOff,
  },
  {
    name: 'statistics',
    IconOn: StatisticsOn,
    IconOff: StatisticsOff,
  },
];

const MenuList = () => {
  const location = useLocation();

  return menuItem.map(({ name, IconOn, IconOff }, idx) => {
    return (
      <NavLink key={idx} to={`${name}`} className={`link ${location.pathname.includes(name) ? 'active' : ''}`}>
        {location.pathname.includes(name) ? <IconOn /> : <IconOff />}
      </NavLink>
    );
  });
};

const SideNavigationComponent = () => {
  const emitter = EventEmitter.getInstance();
  const [settingModalOpen, setSettingModalOpen] = useState(false);

  // !!! 맵이 렌더링 되고 처음 시작
  useEffect(() => {
    emitter.on('openSettingModal', openSettingModal);
    return () => {
      emitter.off('openSettingModal');
    };
  }, []);

  const openSettingModal = () => setSettingModalOpen(true);

  return (
    <>
      <div className={'side-container'}>
        <div className='menu-container'>
          <div className='menu'>
            <MenuList />
          </div>

          <div className='btn-container'>
            <Setting className='icon' onClick={() => setSettingModalOpen(true)} />
          </div>
        </div>
      </div>
      {settingModalOpen && <SettingModal key='setting' modalHandler={setSettingModalOpen} />}
    </>
  );
};

export default SideNavigationComponent;
