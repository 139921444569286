import React from 'react';
import '../../css/event/eventDetailListComponent.css';
import moment from 'moment';

const EventDetailListComponent = ({ commentList }) => {
  const isHistory = 'code' in commentList;

  const status = (() => {
    switch (commentList.status) {
      case 'STANDBY':
        return '상황발생';
      case 'IN_PROGRESS':
        return '처리중';
      case 'TERMINATED':
        return '상황종료';
      default:
        return '';
    }
  })();
  return (
    <div className={'list-container'} style={{ backgroundColor: isHistory ? '#FFE6E6' : 'white' }}>
      <div className={'title'}>
        {isHistory ? <div style={{ fontSize: 12, fontWeight: 'bold', marginRight: 14 }}>상태변경</div> : <div style={{ fontSize: 12, fontWeight: 'bold', marginRight: 14 }}>{commentList?.user?.username}</div>}

        <div
          style={{
            fontSize: 12,
            color: '#AAAAAA',
          }}>
          {isHistory ? moment(commentList?.createdAt).format('MM-DD HH:mm:ss') : moment(commentList?.createdAt).format('MM-DD HH:mm:ss')}
        </div>
      </div>
      {isHistory ? <div className={'detail'}>{status}</div> : <div className={'detail'}>{commentList?.message}</div>}
    </div>
  );
};

export default EventDetailListComponent;
