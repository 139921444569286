import React, {useEffect, useState} from 'react';
import '../../../css/facilities/facilityRegiModal.css';
import AlertMessage from '../../utilities/AlertMessage';
import AddressInput from '../../AddressInput';
import RadioGroup from '../../RadioGroup';
import BaseModal from '../../common/Modal/BaseModal';
import {fetchApi} from '../../../utils/APIUtils';
import Input from '../../Input';
import Radio from '../../Radio';
import {useForm} from 'react-hook-form';
import {FACILITY} from 'anuro-platform-core';

const RegiFacilityModal = ({ open, setOpen, fetchTable }) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      active: '1',
    },
  });
  const [typeList, setTypeList] = useState([]);
  const [addressValue, setAddressValue] = useState('');
  const isCCTV = typeList.find((facility) => facility.organizationFacilityType.id === Number(watch('typeId')))?.name;

  useEffect(() => {
    fetchApi('/v1/me/organization/facility-type', 'get').then((data) => setTypeList(data));
  }, []);

  const onSubmit = (data) => {
    fetchApi(`/v1/me/organization/facility/cctv`, `post`, {
      code: data?.code,
      active: data.active,
      status: data.status,
      typeId: data.typeId,
      detail: data.detail,
      name: data.name,
      latitude: data.latitude,
      longitude: data.longitude,
      addressStreet: addressValue,
      addressStreetDetail: data.addressStreetDetail,
      rtspHost: data.rtspHost,
      rtspPort: data.rtspPort,
      rtspAuthId: data.rtspAuthId,
      rtspAuthPw: data.rtspAuthPw,
      rtspPath: data.rtspPath,
      rtspFlags: data.rtspFlags,
      onvifHost: data.onvifHost,
      onvifPort: data.onvifPort,
      onvifAuthId: data.onvifAuthId,
      onvifAuthPw: data.onvifAuthPw,
      onvifPath: data.onvifPath,
    })
      .then(() => {
        AlertMessage.alertMessage('success', '시설물 생성이 완료되었습니다');
        fetchTable();
        setOpen(false);
      })
      .catch((err) => console.log('[Line:45 err]', err));
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const originalStyle = {
    width: isCCTV ? '50%' : '100%',
    marginRight: isCCTV ? 20 : 0,
  };

  const extendedStyle = {
    width: isCCTV ? '50%' : '100%',
    borderLeft: '1px solid #DDDDDD',
    paddingLeft: 20,
  };

  const titleStyle = {
    textAlign: 'left',
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 6,
  };

  const hostContainerStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  return (
    <BaseModal open={open} handleCloseBtn={handleClose} title={'시설물 등록'} divider leftBtnTitle={'취소'} rightBtnTitle={'등록'} handleLeftBtn={handleClose} handleRightBtn={handleSubmit(onSubmit)}>
      <div className='modal-user-edit-container'>
        <div style={originalStyle}>
          <RadioGroup
            labelwidth={140}
            label={'종류'}
            onChange={(e) => {
              setValue('typeId', e.target.value);
            }}>
            {typeList.map((type) => (
              <div key={type.id}>
                <img src={type.organizationFacilityType.iconNormalUrl} alt={'icon'} width={42} height={42} />
                <Radio value={type.organizationFacilityType.id} name={'r'}>
                  {type.name}
                </Radio>
              </div>
            ))}
          </RadioGroup>
          <Input name={'code'} {...register('code')} label={'코드'} placeholder={'시설물 코드를 입력해주세요'} />
          <Input
            name={'facility_name'}
            {...register('name', {
              required: true,
              minLength: FACILITY.NAME_MIN_LENGTH,
              maxLength: FACILITY.NAME_MAX_LENGTH,
            })}
            label={'이름'}
            placeholder={'시설물 이름을 입력해주세요'}
            errortype={errors?.name?.type}
            requirement={{ minLength: FACILITY.NAME_MIN_LENGTH, maxLength: FACILITY.NAME_MAX_LENGTH }}
          />
          <div className='each-detail'>
            <div className='title'>위치</div>
            <AddressInput
              padding={10}
              placeholder={'위치를 입력해주세요'}
              cn={'input'}
              radius={5}
              width={'calc(100% - 110px)'}
              height={'40px'}
              onChange={(addressInfo) => {
                setAddressValue(addressInfo.roadAddr);
                setValue('latitude', addressInfo.latitude);
                setValue('longitude', addressInfo.longitude);
              }}
            />
          </div>
          <Input name={'addressStreetDetail'} {...register('addressStreetDetail')} label={'상세주소'} placeholder={'상세주소를 입력해주세요'} />
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: '60%' }}>
              <Input name={'latitude'} {...register('latitude')} label={'위도/경도'} placeholder={'위도'} />
            </div>
            <div style={{ width: '40%', marginLeft: 15 }}>
              <Input width={'100%'} name={'longitude'} {...register('longitude')} placeholder={'경도'} />
            </div>
          </div>
          <RadioGroup labelwidth={140} label={'활성 상태'}>
            <Radio value={'1'} {...register('active')}>
              활성
            </Radio>
            <Radio value={'0'} {...register('active')}>
              비활성
            </Radio>
          </RadioGroup>
          <Input textarea height={120} label={'비고'} placeholder='메모를 입력해주세요' {...register('detail')} />
        </div>
        {isCCTV && (
          <div style={extendedStyle}>
            <div style={titleStyle}>RTSP</div>
            <div style={hostContainerStyle}>
              <div style={{ width: '80%' }}>
                <Input
                  extended
                  name={'rtspHost'}
                  {...register('rtspHost', {
                    required: true,
                    max: FACILITY.HOST_MAX_LENGTH,
                  })}
                  label={'Host*'}
                  placeholder={'Host를 입력해주세요'}
                  errortype={errors?.rtspHost?.type}
                  requirement={{ max: FACILITY.HOST_MAX_LENGTH }}
                />
              </div>
              <div style={{ width: '20%', marginLeft: 15 }}>
                <Input
                  width={'100%'}
                  name={'rtspPort'}
                  {...register('rtspPort', {
                    required: true,
                    max: FACILITY.PORT_MAX_VALUE,
                    min: FACILITY.PORT_MIN_VALUE,
                    maxLength: FACILITY.PORT_MAX_LENGTH,
                  })}
                  placeholder={'Port'}
                  errortype={errors?.rtspPort?.type}
                  requirement={{
                    min: FACILITY.PORT_MIN_VALUE,
                    max: FACILITY.PORT_MAX_VALUE,
                    maxLength: FACILITY.PORT_MAX_LENGTH,
                  }}
                />
              </div>
            </div>
            <Input extended name={'rtspPath'} {...register('rtspPath', { maxLength: FACILITY.PATH_MAX_LENGTH })} errortype={errors?.rtspPath?.type} requirement={{ maxLength: FACILITY.PATH_MAX_LENGTH }} label={'Path'} placeholder={'Path를 입력해주세요'} />
            <Input extended name={'rtspAuthId'} {...register('rtspAuthId', { maxLength: FACILITY.AUTH_ID_MAX_LENGTH })} errortype={errors?.rtspAuthId?.type} requirement={{ maxLength: FACILITY.AUTH_ID_MAX_LENGTH }} label={'ID'} placeholder={'아이디를 입력해주세요'} />
            <Input type={'password'} extended name={'rtspAuthPw'} {...register('rtspAuthPw', { maxLength: FACILITY.AUTH_PW_MAX_LENGTH })} errortype={errors?.rtspAuthPw?.type} requirement={{ maxLength: FACILITY.AUTH_PW_MAX_LENGTH }} label={'PW'} placeholder={'비밀번호를 입력해주세요'} />
            <Input extended name={'rtspFlags'} {...register('rtspFlags')} label={'Flags'} placeholder={'Flags를 입력해주세요'} />

            <div style={titleStyle}>ONVIF</div>
            <div style={hostContainerStyle}>
              <div style={{ width: '80%' }}>
                <Input extended name={'onvifHost'} {...register('onvifHost')} label={'Host'} placeholder={'Host를 입력해주세요'} />
              </div>
              <div style={{ width: '20%', marginLeft: 15 }}>
                <Input
                  width={'100%'}
                  name={'onvifPort'}
                  {...register('onvifPort', {
                    max: FACILITY.PORT_MAX_VALUE,
                    min: FACILITY.PORT_MIN_VALUE,
                    maxLength: FACILITY.PORT_MAX_LENGTH,
                  })}
                  placeholder={'Port'}
                  errortype={errors?.onvifPort?.type}
                  requirement={{
                    min: FACILITY.PORT_MIN_VALUE,
                    max: FACILITY.PORT_MAX_VALUE,
                    maxLength: FACILITY.PORT_MAX_LENGTH,
                  }}
                />
              </div>
            </div>
            <Input extended name={'onvifPath'} {...register('onvifPath', { maxLength: FACILITY.PATH_MAX_LENGTH })} label={'Path'} placeholder={'Path를 입력해주세요'} errortype={errors?.onvifPath?.type} requirement={{ maxLength: FACILITY.PATH_MAX_LENGTH }} />
            <Input extended name={'onvifAuthId'} {...register('onvifAuthId', { maxLength: FACILITY.AUTH_ID_MAX_LENGTH })} errortype={errors?.onvifAuthId?.type} requirement={{ maxLength: FACILITY.AUTH_ID_MAX_LENGTH }} label={'ID'} placeholder={'아이디를 입력해주세요'} />
            <Input type={'password'} extended name={'onvifAuthPw'} {...register('onvifAuthPw', { maxLength: FACILITY.AUTH_PW_MAX_LENGTH })} errortype={errors?.onvifAuthPw?.type} requirement={{ maxLength: FACILITY.AUTH_PW_MAX_LENGTH }} label={'PW'} placeholder={'비밀번호를 입력해주세요'} />
          </div>
        )}
      </div>
    </BaseModal>
    // <BaseModal width={isCCTV ? 1000 : 520} height={isCCTV ? 830 : 830} title={'시설물 등록'} open={open} rightBtnLabel={'등록'} leftBtnLabel={'취소'} closeIcon handleClose={handleClose} handleLeftBtn={handleClose} handleRightBtn={handleSubmit(onSubmit)}>
    //   <Divider marginBottom={20} color={'#999999'} />
    //   <div className='modal-user-edit-container'>
    //     <div style={originalStyle}>
    //       <RadioGroup
    //         labelwidth={140}
    //         label={'종류'}
    //         onChange={(e) => {
    //           setValue('typeId', e.target.value);
    //         }}>
    //         {typeList.map((type) => (
    //           <div key={type.id}>
    //             <img src={type.organizationFacilityType.iconNormalUrl} alt={'icon'} width={42} height={42} />
    //             <Radio value={type.organizationFacilityType.id} name={'r'}>
    //               {type.name}
    //             </Radio>
    //           </div>
    //         ))}
    //       </RadioGroup>
    //       <Input name={'code'} {...register('code')} label={'코드'} placeholder={'시설물 코드를 입력해주세요'} />
    //       <Input
    //         name={'facility_name'}
    //         {...register('name', {
    //           required: true,
    //           minLength: FACILITY.NAME_MIN_LENGTH,
    //           maxLength: FACILITY.NAME_MAX_LENGTH,
    //         })}
    //         label={'이름'}
    //         placeholder={'시설물 이름을 입력해주세요'}
    //         errortype={errors?.name?.type}
    //         requirement={{ minLength: FACILITY.NAME_MIN_LENGTH, maxLength: FACILITY.NAME_MAX_LENGTH }}
    //       />
    //       <div className='each-detail'>
    //         <div className='title'>위치</div>
    //         <AddressInput
    //           padding={10}
    //           placeholder={'위치를 입력해주세요'}
    //           cn={'input'}
    //           radius={5}
    //           width={'calc(100% - 110px)'}
    //           height={'40px'}
    //           onChange={(addressInfo) => {
    //             setAddressValue(addressInfo.roadAddr);
    //             setValue('latitude', addressInfo.latitude);
    //             setValue('longitude', addressInfo.longitude);
    //           }}
    //         />
    //       </div>
    //       <Input name={'addressStreetDetail'} {...register('addressStreetDetail')} label={'상세주소'} placeholder={'상세주소를 입력해주세요'} />
    //       <div style={{ display: 'flex', width: '100%' }}>
    //         <div style={{ width: '60%' }}>
    //           <Input name={'latitude'} {...register('latitude')} label={'위도/경도'} placeholder={'위도'} />
    //         </div>
    //         <div style={{ width: '40%', marginLeft: 15 }}>
    //           <Input width={'100%'} name={'longitude'} {...register('longitude')} placeholder={'경도'} />
    //         </div>
    //       </div>
    //       <RadioGroup labelwidth={140} label={'활성 상태'}>
    //         <Radio value={'1'} {...register('active')}>
    //           활성
    //         </Radio>
    //         <Radio value={'0'} {...register('active')}>
    //           비활성
    //         </Radio>
    //       </RadioGroup>
    //       <Input textarea height={120} label={'비고'} placeholder='메모를 입력해주세요' {...register('detail')} />
    //     </div>
    //     {isCCTV && (
    //       <div style={extendedStyle}>
    //         <div style={titleStyle}>RTSP</div>
    //         <div style={hostContainerStyle}>
    //           <div style={{ width: '80%' }}>
    //             <Input
    //               extended
    //               name={'rtspHost'}
    //               {...register('rtspHost', {
    //                 required: true,
    //                 max: FACILITY.HOST_MAX_LENGTH,
    //               })}
    //               label={'Host*'}
    //               placeholder={'Host를 입력해주세요'}
    //               errortype={errors?.rtspHost?.type}
    //               requirement={{ max: FACILITY.HOST_MAX_LENGTH }}
    //             />
    //           </div>
    //           <div style={{ width: '20%', marginLeft: 15 }}>
    //             <Input
    //               width={'100%'}
    //               name={'rtspPort'}
    //               {...register('rtspPort', {
    //                 required: true,
    //                 max: FACILITY.PORT_MAX_VALUE,
    //                 min: FACILITY.PORT_MIN_VALUE,
    //                 maxLength: FACILITY.PORT_MAX_LENGTH,
    //               })}
    //               placeholder={'Port'}
    //               errortype={errors?.rtspPort?.type}
    //               requirement={{
    //                 min: FACILITY.PORT_MIN_VALUE,
    //                 max: FACILITY.PORT_MAX_VALUE,
    //                 maxLength: FACILITY.PORT_MAX_LENGTH,
    //               }}
    //             />
    //           </div>
    //         </div>
    //         <Input extended name={'rtspPath'} {...register('rtspPath', { maxLength: FACILITY.PATH_MAX_LENGTH })} errortype={errors?.rtspPath?.type} requirement={{ maxLength: FACILITY.PATH_MAX_LENGTH }} label={'Path'} placeholder={'Path를 입력해주세요'} />
    //         <Input extended name={'rtspAuthId'} {...register('rtspAuthId', { maxLength: FACILITY.AUTH_ID_MAX_LENGTH })} errortype={errors?.rtspAuthId?.type} requirement={{ maxLength: FACILITY.AUTH_ID_MAX_LENGTH }} label={'ID'} placeholder={'아이디를 입력해주세요'} />
    //         <Input type={'password'} extended name={'rtspAuthPw'} {...register('rtspAuthPw', { maxLength: FACILITY.AUTH_PW_MAX_LENGTH })} errortype={errors?.rtspAuthPw?.type} requirement={{ maxLength: FACILITY.AUTH_PW_MAX_LENGTH }} label={'PW'} placeholder={'비밀번호를 입력해주세요'} />
    //         <Input extended name={'rtspFlags'} {...register('rtspFlags')} label={'Flags'} placeholder={'Flags를 입력해주세요'} />
    //
    //         <div style={titleStyle}>ONVIF</div>
    //         <div style={hostContainerStyle}>
    //           <div style={{ width: '80%' }}>
    //             <Input extended name={'onvifHost'} {...register('onvifHost')} label={'Host'} placeholder={'Host를 입력해주세요'} />
    //           </div>
    //           <div style={{ width: '20%', marginLeft: 15 }}>
    //             <Input
    //               width={'100%'}
    //               name={'onvifPort'}
    //               {...register('onvifPort', {
    //                 max: FACILITY.PORT_MAX_VALUE,
    //                 min: FACILITY.PORT_MIN_VALUE,
    //                 maxLength: FACILITY.PORT_MAX_LENGTH,
    //               })}
    //               placeholder={'Port'}
    //               errortype={errors?.onvifPort?.type}
    //               requirement={{
    //                 min: FACILITY.PORT_MIN_VALUE,
    //                 max: FACILITY.PORT_MAX_VALUE,
    //                 maxLength: FACILITY.PORT_MAX_LENGTH,
    //               }}
    //             />
    //           </div>
    //         </div>
    //         <Input extended name={'onvifPath'} {...register('onvifPath', { maxLength: FACILITY.PATH_MAX_LENGTH })} label={'Path'} placeholder={'Path를 입력해주세요'} errortype={errors?.onvifPath?.type} requirement={{ maxLength: FACILITY.PATH_MAX_LENGTH }} />
    //         <Input extended name={'onvifAuthId'} {...register('onvifAuthId', { maxLength: FACILITY.AUTH_ID_MAX_LENGTH })} errortype={errors?.onvifAuthId?.type} requirement={{ maxLength: FACILITY.AUTH_ID_MAX_LENGTH }} label={'ID'} placeholder={'아이디를 입력해주세요'} />
    //         <Input type={'password'} extended name={'onvifAuthPw'} {...register('onvifAuthPw', { maxLength: FACILITY.AUTH_PW_MAX_LENGTH })} errortype={errors?.onvifAuthPw?.type} requirement={{ maxLength: FACILITY.AUTH_PW_MAX_LENGTH }} label={'PW'} placeholder={'비밀번호를 입력해주세요'} />
    //       </div>
    //     )}
    //   </div>
    // </BaseModal>
  );
};

export default RegiFacilityModal;
