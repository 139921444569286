import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { useHistory } from "react-router-dom/";
import ModalStyle from "../ModalStyle";
import "../../css/event/regiCompleteModal.css";

const RegiCompleteModal = ({ completeOpen, setCompleteOpen, setSmsOpen }) => {
  const handleClose = () => {
    setCompleteOpen(false);
  };

  const openSms = () => {
    setCompleteOpen(false);

    setSmsOpen(true);
  };

  const history = useHistory();

  const goToGIS = () => {
    history.push("/gis");
  };

  return (
    <Modal
      open={completeOpen}
      // onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={ModalStyle(900, 550, 5)}>
        <div id="modal-title" className="modal-complete-title">
          <h2>신규 이벤트 등록 완료</h2>
          <h3>이벤트 담당자에게 알림이 전송되었습니다</h3>
        </div>
        <div id="modal-description" className="modal-complete-container">
          <div className="result-container">
            <div className="each-result">
              <div className="title">사건 ID</div>
              <div className="detail">123123</div>
            </div>
            <div className="each-result">
              <div className="title">발생 위치</div>
              <div className="detail">123123</div>
            </div>
            <div className="each-result">
              <div className="title">발생 일시</div>
              <div className="detail">123123</div>
            </div>
            <div className="each-result">
              <div className="title">이벤트</div>
              <div className="detail">123123</div>
            </div>
            <div className="each-result">
              <div className="title">이벤트 유형</div>
              <div className="detail">123123</div>
            </div>
            <div className="each-result">
              <div className="title">이벤트 담당</div>
              <div className="detail">123123</div>
            </div>
            <div className="each-result">
              <div className="title">상세 내용</div>
              <div className="detail">123123</div>
            </div>
          </div>
          <div className="sms-conatiner">
            <div>
              <strong className="strong">*SMS 보내기</strong>를 통해 특정 기관
              및 사용자(시민)에게도 SMS를 전송할 수 있습니다.
            </div>
            <button className="btn-send" onClick={openSms}>
              SMS 보내기
            </button>
          </div>
          <div className="btn-container">
            <button onClick={goToGIS}>메인지도 바로가기</button>
            <button className="close" onClick={handleClose}>
              닫기
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default RegiCompleteModal;
